import React, { ChangeEventHandler, useCallback } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Input } from '@vkontakte/vkui';

export const integerRegexp = /^[0-9]{1,}$/;

export const IntegerInput = ({ input, maxValue }: WrappedFieldProps & { maxValue?: number }) => {
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.target.value === '' || integerRegexp.test(event.target.value)) {
        const value = +event.target.value;

        if (maxValue) {
          if (value < maxValue) {
            input.onChange(event);
          }
        } else {
          input.onChange(event);
        }
      }
    },
    [input, maxValue]
  );

  return <Input {...input} inputMode="numeric" pattern="[0-9]*" onChange={handleChange} />;
};
