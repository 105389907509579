import React, { FC } from 'react';

type ArrowProps = {
  color?: string;
};

export const Arrow: FC<ArrowProps> = ({ color = '#FFD100' }) => {
  return (
    <svg viewBox="0 0 345 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="1" width="345" height="8" fill="url(#a)" />
      <path d="m173 28-9.526-21h19.052L173 28Z" fill={color} />
      <defs>
        <linearGradient id="a" x1="0" y1="9" x2="345" y2="9" gradientUnits="userSpaceOnUse">
          <stop stopColor={color} stopOpacity="0" />
          <stop offset=".313" stopColor={color} />
          <stop offset=".5" stopColor={color} />
          <stop offset=".698" stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
