import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Field, FormSection, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';
import {
  Button,
  Card,
  Div,
  FormLayout,
  FormLayoutGroup,
  FormStatus,
  Input,
  ScreenSpinner,
  SimpleCell,
  Spinner,
  Switch as VkSwitch,
} from '@vkontakte/vkui';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Icon24DeleteOutline, Icon24CopyOutline, Icon24DoneOutline } from '@vkontakte/icons';
import axios from 'axios';
import copy from 'copy-to-clipboard';

import { RootState } from 'src/core/rootReducer';
import { Target } from 'src/store/targets/types';
import { VkTarif } from 'src/store/auth/types';
import { Switch } from 'src/vk-app/components';
import { PublicCodesState, VKInitialState, vkActions } from 'src/store/vk/reducer';
import { usePrevious } from 'src/core/hooks/usePrevious';
import { myGamesActions } from 'src/store/my-games/actions';
import { BaseGame } from 'src/games/common/types';
import { ModalName, PublicCode } from 'src/store/vk/types';
import { AddPublicCodesModal } from 'src/vk-app/components/add-public-codes-modal';
import { useVirtual } from 'react-virtual';
import { config } from 'src/config/config';
import { getStringParams } from 'src/vk-app/utils/query';
import { ApiStatus } from 'src/core/ApiStatus';
import { pluralize } from 'src/core/utils/pluralize';

const contains = (value: string, str?: string): boolean => {
  if (!str) {
    return false;
  }

  return str.toLowerCase().includes(value);
};

const getUsages = (code: PublicCode): string => {
  return `Использований: ${code.usages} ${code.maxUsage ? ` / ${code.maxUsage}` : ''}`;
};

const getCodeBgColor = (code: PublicCode, virtualRowIndex: number): string => {
  if (code.status === 'used') {
    return '#fff4e5';
  }

  return virtualRowIndex % 2 === 0 ? 'transparent' : 'var(--content_tint_background)';
};

const PublicCodes = () => {
  const dispatch = useDispatch();
  const formSelector = formValueSelector('game');
  const target = useSelector<RootState, Target>((state) => formSelector(state, 'targetInfo'));
  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);
  const settings = useSelector<RootState>((state) => state.form.game?.values) as BaseGame;
  const prevRequestSave = usePrevious(vkData.requestSave);
  const saveError = useSelector<RootState, string>((state) => state.myGames.saveError);
  const publicCodes = useSelector<RootState, PublicCodesState>((state) => state.vk.publicCodes);
  const codeListRef = useRef<HTMLDivElement>(null);
  const [deleting, setDeleting] = useState(false);
  const [copyCode, setCopyCode] = useState(0);

  const [filteredCodes, setFilteredCodes] = useState<PublicCode[]>([]);
  const [search, setSearch] = useState('');

  const codes = useMemo(
    () => publicCodes.data[settings.mainGameSettingsId] || [],
    [publicCodes.data, settings.mainGameSettingsId]
  );

  const hasPremium = target?.vkTarif !== VkTarif.FREE;
  const publicCodesFetching = publicCodes.status === ApiStatus.FETCHING;

  const handleSubmit = useCallback(() => {
    dispatch(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));
    dispatch(myGamesActions.saveSettings.started({ gameId: settings.gameId }));
  }, [dispatch, settings.gameId]);

  const handleAddCodesClick = useCallback(() => {
    dispatch(vkActions.setActiveModal({ modal: ModalName.MAIN }));
    dispatch(
      vkActions.setModalContent({
        content: AddPublicCodesModal,
        title: 'Добавить промокоды',
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (vkData.requestSave && !prevRequestSave) {
      handleSubmit();
    }
  }, [vkData.requestSave, handleSubmit, prevRequestSave]);

  useEffect(() => {
    dispatch(vkActions.getPublicCodes.started({ mainGameSettingsId: settings.mainGameSettingsId }));
  }, [dispatch, settings.mainGameSettingsId]);

  useEffect(() => {
    setFilteredCodes(codes);
  }, [codes]);

  const handleCodeSearchChange = useCallback(
    (event) => {
      const value = event.target.value.toLowerCase();

      if (value.length >= 2) {
        const filtered = codes.filter((code) => contains(value, code.code));

        setFilteredCodes(filtered);
      } else {
        setFilteredCodes(codes);
      }

      setSearch(event.target.value);
    },
    [codes]
  );

  const handleDelete = async (code: PublicCode) => {
    if (deleting) {
      return;
    }

    setDeleting(true);

    try {
      await axios.post(
        `${config.api}/vk/public-codes/delete`,
        {
          codeId: code.id,
          mainGameSettingsId: settings.mainGameSettingsId,
          ...getStringParams(),
        },
        { withCredentials: true }
      );

      dispatch(
        vkActions.getPublicCodes.started({
          mainGameSettingsId: settings.mainGameSettingsId,
        })
      );
    } catch (error) {
      //
    }

    setDeleting(false);
  };

  const handleCopy = (code: PublicCode) => {
    copy(code.code);
    setCopyCode(code.id);

    setTimeout(() => {
      setCopyCode(0);
    }, 1000);
  };

  const rowVirtualizer = useVirtual({
    size: filteredCodes.length,
    parentRef: codeListRef,
    estimateSize: useCallback(() => 83, []),
  });

  const noCodes = !publicCodesFetching && codes.length === 0;

  return (
    <Wrap>
      <FormSection name="mainGameSettings">
        <FormLayout>
          <FormLayoutGroup top="Промокоды на дополнительные попытки">
            <Div>
              {hasPremium ? (
                <Field name="usePublicCodes" component={Switch} />
              ) : (
                <VkSwitch checked={false} disabled={true} />
              )}
            </Div>

            <FormStatus className="custom-form-status" mode="default">
              Позволит выдавать пользователям промокоды на дополнительные попытки.
              {!hasPremium && (
                <>
                  <br />
                  Доступно только для платной версии
                </>
              )}
            </FormStatus>
          </FormLayoutGroup>

          {settings.mainGameSettings.usePublicCodes && (
            <FormLayoutGroup top="Ваши промокоды">
              <Button onClick={handleAddCodesClick}>Добавить</Button>

              <Div>
                <Card size="l" mode="outline">
                  {publicCodesFetching && (
                    <LoaderWrap>
                      <Spinner />
                    </LoaderWrap>
                  )}

                  {noCodes ? (
                    <EmptyPlaceholder>Нет промокодов</EmptyPlaceholder>
                  ) : (
                    <SearchWrapper>
                      <Input value={search} onChange={handleCodeSearchChange} placeholder="Найти" />
                    </SearchWrapper>
                  )}

                  <CodeList ref={codeListRef}>
                    <div style={{ height: `${rowVirtualizer.totalSize}px`, position: 'relative' }}>
                      {rowVirtualizer.virtualItems.map((virtualRow) => {
                        const code = codes[virtualRow.index];

                        if (!code) {
                          return null;
                        }

                        return (
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              height: `${virtualRow.size}px`,
                              transform: `translateY(${virtualRow.start}px)`,
                              width: '100%',
                              backgroundColor: getCodeBgColor(code, virtualRow.index),
                            }}
                            key={code.id}
                          >
                            <SimpleCell
                              disabled
                              multiline
                              description={
                                <div>
                                  <Overflowed>
                                    на {code.attemptsCount}{' '}
                                    {pluralize(code.attemptsCount, 'попытку', 'попытки', 'попыток')}
                                  </Overflowed>
                                  <Overflowed bold>{getUsages(code)}</Overflowed>
                                </div>
                              }
                              after={
                                <>
                                  {copyCode === code.id ? (
                                    <Icon24DoneOutline />
                                  ) : (
                                    <Icon24CopyOutline
                                      onClick={() => {
                                        handleCopy(code);
                                      }}
                                    />
                                  )}

                                  <Icon24DeleteOutline
                                    onClick={() => {
                                      handleDelete(code);
                                    }}
                                  />
                                </>
                              }
                            >
                              <Overflowed>{code.code}</Overflowed>
                            </SimpleCell>
                          </div>
                        );
                      })}
                    </div>
                  </CodeList>
                </Card>
              </Div>
            </FormLayoutGroup>
          )}
        </FormLayout>
      </FormSection>

      {saveError && <Error>{saveError}</Error>}
    </Wrap>
  );
};

const Wrap = styled.div`
  padding-bottom: 45px;

  .custom-form-status {
    margin-top: 0;
  }
`;

const Error = styled.div`
  font-size: 12px;
  margin-top: 15px;
  color: red;
  padding: 12px;
`;

const CodeList = styled.div`
  width: 100%;
  height: 383px;
  overflow: auto;
  margin-bottom: 16px;
`;

const Overflowed = styled.div<{ bold?: boolean }>`
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(p) =>
    p.bold &&
    `
    font-size: 14px;
    font-weight:  bold;
  `};
`;

const LoaderWrap = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyPlaceholder = styled.div`
  opacity: 0.5;
  padding: 12px;
`;

const SearchWrapper = styled.div`
  padding: 12px 0;
`;

const withForm = reduxForm<any, any, any>({
  form: 'game',
})(PublicCodes);

const withStore = connect((state: RootState) => {
  const { mainSettingsId } = state.vk.gameSettings;

  return {
    initialValues: state.myGames.data.find(
      (game: any) => game.mainGameSettings.id === mainSettingsId
    ),
  };
}, {})(withForm);

export { withStore as PublicCodes };
