import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { GameProps, GameResult, LuckySettings } from '../common/types';
import { getSlides } from './get-slides';
import { Arrow } from './components';

import styles from './lucky.module.css';
import { useWindowResize } from 'src/core/hooks/useWindowResize';

type LuckyGameProps = Omit<GameProps, 'settings'> & {
  settings: LuckySettings;
};

const POINTS = [2000, 3500];

const EPS = 50;

const BR_PX = 768;

export const Lucky: FC<LuckyGameProps> = ({
  settings: { prizes, subtitleColor, arrowsColor, giftSrc },
  started,
  endGame,
  showResultScreen,
}) => {
  const mainSectionRef = useRef<HTMLDivElement>(null);
  const firstSlideSectionRef = useRef<HTMLDivElement>(null);
  const secondSlideSectionRef = useRef<HTMLDivElement>(null);
  const sliderWrapperRef = useRef<HTMLDivElement>(null);

  const [mainSectionWidth, setMainSectionWidth] = useState(0);
  const [slideSectionWidth, setSlideSectionWidth] = useState(0);
  const [prizeWidth, setPrizeWidth] = useState(150);

  const windowWidth = useRef(0);

  const speedRef = useRef(2);
  const dx1 = useRef(0);
  const dx2 = useRef(0);

  const idleRequestId = useRef(0);
  const speedUpRequestId = useRef(0);

  const prizeMargin = prizeWidth / 4;

  const slides = getSlides(prizes.prizes);

  const updateParams = useCallback(() => {
    windowWidth.current = window.innerWidth;

    const mobile = windowWidth.current <= BR_PX;

    setPrizeWidth(Math.ceil(mainSectionWidth / (mobile ? 2 : 4)));
  }, [mainSectionWidth]);

  useWindowResize(() => {
    if (windowWidth.current !== window.innerWidth && mainSectionRef.current) {
      dx1.current = 0;
      dx2.current = 0;

      setMainSectionWidth(mainSectionRef.current.getBoundingClientRect().width);

      updateParams();
    }
  });

  const handleTransitionEnd = () => {
    setTimeout(() => {
      showResultScreen();
    }, 300);
  };

  const setDx1 = useCallback((dx: number) => {
    if (firstSlideSectionRef.current) {
      dx1.current = dx;
      firstSlideSectionRef.current.style.transform = `translate3d(${dx}px, 0, 0)`;
    }
  }, []);

  const setDx2 = useCallback((dx: number) => {
    if (secondSlideSectionRef.current) {
      dx2.current = dx;
      secondSlideSectionRef.current.style.transform = `translate3d(${dx}px, 0, 0)`;
    }
  }, []);

  const rotate = useCallback(
    (result: GameResult) => {
      const start = Date.now();
      const upDx = window.innerWidth <= BR_PX ? 0.4 : 0.4;

      const isStartPosition = () => Math.abs(dx1.current) <= EPS;

      const prizeIndex = result.prizeIndex || 0;
      const prizeSizeWithMargins = prizeWidth + prizeMargin * 2;
      const mobile = window.innerWidth <= BR_PX;
      const diff = mobile ? prizeWidth / 4 : prizeWidth + prizeMargin;

      const transform = slideSectionWidth + prizeSizeWithMargins * prizeIndex - diff;

      const run = () => {
        const timePass = Date.now() - start >= POINTS[1];

        const stop = timePass && isStartPosition();

        if (stop) {
          speedRef.current = 0;
          setDx1(0);
          setDx2(0);

          if (sliderWrapperRef.current) {
            sliderWrapperRef.current.style.transform = `translate3d(-${transform}px, 0, 0)`;
          }
        } else {
          speedUpRequestId.current = window.requestAnimationFrame(run);
        }
      };

      const speedUp = () => {
        if (Date.now() - start <= POINTS[0]) {
          speedRef.current = speedRef.current + upDx;

          speedUpRequestId.current = window.requestAnimationFrame(speedUp);
        } else {
          speedUpRequestId.current = window.requestAnimationFrame(run);
        }
      };

      speedUpRequestId.current = window.requestAnimationFrame(speedUp);

      return () => {
        window.cancelAnimationFrame(speedUpRequestId.current);
      };
    },
    [prizeMargin, prizeWidth, setDx1, setDx2, slideSectionWidth]
  );

  useEffect(() => {
    if (started) {
      endGame().then((result) => {
        if (result) {
          rotate(result);
        }
      });
    }
  }, [started, endGame, rotate]);

  useEffect(() => {
    updateParams();
  }, [updateParams]);

  useEffect(() => {
    if (mainSectionRef.current) {
      const mainSectionWidth = mainSectionRef.current.getBoundingClientRect().width;

      setMainSectionWidth(mainSectionWidth);
    }

    if (firstSlideSectionRef.current) {
      const slideSectionWidth = firstSlideSectionRef.current.getBoundingClientRect().width;

      setSlideSectionWidth(slideSectionWidth);
    }
  }, [prizeWidth]);

  useEffect(() => {
    const move = () => {
      if (
        !firstSlideSectionRef.current ||
        !secondSlideSectionRef.current ||
        !mainSectionRef.current
      ) {
        idleRequestId.current = window.requestAnimationFrame(move);
        return;
      }

      const firstSlidesSectionRect = firstSlideSectionRef.current.getBoundingClientRect();
      const secondSlidesSectionRect = secondSlideSectionRef.current.getBoundingClientRect();
      const mainSectionRect = mainSectionRef.current.getBoundingClientRect();

      const speed = speedRef.current;

      let nextDx1 = Math.floor(dx1.current - speed);
      let nextDx2 = Math.floor(dx2.current - speed);

      if (firstSlidesSectionRect.right <= mainSectionRect.left) {
        nextDx1 = Math.floor(slideSectionWidth + speed);
      } else if (secondSlidesSectionRect.right <= mainSectionRect.left) {
        nextDx2 = Math.floor(speed);
      }

      setDx1(nextDx1);
      setDx2(nextDx2);

      if (speedRef.current) {
        idleRequestId.current = window.requestAnimationFrame(move);
      }
    };

    if (slideSectionWidth) {
      idleRequestId.current = window.requestAnimationFrame(move);
    }

    return () => {
      window.cancelAnimationFrame(idleRequestId.current);
    };
  }, [setDx1, setDx2, slideSectionWidth]);

  const renderPrizes = () =>
    slides.map(({ value, imageSrc, text, inactive = false }, index) => (
      <div
        className={styles.prize}
        key={`${index}-${value}`}
        style={{ width: `${prizeWidth}px`, margin: `0 ${prizeMargin}px` }}
      >
        <div
          className={styles.prizeImage}
          style={{
            backgroundImage: `url(${imageSrc || giftSrc})`,
            height: `${prizeWidth}px`,
            opacity: inactive ? 0.25 : 1,
          }}
        />

        <div className={styles.prizeTitle} style={{ color: subtitleColor }}>
          {inactive ? 'Приз закончился' : text}
        </div>
      </div>
    ));

  return (
    <div className={styles.mainSection} ref={mainSectionRef}>
      <div className={styles.arrow}>
        <Arrow color={arrowsColor} />
      </div>

      <div
        className={styles.sliderWrapper}
        ref={sliderWrapperRef}
        onTransitionEnd={handleTransitionEnd}
      >
        <div className={styles.firstSlidesSection} ref={firstSlideSectionRef}>
          {renderPrizes()}
        </div>

        <div className={styles.secondSlidesSection} ref={secondSlideSectionRef}>
          {renderPrizes()}
        </div>
      </div>

      <div className={cn(styles.arrow, styles.rotatedArrow)}>
        <Arrow color={arrowsColor} />
      </div>
    </div>
  );
};
