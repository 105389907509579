import actionCreatorFactory from 'typescript-fsa';
import { ApiError } from 'src/core/ApiStatus';
import { StatisticsEvent, CollectedUserData } from '../types';

const factory = actionCreatorFactory('STATISTICS');

export const statisticsActions = {
  get: factory.async<
    { mainSettingId: string },
    {
      events: StatisticsEvent;
      emails: CollectedUserData[];
      phones: CollectedUserData[];
      leads: CollectedUserData[];
    },
    ApiError
  >('GET'),
  reset: factory('RESET'),
};
