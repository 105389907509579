import { call, put, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { allGamesActions } from '../actions';
import { config } from 'src/config/config';
import { Game } from '../types';

const getGamesFetcher = () =>
  axios.get(`${config.api}/games`, { withCredentials: true });

function* getAllGames() {
  try {
    const res: AxiosResponse<Game[]> = yield call(getGamesFetcher);

    yield put(allGamesActions.get.done({ params: {}, result: res.data }));
  } catch (err) {
    yield put(
      allGamesActions.get.failed({ params: {}, error: { message: err.response.data.message } })
    );
  }
}

export function* watchGetAll() {
  yield takeLatest(allGamesActions.get.started, getAllGames);
}
