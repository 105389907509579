import React, { FC, ReactNode } from 'react';
import { Select as VkSelect } from '@vkontakte/vkui';
import { WrappedFieldProps } from 'redux-form';
import styled from 'styled-components';

type SelectOwnProps = {
  className?: string;
  placeholder?: string;
  options?: ReactNode[];
  label?: string;
  multiple?: boolean;
  disabled?: boolean;
};

type SelectProps = WrappedFieldProps & SelectOwnProps;

export const Select: FC<SelectProps> = ({
  input,
  placeholder,
  options = [],
  label,
  multiple,
  disabled,
}) => {
  return (
    <>
      <Label>{label}</Label>
      <VkSelect
        placeholder={placeholder}
        onChange={input.onChange}
        value={input.value}
        multiple={multiple}
        disabled={disabled}
      >
        {options.map((option) => option)}
      </VkSelect>
    </>
  );
};

const Label = styled.div`
  padding: 2px 12px 8px;
  font-size: 14px;
  line-height: 18px;
  color: #818c99;
  color: var(--text_secondary);
`;
