import { FormLayout, FormLayoutGroup, ScreenSpinner } from '@vkontakte/vkui';
import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, FormSection, reduxForm } from 'redux-form';
import { usePrevious } from 'src/core/hooks/usePrevious';
import { RootState } from 'src/core/rootReducer';
import { BaseGame } from 'src/games/common/types';
import { GameId } from 'src/store/game/types';
import { myGamesActions } from 'src/store/my-games/actions';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { RadioGroup, TextField } from 'src/vk-app/components';

const GAMES_ON_SKILLS = [GameId.NINJA, GameId.RUNNER];
const CAMPAIGN_TYPES = [
  {
    id: 'default',
    label: 'Розыгрыш призов',
    description: 'Моментальный розыгрыш призов за набранные очки',
  },
  {
    id: 'competition',
    label: 'Соревнования',
    description:
      'Пользователи играют и набирают очки в течение N дней. Отображается рейтинг игроков',
  },
];

const CampaignSettings = () => {
  const dispatch = useDispatch();
  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);
  const prevRequestSave = usePrevious(vkData.requestSave);
  const settings = useSelector<RootState>((state) => state.form.game?.values) as BaseGame;

  const gameWithRating = GAMES_ON_SKILLS.includes(settings.gameId);

  const handleSubmit = useCallback(() => {
    dispatch(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));
    dispatch(myGamesActions.saveSettings.started({ gameId: settings.gameId }));
  }, [dispatch, settings.gameId]);

  useEffect(() => {
    if (vkData.requestSave && !prevRequestSave) {
      handleSubmit();
    }
  }, [vkData.requestSave, handleSubmit, prevRequestSave]);

  return (
    <FormSection name="mainGameSettings">
      <FormLayout>
        <FormLayoutGroup top="Название кампании">
          <Field name="campaignName" component={TextField} maxLength={25} />
        </FormLayoutGroup>

        {gameWithRating && (
          <FormLayoutGroup top="Тип кампании">
            <Field name="campaignType" component={RadioGroup} options={CAMPAIGN_TYPES} />
          </FormLayoutGroup>
        )}
      </FormLayout>
    </FormSection>
  );
};

const withForm = reduxForm<any, any, any>({
  form: 'game',
})(CampaignSettings);

const withStore = connect((state: RootState) => {
  const { mainSettingsId } = state.vk.gameSettings;

  return {
    initialValues: state.myGames.data.find(
      (game: any) => game.mainGameSettings.id === mainSettingsId
    ),
  };
}, {})(withForm);

export { withStore as CampaignSettings };
