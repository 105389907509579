import React from 'react';

import { Props } from './WheelSvg';

const Wheel = React.forwardRef<SVGSVGElement, Props>(
  ({ onAnimationEnd, kantColor, lampColor, className }, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      viewBox="0 0 1000 1000"
      className={className}
      onTransitionEnd={onAnimationEnd}
    >
      <path
        d="M934.504 671.121c29.056-73.704 38.056-150.515 29.534-224.293a467.793 467.793 0 00-2.165-16.221c-11.43-75.587-41.289-147.466-86.788-208.909a464.225 464.225 0 00-9.956-12.965c-46.256-58.095-107.023-105.926-179.764-137.357a486.33 486.33 0 00-6.655-2.823 472.255 472.255 0 00-8.31-3.34c-73.752-28.937-150.591-37.814-224.375-29.162A462.47 462.47 0 00430.11 38.2c-75.598 11.514-147.468 41.466-208.879 87.059a460.133 460.133 0 00-12.574 9.671c-58.109 46.287-105.945 107.095-137.362 179.887a477.16 477.16 0 00-2.742 6.471 474.925 474.925 0 00-3.218 7.997c-29.004 73.769-37.928 150.636-29.311 224.454.605 5.176 1.3 10.336 2.076 15.479 11.41 75.642 41.273 147.576 86.797 209.061a462.722 462.722 0 009.511 12.409c46.192 58.203 106.928 106.148 179.659 137.687a469.96 469.96 0 007.223 3.071 468.749 468.749 0 007.278 2.936c73.721 29.122 150.558 38.169 224.369 29.681a460.106 460.106 0 0015.788-2.092c75.632-11.324 147.573-41.094 209.089-86.523a465.518 465.518 0 0012.802-9.798c58.188-46.161 106.127-106.856 137.682-179.536a483.693 483.693 0 003.15-7.404 502.983 502.983 0 003.056-7.589"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M566.138 586.844c14.757-11.217 26.85-26.344 34.524-44.656.058-.14.122-.278.18-.419 7.558-18.239 9.801-37.355 7.444-55.627a109.672 109.672 0 00-21.542-52.417c-11.294-14.811-26.527-26.929-44.974-34.57-18.447-7.641-37.788-9.845-56.247-7.358a109.686 109.686 0 00-52.297 21.833c-14.586 11.254-26.517 26.357-34.072 44.598-.059.14-.112.283-.169.424-7.524 18.375-9.669 37.62-7.165 55.988a109.69 109.69 0 0021.707 52c11.265 14.646 26.405 26.625 44.701 34.203 18.295 7.578 37.472 9.812 55.793 7.421a109.682 109.682 0 0052.118-21.419"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M680.931 83.795a7.884 7.884 0 10-14.568-6.034 7.884 7.884 0 0014.568 6.034z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M686.214 71.747c5.778-6.645 11.166-13.276 12.374-16.192 2.83-6.832-.415-14.665-7.246-17.495-6.833-2.83-14.665.414-17.495 7.247-1.208 2.915-2.087 11.415-2.7 20.2 2.525.994 5.049 2.005 7.565 3.047a486.33 486.33 0 016.655 2.823c.283.121.564.248.847.37z"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M333.636 77.761a7.884 7.884 0 10-14.568 6.035 7.884 7.884 0 0014.568-6.035z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M328.853 65.506c-.613-8.784-1.492-17.284-2.7-20.2-2.83-6.831-10.663-10.076-17.495-7.246-6.832 2.83-10.076 10.662-7.247 17.495 1.208 2.916 6.597 9.547 12.376 16.193a451.742 451.742 0 017.504-3.195c2.23-.924 4.464-1.823 6.701-2.71.286-.114.574-.224.861-.337z"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M83.796 319.069a7.884 7.884 0 10-6.035 14.566 7.884 7.884 0 006.035-14.566z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M71.747 313.785c-6.644-5.778-13.276-11.166-16.191-12.374-6.833-2.83-14.665.415-17.496 7.247-2.829 6.832.415 14.664 7.247 17.495 2.916 1.208 11.415 2.087 20.201 2.699a457.706 457.706 0 013.047-7.565c.924-2.23 1.868-4.446 2.822-6.654.122-.284.248-.565.37-.848"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M77.762 666.363a7.884 7.884 0 106.034 14.568 7.884 7.884 0 00-6.034-14.568z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M65.506 671.146c-8.784.613-17.283 1.492-20.199 2.7-6.832 2.83-10.076 10.663-7.247 17.495 2.831 6.832 10.663 10.076 17.495 7.247 2.916-1.208 9.548-6.596 16.193-12.376a456.626 456.626 0 01-3.194-7.504 481.312 481.312 0 01-2.71-6.7c-.114-.288-.224-.575-.338-.862"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M319.069 916.204a7.884 7.884 0 1014.567 6.033 7.884 7.884 0 00-14.567-6.033z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M313.786 928.252c-5.778 6.645-11.167 13.276-12.374 16.192-2.83 6.832.414 14.665 7.246 17.495 6.833 2.83 14.665-.415 17.496-7.247 1.207-2.916 2.086-11.415 2.698-20.2a470.294 470.294 0 01-7.564-3.048 474.104 474.104 0 01-6.655-2.822c-.283-.122-.565-.248-.847-.37"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M666.363 922.238a7.884 7.884 0 0010.301 4.266 7.883 7.883 0 10-6.034-14.567 7.886 7.886 0 00-4.267 10.301z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M671.147 934.493c.613 8.784 1.492 17.284 2.699 20.199 2.831 6.832 10.663 10.077 17.496 7.247 6.832-2.831 10.076-10.663 7.247-17.495-1.208-2.916-6.597-9.547-12.376-16.193a469.336 469.336 0 01-7.504 3.195c-2.23.924-4.465 1.822-6.701 2.71-.287.114-.575.224-.861.337z"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M916.204 680.93a7.884 7.884 0 106.034-14.568 7.884 7.884 0 00-6.034 14.568z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M928.253 686.214c6.645 5.778 13.276 11.166 16.191 12.374 6.832 2.83 14.665-.415 17.496-7.247 2.829-6.833-.415-14.665-7.247-17.495-2.916-1.208-11.415-2.087-20.201-2.699a457.706 457.706 0 01-3.047 7.565 468.994 468.994 0 01-2.822 6.654c-.122.284-.248.565-.37.848"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M922.238 333.636a7.884 7.884 0 10-6.034-14.568 7.884 7.884 0 006.034 14.568z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M934.494 328.852c8.784-.613 17.283-1.491 20.199-2.699 6.832-2.83 10.076-10.663 7.247-17.495-2.831-6.832-10.663-10.077-17.496-7.247-2.915 1.208-9.547 6.596-16.192 12.375a464.113 464.113 0 013.194 7.504c.924 2.23 1.823 4.465 2.71 6.701.114.287.224.575.338.861"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M331.92 94.218l126.309 304.939a109.629 109.629 0 0127.294-7.36c18.459-2.488 37.799-.284 56.248 7.358L668.08 94.218c-112.053-46.414-232.312-43.015-336.16 0"
        id="slot04"
      />
      <path
        d="M399.153 458.227L94.218 331.919c-46.414 112.054-43.015 232.312 0 336.161l304.939-126.31a109.65 109.65 0 01-7.338-27.13c-2.504-18.368-.359-37.614 7.165-55.988.057-.142.11-.284.169-.425"
        id="slot06"
      />
      <path
        d="M458.229 600.844L331.92 905.781c112.053 46.414 232.312 43.016 336.16 0L541.769 600.838a109.52 109.52 0 01-27.749 7.426c-18.321 2.391-37.498.157-55.791-7.42"
        id="slot00"
      />
      <path
        d="M600.842 541.769L905.781 668.08c46.414-112.054 43.016-232.312 0-336.161L600.84 458.23a109.488 109.488 0 017.446 27.912c2.356 18.272.113 37.387-7.444 55.627z"
        id="slot02"
      />
      <path
        d="M331.92 905.781l126.306-304.938c-18.295-7.578-33.435-19.558-44.7-34.204a109.479 109.479 0 01-14.369-24.869L94.218 668.08c43.016 103.848 125.648 191.287 237.702 237.701z"
        id="slot07"
      />
      <path
        d="M541.769 600.838L668.08 905.781c103.848-43.015 191.287-125.648 237.701-237.702l-304.939-126.31c-.059.141-.123.278-.181.419-7.674 18.312-19.766 33.439-34.523 44.656a109.636 109.636 0 01-24.37 13.994"
        id="slot01"
      />
      <path
        d="M600.84 458.23l304.941-126.311C862.766 228.071 780.133 140.632 668.08 94.218L541.77 399.155c18.447 7.641 33.68 19.759 44.974 34.57a109.653 109.653 0 0114.097 24.505"
        id="slot03"
      />
      <path
        d="M458.229 399.157L331.92 94.218C228.071 137.234 140.632 219.866 94.219 331.919l304.934 126.308c7.556-18.241 19.487-33.343 34.072-44.598a109.567 109.567 0 0125.004-14.472"
        id="slot05"
      />

      <text
        transform="translate(60.67 507.566)"
        id="text_slot06"
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
      ></text>

      <text
        transform="rotate(45 -147.105 318.355)"
        id="text_slot05"
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
      ></text>

      <text
        transform="rotate(45 -422.691 984.192)"
        id="text_slot01"
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
      ></text>

      <text
        transform="rotate(-45 1078.268 174.34)"
        id="text_slot07"
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
      ></text>

      <text
        transform="rotate(-45 800.091 -488.739)"
        id="text_slot03"
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
      ></text>

      <text
        transform="rotate(-90 447.169 -60.395)"
        id="text_slot04"
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
      ></text>

      <text
        transform="rotate(-90 723.448 215.884)"
        id="text_slot00"
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
      ></text>

      <text
        transform="translate(609.18 511.065)"
        id="text_slot02"
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
      ></text>
    </svg>
  )
);

export default Wheel;
