export enum Views {
  GAMES = 'games',
  MY_GAMES = 'my-games',
  ACCOUNT = 'account',
  GREETING = 'greeting',
  SHOW_GAME = 'show-game',
  ERROR = 'error',
  TARIF_DESCRIPTION = 'tarif-description',
}

type PanelType = {
  [key in Views]: { [key: string]: string };
};

export const Panels: PanelType = {
  [Views.GAMES]: {
    MAIN: 'main',
    GAME: 'game',
  },
  [Views.MY_GAMES]: {
    MAIN: 'main',
    GAME: 'game',
    FORM: 'form',
  },
  [Views.ACCOUNT]: {
    MAIN: 'main',
  },
  [Views.GREETING]: {
    MAIN: 'main',
  },
  [Views.SHOW_GAME]: {
    MAIN: 'main',
  },
  [Views.ERROR]: {
    MAIN: 'main',
  },
  [Views.TARIF_DESCRIPTION]: {
    MAIN: 'main',
  },
};

export enum SettingsForm {
  DESIGN = 'design',
  TEXT = 'text',
  PROMOCODES = 'promocodes',
  RULES = 'rules',
  PUBLISH = 'publish',
  SHOW_RESULT = 'show-result',
  STATISTICS = 'statistics',
  PREMIUM = 'premium',
  PUBLIC_CODES = 'public-codes',
  CAMPAIGN_SETTINGS = 'campaign-settings',
}

export enum ModalName {
  MAIN = 'main',
}

export const FormName = {
  [SettingsForm.DESIGN]: 'Дизайн',
  [SettingsForm.TEXT]: 'Тексты',
  [SettingsForm.PROMOCODES]: 'Призы',
  [SettingsForm.RULES]: 'Правила показа',
  [SettingsForm.PUBLISH]: 'Публикация',
  [SettingsForm.SHOW_RESULT]: 'Предпросмотр',
  [SettingsForm.STATISTICS]: 'Статистика',
  [SettingsForm.PREMIUM]: 'Премиум-функции',
  [SettingsForm.PUBLIC_CODES]: 'Доп. попытки',
  [SettingsForm.CAMPAIGN_SETTINGS]: 'Настройки кампании',
};

export type PublicCode = {
  id: number;
  code: string;
  mainGameSettingsId: number;
  attemptsCount: number;
  maxUsage?: number;
  status: string;
  usages: number;
};
