import React from 'react';
import { GameWrapProps } from 'src/games/common/types';
import { storage } from 'src/core/storage';
import { timeDifference } from 'src/core/utils/formatters';

import styles from './embed.module.css';

type EmbedComponentProps = {
  Game: React.ComponentType<GameWrapProps>;
  gameProps: any;
  localStorageKey: string;
};

export const EmbedComponent: React.FC<EmbedComponentProps> = ({
  Game,
  gameProps,
  localStorageKey,
}) => {
  const [counterText, setCounterText] = React.useState('');

  React.useEffect(() => {
    const lastShow = storage.getItem(localStorageKey);
    const lastShowMs = +lastShow;
    const now = Date.now();

    const breakMs = gameProps?.settings?.mainGameSettings?.gameBreak * 60 * 60 * 1000;

    if (now - lastShowMs < breakMs) {
      const text = `Повторить попытку можно через\xa0${timeDifference(
        new Date(lastShowMs + breakMs),
        new Date(now)
      )}`;

      setCounterText(text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGameOver = () => {
    const now = Date.now();
    const end = now + gameProps?.settings?.mainGameSettings?.gameBreak * 60 * 60 * 1000;

    const timeDiff = timeDifference(new Date(end), new Date(now));

    const text = `Повторить попытку можно через\xa0${timeDiff}`;

    setCounterText(text);
  };

  const attemptsLeftComponent = (
    <div>
      <div
        className={styles.attemptsLeftComponentTitle}
        style={{ color: gameProps?.settings?.titleColor }}
      >
        {counterText}
      </div>
    </div>
  );

  const disabledGame = Boolean(counterText);

  return (
    <Game
      {...gameProps}
      disabled={disabledGame}
      attemptsLeftComponent={attemptsLeftComponent}
      onGameOver={onGameOver}
    />
  );
};
