import React, { ChangeEvent, FC, ReactNode, useCallback } from 'react';
import { Radio } from '@vkontakte/vkui';
import { WrappedFieldProps } from 'redux-form';

type RadioGroupOption = {
  id: string;
  label?: ReactNode;
  description?: ReactNode;
};

type RadioGroupOwnProps = {
  className?: string;
  options: RadioGroupOption[];
};

type RadioGroupProps = WrappedFieldProps & RadioGroupOwnProps;

export const RadioGroup: FC<RadioGroupProps> = ({ input, options }) => {
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      input.onChange(event.target.value);
    },
    [input]
  );

  return (
    <>
      {options.map(({ id, label, description }) => {
        return (
          <Radio
            {...input}
            onChange={onChange}
            key={id}
            value={id}
            description={description}
            checked={input.value === id}
          >
            {label || id}
          </Radio>
        );
      })}
    </>
  );
};
