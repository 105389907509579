import { Dispatch, SetStateAction, useState } from 'react';

type UseApi = () => [
  boolean,
  Dispatch<SetStateAction<boolean>>,
  string,
  Dispatch<SetStateAction<string>>
];

export const useApi: UseApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  return [loading, setLoading, error, setError];
};
