import React, { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  FormLayout,
  FormLayoutGroup,
  FormStatus,
  Input,
  Link,
  ScreenSpinner,
  Textarea,
} from '@vkontakte/vkui';
import styled from 'styled-components';

import { integerRegexp } from '../integer-input';
import { config } from 'src/config/config';
import { RootState } from 'src/core/rootReducer';
import { BaseGame } from 'src/games/common/types';
import { vkActions } from 'src/store/vk/reducer';
import { getStringParams } from 'src/vk-app/utils/query';

const MAX_ATTEMPTS = 100;
const MAX_USAGE = 1000000;

const getCodes = (codes: string): string[] => {
  if (!codes) {
    return [];
  }

  return codes
    .replace(/\r\n/g, '\n')
    .split('\n')
    .map((code) => code.trim());
};

export const AddPublicCodesModal = () => {
  const dispatch = useDispatch();
  const [codes, setCodes] = useState('');
  const [attempts, setAttempts] = useState('3');
  const [maxUsage, setMaxUsage] = useState('');

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const settings = useSelector<RootState>((state) => state.form.game?.values) as BaseGame;

  const handleCodesChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>((event) => {
    setCodes(event.target.value);
  }, []);

  const handleAttemptsChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    if (event.target.value === '' || integerRegexp.test(event.target.value)) {
      const value = +event.target.value;

      if (value < MAX_ATTEMPTS) {
        setAttempts(event.target.value);
      }
    }
  }, []);

  const handleMaxUsageChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    if (event.target.value === '' || integerRegexp.test(event.target.value)) {
      const value = +event.target.value;

      if (value < MAX_USAGE) {
        setMaxUsage(event.target.value);
      }
    }
  }, []);

  const validateForm = useCallback(() => {
    const attemptsCount = +attempts;
    const maxUsageNumber = +maxUsage;

    const errors: Record<string, string> = {};

    if (isNaN(attemptsCount) || attempts === '') {
      errors.attemptsCount = 'Некорректное значение';
    }

    if (isNaN(maxUsageNumber)) {
      errors.maxUsage = 'Некорректное значение';
    }

    if (!codes) {
      errors.codes = 'Введите промокоды';
    }

    if (maxUsageNumber > MAX_USAGE) {
      errors.maxUsage = `Должно быть быть < ${MAX_USAGE}`;
    }

    if (attemptsCount > MAX_ATTEMPTS) {
      errors.attemptsCount = `Должно быть быть < ${MAX_ATTEMPTS}`;
    }

    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      setErrors(errors);
    }

    return !hasErrors;
  }, [attempts, codes, maxUsage]);

  const addCodes = useCallback(async () => {
    await axios.post(
      `${config.api}/vk/public-codes/add`,
      {
        codes: getCodes(codes),
        mainGameSettingsId: settings.mainGameSettingsId,
        attemptsCount: +attempts,
        maxUsage: +maxUsage,
        ...getStringParams(),
      },
      { withCredentials: true }
    );

    dispatch(vkActions.getPublicCodes.started({ mainGameSettingsId: settings.mainGameSettingsId }));
  }, [attempts, codes, dispatch, maxUsage, settings.mainGameSettingsId]);

  const handleAddButtonClick = useCallback(async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setErrors({});

    try {
      await addCodes();
      setLoading(false);
      dispatch(vkActions.setActiveModal({ modal: null }));
      dispatch(vkActions.setModalContent({ content: () => null, title: '' }));
    } catch (err) {
      setLoading(false);
      setErrors({
        api: 'Ошибка. Мы уже работаем над ее исправлением.',
      });
    }
  }, [addCodes, dispatch, validateForm]);

  const handleSubmitModal = useCallback(() => {
    if (validateForm()) {
      addCodes();
    }
  }, [addCodes, validateForm]);

  useEffect(() => {
    const submitModalButton = document.getElementById('submit-modal');

    if (submitModalButton) {
      submitModalButton.addEventListener('click', handleSubmitModal);
    }

    return () => {
      if (submitModalButton) {
        submitModalButton.removeEventListener('click', handleSubmitModal);
      }
    };
  }, [handleSubmitModal]);

  const renderError = (key: string) => (errors[key] ? <Error>{errors[key]}</Error> : null);

  return (
    <Wrapper>
      <FormLayout>
        <FormStatus mode="default">Каждый человек может использовать код один раз.</FormStatus>

        <FormLayoutGroup
          top="Введите промокоды. Каждый код с новой строки"
          bottom={
            <>
              {renderError('codes')}
              <Link href="https://randomus.ru/promocode" target="_blank" rel="noopener noreferrer">
                Удобный генератор промокодов
              </Link>
            </>
          }
        >
          <Textarea value={codes} onChange={handleCodesChange} />
        </FormLayoutGroup>

        <FormLayoutGroup
          top="Количество попыток, которое дает промокод"
          bottom={renderError('attemptsCount')}
        >
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            value={attempts}
            onChange={handleAttemptsChange}
          />
        </FormLayoutGroup>

        <FormLayoutGroup
          top="Лимит использования. Пустое значение - без лимита"
          bottom={renderError('maxUsage')}
        >
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            value={maxUsage}
            onChange={handleMaxUsageChange}
          />
        </FormLayoutGroup>

        <FormStatus mode="default">
          Лимит использования ограничивает количество людей, которые смогут использовать этот код,
          пока он станет недействительным.
        </FormStatus>

        <Button onClick={handleAddButtonClick} disabled={loading}>
          Добавить
        </Button>

        {errors.api && <Error>{errors.api}</Error>}

        {loading && <ScreenSpinner />}
      </FormLayout>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 24px;
`;

const Error = styled.div`
  font-size: 12px;
  color: red;
`;
