import {
  FormLayout,
  FormLayoutGroup,
  FormStatus,
  ScreenSpinner,
  Separator,
  Cell,
} from '@vkontakte/vkui';
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { FieldArray, reduxForm, Field } from 'redux-form';
import styled from 'styled-components';

import { RootState } from 'src/core/rootReducer';
import { ChestSettings } from 'src/games/common/types';
import { myGamesActions } from 'src/store/my-games/actions';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { Promocodes as PromocodesList, Switch } from 'src/vk-app/components';
import { usePrevious } from 'src/core/hooks/usePrevious';

const Promocodes = () => {
  const values = useSelector<RootState>((state) => state.form.game.values) as ChestSettings;
  const dispatch = useDispatch();

  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);

  const handleSubmit = useCallback(() => {
    dispatch(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));
    dispatch(myGamesActions.saveSettings.started({ gameId: values.gameId }));
  }, [dispatch, values.gameId]);

  const prevRequestSave = usePrevious(vkData.requestSave);

  useEffect(() => {
    if (vkData.requestSave && !prevRequestSave) {
      handleSubmit();
    }
  }, [vkData.requestSave, handleSubmit, prevRequestSave]);

  return (
    <Wrap>
      <FormLayout>
        <FormStatus header="Расположите призы в порядке увеличения их ценности">
          Призом может быть промокод или ссылка. Чем больше игрок наберет очков, тем более ценный
          приз он получит. Призы распределятся равномерно от 1 до 100 очков.
        </FormStatus>

        <Cell asideContent={<Field name="prizesPreview" component={Switch} />}>
          Выводить список призов
          <br />
          на экран
        </Cell>

        <Separator />

        <FormLayoutGroup top="Призы">
          <FieldArray
            name="prizes.prizes"
            disableChances={true}
            // @ts-ignore
            component={PromocodesList}
          />
        </FormLayoutGroup>
      </FormLayout>
    </Wrap>
  );
};

const Wrap = styled.div``;

const withForm = reduxForm<any, any, any>({
  form: 'game',
})(Promocodes);

const withStore = connect((state: RootState) => {
  const { mainSettingsId } = state.vk.gameSettings;

  return {
    initialValues: state.myGames.data.find(
      (game: any) => game.mainGameSettings.id === mainSettingsId
    ),
  };
}, {})(withForm);

export { withStore as Promocodes };
