import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Button,
  PanelHeader,
  PanelHeaderBack,
  Separator,
  Text,
  FormLayout,
  FormLayoutGroup,
  Input,
} from '@vkontakte/vkui';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { VKBridge } from '@vkontakte/vk-bridge';

import { AllGamesInitState } from 'src/store/game/reducers/allGamesReducer';
import { RootState } from 'src/core/rootReducer';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { GameId } from 'src/store/game/types';
import { getVkQuery } from 'src/vk-app/utils/query';
import Wheel from 'src/pages/Game/wheel/WheelDemo';
import Box from 'src/pages/Game/box/BoxDemo';
import Chest from 'src/pages/Game/chest/ChestDemo';
import Runner from 'src/pages/Game/runner/RunnerDemo';
import Ninja from 'src/pages/Game/ninja/NinjaDemo';
import Lucky from 'src/pages/Game/lucky/lucky-demo';
import { ModalName, Panels, Views } from 'src/store/vk/types';
import { myGamesActions } from 'src/store/my-games/actions';
import { TargetsInitState } from 'src/store/targets/reducers';
import { MyGamesInitState } from 'src/store/my-games/reducers';
import { usePrevious } from 'src/core/hooks/usePrevious';

const getGame = (gameId: GameId | ''): any => {
  switch (gameId) {
    case GameId.WHEEL:
      return Wheel;
    case GameId.BOX:
      return Box;
    case GameId.CHEST:
      return Chest;
    case GameId.RUNNER:
      return Runner;
    case GameId.NINJA:
      return Ninja;
    case GameId.LUCKY:
      return Lucky;
    default:
      return Runner;
  }
};

type Props = {
  bridge: VKBridge;
};

type CreateCampaignModalProps = {
  gameName: string;
  handleSubmitModal: (campaignName: string) => void;
};

const CreateCampaignModal: FC<CreateCampaignModalProps> = ({ gameName, handleSubmitModal }) => {
  const [campaignName, setCampaignName] = useState(gameName);

  const onSubmit = useCallback(() => {
    handleSubmitModal(campaignName);
  }, [campaignName, handleSubmitModal]);

  useEffect(() => {
    const submitModalButton = document.getElementById('submit-modal');

    if (submitModalButton) {
      submitModalButton.addEventListener('click', onSubmit);
    }

    return () => {
      if (submitModalButton) {
        submitModalButton.removeEventListener('click', onSubmit);
      }
    };
  }, [onSubmit]);

  return (
    <FormLayout style={{ minHeight: '250px' }}>
      <FormLayoutGroup top="Название кампании">
        <Input
          value={campaignName}
          onChange={(e) => {
            setCampaignName(e.target.value);
          }}
          maxLength={500}
        />
      </FormLayoutGroup>
    </FormLayout>
  );
};

export const GameDemoPanel: FC<Props> = ({ bridge }) => {
  const dispatch = useDispatch();
  const gamesData = useSelector<RootState, AllGamesInitState>((state) => state.games.allGames);
  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);
  const targets = useSelector<RootState, TargetsInitState>((state) => state.targets);
  const myGames = useSelector<RootState, MyGamesInitState>((state) => state.myGames);

  const [gameAdded, setGameAdded] = useState(false);
  const [tabbarHeight, setTabbarHeight] = useState(0);

  const game = gamesData.data.find((g) => g.gameId === vkData.gameIdDemo);
  const Game = getGame(vkData.gameIdDemo);
  const query = getVkQuery();
  const currentTarget = targets.data.targets.find(
    (t) => t.targetType === 'VK_CLUB' && t.vkClubID === `${query.vk_group_id}`
  );

  const prevAddInProgress = usePrevious(myGames.addInProgress);

  const createCampaign = useCallback(
    (campaignName: string) => {
      if (currentTarget && game) {
        dispatch(
          myGamesActions.addGame.started({
            gameId: game.gameId,
            targetId: currentTarget.id,
            campaignName,
          })
        );
      }
    },
    [currentTarget, dispatch, game]
  );

  const handleCreateCampaignButtonClick = useCallback(() => {
    if (currentTarget && game) {
      dispatch(vkActions.setActiveModal({ modal: ModalName.MAIN }));
      dispatch(
        vkActions.setModalContent({
          content: () => (
            <CreateCampaignModal gameName={game.name} handleSubmitModal={createCampaign} />
          ),
          title: 'Новая кампания',
        })
      );
    }
  }, [createCampaign, currentTarget, dispatch, game]);

  const handleSettingsButtonClick = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, __, gameId, mainSettingsId] = myGames.addedGameUrl.split('/');

    dispatch(
      vkActions.setGameSettings({ gameId: gameId as GameId, mainSettingsId: +mainSettingsId })
    );
    dispatch(vkActions.setView({ view: Views.MY_GAMES }));
    dispatch(vkActions.setPanel({ panel: Panels[Views.MY_GAMES]['GAME'] }));
  }, [dispatch, myGames.addedGameUrl]);

  useEffect(() => {
    if (prevAddInProgress && !myGames.addInProgress && !myGames.addError) {
      setGameAdded(true);
    }
  }, [myGames.addError, myGames.addInProgress, myGames.addedGameUrl, prevAddInProgress]);

  useEffect(() => {
    const tabbar = document.querySelector('.Tabbar');

    if (tabbar) {
      setTabbarHeight(tabbar.clientHeight);
    }
  }, []);

  return game ? (
    <>
      <PanelHeader
        left={
          <PanelHeaderBack
            onClick={() => {
              dispatch(vkActions.setPanel({ panel: Panels[Views.GAMES]['MAIN'] }));
            }}
          />
        }
      >
        {game.name}
      </PanelHeader>

      <br />

      <Game isDemo={true} bridge={bridge} />

      <br />

      <Separator />

      <Content>
        <ButtonWrap style={{ bottom: tabbarHeight ? `${tabbarHeight + 12}px` : '60px' }}>
          {gameAdded ? (
            <Button size="xl" mode="secondary" onClick={handleSettingsButtonClick}>
              Перейти в настройки
            </Button>
          ) : (
            <Button
              size="xl"
              onClick={handleCreateCampaignButtonClick}
              disabled={myGames.addInProgress}
            >
              Создать кампанию
            </Button>
          )}
        </ButtonWrap>

        <Text
          weight="regular"
          style={{ marginBottom: 16 }}
          className="eg-game-description"
          dangerouslySetInnerHTML={{ __html: game.description }}
        />
      </Content>
    </>
  ) : null;
};

const Content = styled.div`
  padding: 20px;
  padding-bottom: 70px;
`;

const ButtonWrap = styled.div`
  position: fixed;
  z-index: 1;
  width: calc(100% - 40px);
  background-color: var(--background_content);
  border-radius: 10px;
`;
