import React from 'react';

import { Props } from './WheelSvg';

const Wheel = React.forwardRef<SVGSVGElement, Props>(
  ({ onAnimationEnd, kantColor, lampColor, className }, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      viewBox="0 0 1000 1000"
      className={className}
      onTransitionEnd={onAnimationEnd}
    >
      <path
        d="M944.798 636.101c22.992-75.153 25.818-151.845 11.484-224.198a461.148 461.148 0 00-3.441-15.885c-17.366-73.92-52.74-142.761-102.816-200.081a462.276 462.276 0 00-10.919-12.068c-50.562-53.948-114.719-96.63-189.463-122.17a488.432 488.432 0 00-6.833-2.284 475.49 475.49 0 00-8.52-2.67c-75.548-22.993-152.567-25.902-225.157-11.699a465.345 465.345 0 00-15.635 3.343c-74.163 17.175-143.158 52.308-200.52 102.119-3.962 3.44-7.87 6.95-11.718 10.53-54.027 50.245-96.696 114.073-122.106 188.503a461.677 461.677 0 00-2.208 6.614 470.858 470.858 0 00-2.56 8.159c-22.935 75.212-25.682 151.955-11.249 224.339a456.073 456.073 0 003.293 15.159c17.35 73.976 52.733 142.871 102.837 200.23a464.234 464.234 0 0010.433 11.552c50.507 54.06 114.642 96.858 189.384 122.505 2.462.845 4.932 1.678 7.418 2.486 2.486.808 4.973 1.585 7.462 2.348 75.533 23.18 152.563 26.257 225.192 12.214a463.282 463.282 0 0015.515-3.277c74.211-16.99 143.29-51.948 200.77-101.604a461.11 461.11 0 0011.934-10.674c54.117-50.127 96.898-113.85 122.453-188.18a476.161 476.161 0 002.539-7.568c.836-2.578 1.64-5.161 2.431-7.743"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M572.251 580.876c13.764-12.228 24.569-28.12 30.733-46.827.047-.144.1-.285.146-.429 6.054-18.626 6.761-37.712 2.967-55.613-3.952-18.65-12.794-36.01-25.565-50.22-12.395-13.792-28.489-24.618-47.417-30.768-18.929-6.15-38.312-6.852-56.447-2.979-18.685 3.99-36.041 12.837-50.202 25.602-13.59 12.252-24.237 28.108-30.29 46.735-.046.144-.088.289-.134.433-6.009 18.758-6.608 37.965-2.66 55.949a109.082 109.082 0 0025.695 49.794c12.354 13.631 28.343 24.327 47.116 30.426 18.774 6.1 37.997 6.844 56.002 3.077a109.08 109.08 0 0050.057-25.18"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M646.228 74.327c1.335-4.107-.929-8.525-5.057-9.866-4.127-1.341-8.556.902-9.891 5.009-1.334 4.108.93 8.525 5.058 9.867 4.127 1.341 8.555-.902 9.89-5.01z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M650.517 62.001c5.209-7.017 10.033-13.99 11-16.968 2.267-6.977-1.579-14.479-8.589-16.757-7.011-2.278-14.531 1.531-16.798 8.508-.967 2.978-1.164 11.455-1.074 20.195 2.587.791 5.174 1.598 7.756 2.437a480.01 480.01 0 016.834 2.284c.291.098.58.202.871.301"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M366.81 69.097c-1.346-4.111-5.78-6.367-9.904-5.038a7.797 7.797 0 00-5.029 9.853c1.346 4.111 5.781 6.366 9.905 5.037a7.796 7.796 0 005.028-9.852"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M363 56.594c.065-8.739-.155-17.217-1.131-20.197-2.287-6.983-9.819-10.813-16.823-8.556-7.004 2.259-10.828 9.75-8.541 16.733.976 2.98 5.819 9.968 11.049 17.001 2.556-.874 5.12-1.734 7.7-2.566a485.244 485.244 0 016.865-2.15c.293-.09.587-.176.881-.265"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M138.136 228.457c-3.513-2.544-8.425-1.776-10.97 1.716s-1.759 8.386 1.754 10.931 8.424 1.777 10.969-1.715c2.544-3.492 1.76-8.387-1.753-10.932z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M127.681 220.553c-5.104-7.123-10.284-13.867-12.831-15.711-5.967-4.322-14.308-3.018-18.63 2.914-4.322 5.932-2.99 14.243 2.978 18.565 2.546 1.845 10.579 4.674 18.952 7.313a466.18 466.18 0 014.702-6.589 485.049 485.049 0 014.274-5.761c.184-.245.371-.487.555-.731z"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M47.549 491.538c-4.338-.007-7.85 3.492-7.844 7.813.006 4.321 3.528 7.829 7.866 7.835 4.338.006 7.849-3.492 7.844-7.813-.006-4.321-3.528-7.829-7.866-7.835z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M34.444 491.251c-8.324-2.786-16.486-5.22-19.63-5.224-7.368-.01-13.332 5.931-13.322 13.27.011 7.34 5.992 13.298 13.36 13.308 3.144.004 11.299-2.406 19.616-5.169a463.618 463.618 0 01-.091-8.094c-.004-2.396.018-4.786.05-7.174l.017-.917"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M129.652 757.85a7.798 7.798 0 00-1.723 10.927c2.555 3.499 7.469 4.281 10.974 1.746a7.796 7.796 0 001.723-10.926c-2.555-3.499-7.469-4.281-10.974-1.747"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M118.902 765.291c-8.364 2.615-16.39 5.421-18.931 7.258-5.954 4.306-7.264 12.614-2.925 18.558 4.34 5.943 12.684 7.272 18.639 2.966 2.541-1.838 7.703-8.566 12.787-15.675a465.629 465.629 0 01-4.85-6.509 485.002 485.002 0 01-4.192-5.845c-.177-.25-.351-.503-.528-.753"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M353.083 925.673c-1.334 4.107.93 8.525 5.057 9.866 4.128 1.341 8.556-.902 9.891-5.009 1.334-4.108-.93-8.525-5.057-9.867-4.127-1.341-8.556.902-9.891 5.01z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M348.795 937.999c-5.21 7.016-10.034 13.99-11.001 16.968-2.266 6.977 1.579 14.479 8.589 16.757 7.011 2.277 14.532-1.531 16.798-8.508.968-2.978 1.165-11.455 1.074-20.195a468.846 468.846 0 01-7.756-2.437 475.697 475.697 0 01-6.833-2.284c-.291-.099-.58-.202-.871-.301"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M632.501 930.903c1.346 4.111 5.781 6.367 9.904 5.037a7.797 7.797 0 005.03-9.852c-1.347-4.112-5.782-6.366-9.905-5.037a7.797 7.797 0 00-5.029 9.852z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M636.312 943.406c-.066 8.739.155 17.217 1.131 20.197 2.287 6.983 9.818 10.813 16.822 8.556 7.004-2.259 10.829-9.75 8.542-16.733-.976-2.981-5.82-9.968-11.05-17.001a456.844 456.844 0 01-7.7 2.566 489.233 489.233 0 01-6.864 2.15c-.294.09-.588.176-.881.265z"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M861.176 771.542c3.513 2.545 8.424 1.777 10.969-1.715s1.76-8.387-1.753-10.931c-3.514-2.545-8.424-1.777-10.969 1.715s-1.76 8.386 1.753 10.931z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M871.631 779.447c5.103 7.123 10.284 13.867 12.83 15.711 5.967 4.322 14.308 3.018 18.631-2.914 4.322-5.932 2.99-14.243-2.978-18.566-2.547-1.844-10.58-4.673-18.952-7.312a460.461 460.461 0 01-4.703 6.588 477.128 477.128 0 01-4.274 5.762c-.184.245-.37.487-.554.731"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M951.763 508.462c4.337.006 7.85-3.492 7.843-7.813-.006-4.321-3.528-7.829-7.866-7.835-4.338-.006-7.849 3.492-7.843 7.813.006 4.32 3.527 7.829 7.866 7.835z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M964.868 508.749c8.323 2.786 16.485 5.22 19.629 5.224 7.368.01 13.333-5.931 13.322-13.27-.011-7.34-5.991-13.298-13.359-13.308-3.145-.004-11.3 2.406-19.616 5.169.051 2.693.086 5.391.091 8.094.003 2.396-.019 4.786-.051 7.174-.004.306-.012.611-.016.917"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M869.66 242.15a7.798 7.798 0 001.722-10.927c-2.555-3.499-7.468-4.281-10.974-1.746-3.506 2.535-4.276 7.427-1.722 10.926 2.554 3.499 7.467 4.282 10.974 1.747z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M880.41 234.709c8.363-2.615 16.389-5.421 18.93-7.259 5.955-4.305 7.264-12.613 2.925-18.557-4.34-5.943-12.683-7.272-18.638-2.966-2.541 1.838-7.703 8.566-12.787 15.675 1.63 2.153 3.25 4.32 4.849 6.509 1.417 1.94 2.81 3.89 4.192 5.845.178.25.352.503.529.753"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M145.966 755.735l265.791-192.18a109.033 109.033 0 01-18.375-40.796 107.432 107.432 0 01-2.505-22.913l-328.941-.464c.112 92.932 30.294 182.618 84.03 256.353z"
        id="slot08"
      />
      <path
        d="M466.195 602.98L365.017 914.376c91.472 29.722 185.593 27.849 270.462.412l-102.07-311.709a108.825 108.825 0 01-11.214 2.977c-18.005 3.767-37.229 3.023-56-3.076z"
        id="slot00"
      />
      <path
        d="M587.745 563.811l266.396 192.975c26.266-36.06 47.365-76.797 61.889-121.498 14.525-44.701 21.399-90.059 21.346-134.67l-328.94-.464c.013 11.086-1.695 22.358-5.306 33.466-.047.144-.099.285-.146.429-3.605 10.94-8.797 20.917-15.24 29.762"
        id="slot02"
      />
      <path
        d="M587.549 436.449l265.796-192.184c-52.533-72.083-127.578-128.92-219.05-158.641L533.114 397.019c18.929 6.15 35.022 16.976 47.417 30.768a109.248 109.248 0 017.018 8.661"
        id="slot04"
      />
      <path
        d="M465.902 396.921L363.833 85.212c-86.815 28.067-163.948 82.885-218.663 158.002l266.388 192.969a107.447 107.447 0 0114.908-16.541 108.987 108.987 0 0139.436-22.721"
        id="slot06"
      />
      <path
        d="M853.345 244.265L587.549 436.449a108.968 108.968 0 0118.548 41.558 107.507 107.507 0 012.339 22.147l328.94.464c-.113-92.932-30.294-182.618-84.031-256.353"
        id="slot03"
      />
      <path
        d="M411.558 436.183L145.17 243.214c-26.265 36.06-47.365 76.796-61.889 121.498-14.524 44.701-21.399 90.059-21.345 134.67l328.941.464c-.014-10.943 1.65-22.066 5.165-33.036.046-.144.087-.289.134-.433 3.61-11.109 8.854-21.233 15.382-30.194"
        id="slot07"
      />
      <path
        d="M533.116 397.02L634.295 85.624c-91.472-29.721-185.594-27.85-270.462-.412l102.069 311.709a109.19 109.19 0 0110.766-2.881c18.134-3.874 37.518-3.171 56.448 2.98"
        id="slot05"
      />
      <path
        d="M411.757 563.555l-265.791 192.18c52.533 72.083 127.579 128.92 219.05 158.641l101.177-311.397c-18.773-6.1-34.763-16.796-47.116-30.427a108.879 108.879 0 01-7.32-8.997"
        id="slot09"
      />
      <path
        d="M587.745 563.811a107.554 107.554 0 01-15.494 17.065 109.048 109.048 0 01-38.842 22.203l102.07 311.709c86.814-28.067 163.947-82.885 218.662-158.002L587.745 563.811z"
        id="slot01"
      />

      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(-18.01 2063.56 43.652)"
        id="text_slot08"
      ></text>

      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(18.01 -1113.322 450.87)"
        id="text_slot07"
      ></text>

      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(53.98 -27.436 312.395)"
        id="text_slot06"
      ></text>

      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(53.98 -298.592 847.917)"
        id="text_slot01"
      ></text>

      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(18.01 -1412.667 2170.284)"
        id="text_slot02"
      ></text>

      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(-18.01 1775.172 -1674.024)"
        id="text_slot03"
      ></text>

      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(-53.98 689.323 -349.341)"
        id="text_slot04"
      ></text>

      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(-53.98 959.984 187.153)"
        id="text_slot09"
      ></text>

      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(-90 719.856 216.164)"
        id="text_slot00"
      ></text>

      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(-90 445.072 -58.62)"
        id="text_slot05"
      ></text>
    </svg>
  )
);

export default Wheel;
