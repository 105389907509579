import React, { FC } from 'react';
import Slider from 'react-slick';

import { GameWrap } from '../common/game-wrap';
import { GameWrapProps } from '../common/types';
import { Ninja } from './ninja';

type Props = Omit<GameWrapProps, 'GameContent'>;

const NinjaGame: FC<Props> = (props) => {
  return <GameWrap {...props} GameContent={Ninja} Slider={Slider} />;
};

export { NinjaGame };

export default NinjaGame;
