import styled from 'styled-components';
import React, { useCallback, useEffect } from 'react';
import { Field, FormSection, reduxForm } from 'redux-form';
import { FormLayout, FormLayoutGroup, ScreenSpinner } from '@vkontakte/vkui';
import { RootState } from 'src/core/rootReducer';
import { connect, useDispatch, useSelector } from 'react-redux';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { myGamesActions } from 'src/store/my-games/actions';
import { BaseGame } from 'src/games/common/types';
import { usePrevious } from 'src/core/hooks/usePrevious';
import { IntegerInput } from 'src/vk-app/components';

const Rules = () => {
  const values = useSelector<RootState>((state) => state.form.game.values) as BaseGame;
  const dispatch = useDispatch();

  const handleSubmit = useCallback(() => {
    dispatch(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));
    dispatch(myGamesActions.saveSettings.started({ gameId: values.gameId }));
  }, [dispatch, values.gameId]);

  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);

  const prevRequestSave = usePrevious(vkData.requestSave);

  useEffect(() => {
    if (vkData.requestSave && !prevRequestSave) {
      handleSubmit();
    }
  }, [vkData.requestSave, handleSubmit, prevRequestSave]);

  const saveError = useSelector<RootState, string>((state) => state.myGames.saveError);

  return (
    <Wrap>
      <FormSection name="mainGameSettings">
        <FormLayout>
          <FormLayoutGroup
            top="Игра недоступна в течение (ч)"
            bottom="Сколько часов игра будет недоступна после того, как пользователь в нее сыграл"
          >
            <Field name="gameBreak" component={IntegerInput} maxValue={10000} />
          </FormLayoutGroup>
        </FormLayout>
      </FormSection>

      {saveError && <Error>{saveError}</Error>}
    </Wrap>
  );
};

const Wrap = styled.div``;

const Error = styled.div`
  font-size: 12px;
  margin-top: 15px;
  color: red;
  padding: 12px;
`;

const withForm = reduxForm<any, any, any>({
  form: 'game',
})(Rules);

const withStore = connect((state: RootState) => {
  const { mainSettingsId } = state.vk.gameSettings;

  return {
    initialValues: state.myGames.data.find(
      (game: any) => game.mainGameSettings.id === mainSettingsId
    ),
  };
}, {})(withForm);

export { withStore as Rules };
