import { DAY_SHOWS_LIMIT } from 'src/core/constants';
import { Tarif, VkTarif } from 'src/store/auth/types';

export const tarifName = {
  [Tarif.FREE]: 'Бесплатный',
  [Tarif.THREE]: 'Премиум',
  [Tarif.UNLIM]: 'Бизнес',
};

export const vkTarifName = {
  [VkTarif.FREE]: 'Бесплатный',
  [VkTarif.PREMIUM]: 'Премиум',
};

export const tarifCost = {
  [Tarif.FREE]: '0 ₽',
  [Tarif.THREE]: '699 ₽',
  [Tarif.UNLIM]: '999 ₽',
};

export const vkTarifCost = {
  [VkTarif.FREE]: '0 ₽',
  [VkTarif.PREMIUM]: '399 ₽',
};

export const tarifTargetsAmount = {
  [Tarif.FREE]: 5,
  [Tarif.THREE]: 5,
  [Tarif.UNLIM]: 10,
};

export const tarifDescription = {
  [Tarif.FREE]: `до ${DAY_SHOWS_LIMIT} пользователей за день смогут сыграть в вашу игру`,
  [Tarif.THREE]: '',
  [Tarif.UNLIM]: '',
};
