import qs from 'querystring';

const AVAILABLE_SEARCH_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'utm_id',
];

export const SEARCH_PARAMS_KEY = 'embed_games_search_params';

export function saveEnv(globalObj: any, storage: any) {
  const searchParams = qs.parse(globalObj.location.search.slice(1));

  const existedParams = JSON.parse(storage.getItem(SEARCH_PARAMS_KEY) || '{}');

  if (!searchParams['utm_source']) {
    return existedParams;
  }

  const newParams: { [key: string]: string } = {};

  AVAILABLE_SEARCH_PARAMS.forEach((paramName) => {
    if (searchParams[paramName]) {
      newParams[paramName] = searchParams[paramName] as string;
    }
  });

  storage.setItem(SEARCH_PARAMS_KEY, JSON.stringify(newParams));

  return newParams;
}

export function getEnv(storage: any) {
  return JSON.parse(storage.getItem(SEARCH_PARAMS_KEY) || '{}');
}

export const getEnvForVk = () => {
  const hash = window.location.hash.replace(/^#/, '');
  const env: Record<string, string> = {};

  try {
    if (hash) {
      const searchParams = new URLSearchParams(hash);

      AVAILABLE_SEARCH_PARAMS.forEach((paramName) => {
        if (searchParams.get(paramName)) {
          env[paramName] = searchParams.get(paramName) as string;
        }
      });
    }

    const searchParams = new URLSearchParams(window.location.search);

    const vkRef = searchParams.get('vk_ref');

    if (vkRef && vkRef !== 'other') {
      env['vk_ref'] = vkRef as string;
    }

    return env;
  } catch (err) {
    return;
  }
};
