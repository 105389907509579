export enum GameId {
  WHEEL = 'WHEEL',
  BOX = 'BOX',
  CHEST = 'CHEST',
  RUNNER = 'RUNNER',
  NINJA = 'NINJA',
  LUCKY = 'LUCKY'
}

export type Game = {
  id: number;
  name: string;
  shortDescription: string;
  description: string;
  cover: string;
  cost: number;
  images: string[];
  gameId: GameId;
  costDescription: string;
  coverReal: string;
  backgroundVariants?: string[];
  comingSoon: boolean;
};

export enum ShowGameRules {
  AUTO_OPEN = 'AUTO_OPEN',
  CLICK_ON_GIFT = 'CLICK_ON_GIFT',
  CLICK_ON_GIFT_AND_AUTO_OPEN = 'CLICK_ON_GIFT_AND_AUTO_OPEN',
  RENDER_TO_DIV = 'RENDER_TO_DIV',
}

export enum IntegrationsEnum {
  EMAIL = 'EMAIL',
  HOOK = 'HOOK',
  YA_METRIKA = 'YA_METRIKA',
  BITRIX = 'BITRIX',
  AMOCRM = 'AMOCRM',
  GETRESPONSE = 'GETRESPONSE',
  SENLER = 'SENLER',
  ROISTAT = 'ROISTAT',
}
