import React, { FC, useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormLayout, FormLayoutGroup, FormStatus, ScreenSpinner, Separator } from '@vkontakte/vkui';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';

import { RootState } from 'src/core/rootReducer';
import { LuckySettings } from 'src/games/common/types';
import { ColorSelect, ImageSelect } from 'src/vk-app/components';
import { myGamesActions } from 'src/store/my-games/actions';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { usePrevious } from 'src/core/hooks/usePrevious';
import { giftVariants } from '../common/design';

type Props = {
  initialValues: LuckySettings;
};

const Design: FC<Props> = () => {
  const formSelector = formValueSelector('game');
  const values = useSelector<RootState>((state) => state.form.game?.values) as LuckySettings;
  const dispatch = useDispatch();
  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);

  const handleSubmit = useCallback(() => {
    dispatch(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));
    dispatch(myGamesActions.saveSettings.started({ gameId: values.gameId }));
  }, [dispatch, values.gameId]);

  const prevRequestSave = usePrevious(vkData.requestSave);

  useEffect(() => {
    if (vkData.requestSave && !prevRequestSave) {
      handleSubmit();
    }
  }, [vkData.requestSave, handleSubmit, prevRequestSave]);

  const backgroundColor = useSelector<RootState, string>((state) =>
    formSelector(state, 'backgroundColor')
  );

  return (
    <Wrap>
      <FormLayout>
        <FormLayoutGroup top="Цвет фона">
          <Field name="backgroundColor" component={ColorSelect} top="Цвет фона" />
        </FormLayoutGroup>

        <FormLayoutGroup top="Цвет стрелочек">
          <Field name="arrowsColor" component={ColorSelect} top="Цвет фона" />
        </FormLayoutGroup>

        <Separator />

        <FormLayoutGroup top="Цвет заголовка">
          <Field
            name="titleColor"
            component={ColorSelect}
            TextPreview={
              <TitlePreview color={values.titleColor} backgroundColor={backgroundColor}>
                {values.titleText}
              </TitlePreview>
            }
          />
        </FormLayoutGroup>

        <FormLayoutGroup top="Цвет подзаголовка">
          <Field
            name="subtitleColor"
            component={ColorSelect}
            TextPreview={
              <SubtitlePreview color={values.subtitleColor} backgroundColor={backgroundColor}>
                {values.subtitleText}
              </SubtitlePreview>
            }
          />
        </FormLayoutGroup>

        <Separator />

        <FormLayoutGroup top="Цвет кнопки">
          <Field name="buttonColor" component={ColorSelect} />
        </FormLayoutGroup>

        <FormLayoutGroup top="Цвет текста в кнопке">
          <Field name="buttonTextColor" component={ColorSelect} />
        </FormLayoutGroup>

        <Separator />

        <FormStatus className="gifts-description">
          Это изображение будет отображаться, если фото приза не загружено.
          <br />
          Фото призов можно загрузить в разделе «Призы»
        </FormStatus>

        <FormLayoutGroup className="gifts">
          <Field
            name="giftSrc"
            component={ImageSelect}
            variants={giftVariants}
            size={[120, 120]}
            title="Выберите коробку"
            setPreviewSrc={(src: string) => {
              dispatch(change('game', 'giftSrc', src));
            }}
          />
        </FormLayoutGroup>
      </FormLayout>
    </Wrap>
  );
};

const Wrap = styled.div`
  .select-background {
    margin: 0 12px;
  }

  .gifts {
    margin: 12px;
  }
`;

const TitlePreview = styled.div<{ color?: string; backgroundColor?: string }>`
  margin: 0 12px;
  font-size: 24px;
  font-weight: bold;
  color: ${(p) => p.color};
  background-color: ${(p) => p.backgroundColor};
`;

const SubtitlePreview = styled.div<{ color?: string; backgroundColor?: string }>`
  margin: 0 12px;
  font-size: 16px;
  line-height: 20px;
  color: ${(p) => p.color};
  background-color: ${(p) => p.backgroundColor};
`;

const withForm = reduxForm<any, any, any>({
  form: 'game',
})(Design);

const withStore = connect((state: RootState) => {
  const { mainSettingsId } = state.vk.gameSettings;

  return {
    initialValues: state.myGames.data.find(
      (game: any) => game.mainGameSettings.id === mainSettingsId
    ),
  };
}, {})(withForm);

export { withStore as Design };
