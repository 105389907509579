import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import bridge from '@vkontakte/vk-bridge';

import { targetsActions } from '../actions';
import { VkLimits, Target, Limits } from '../types';
import { Action } from 'typescript-fsa';
import { myGamesActions } from 'src/store/my-games/actions';
import { getStringParams } from 'src/vk-app/utils/query';
import {
  getTargetsVkFetcher,
  getTargetsFetcher,
  createTargetFetcher,
  updateTargetFetcher,
  deleteTargetFetcher,
  getVkLimits,
  getLimits,
} from './fetchers';

function* getTargets(action: ReturnType<typeof targetsActions.get.started>) {
  const fetcher = action.payload.isVk ? getTargetsVkFetcher : getTargetsFetcher;

  try {
    const res: AxiosResponse<{ targets: Target[] }> = yield call(fetcher, getStringParams());

    yield put(targetsActions.get.done({ params: {}, result: res.data }));
  } catch (err) {
    yield put(
      targetsActions.get.failed({ params: {}, error: { message: err.response.data.message } })
    );
  }
}

function* createTarget(action: Action<Partial<Target>>) {
  const target = action.payload;

  try {
    yield call(createTargetFetcher, target);

    yield put(targetsActions.create.done({ params: {}, result: null }));
    yield put(targetsActions.get.started({}));
  } catch (err) {
    yield put(
      targetsActions.create.failed({ params: {}, error: { message: err.response.data.message } })
    );
  }
}

function* updateTarget(action: Action<Partial<Target>>) {
  const target = action.payload;

  try {
    yield call(updateTargetFetcher, target);

    yield put(targetsActions.update.done({ params: {}, result: null }));
    yield put(targetsActions.get.started({}));
  } catch (err) {
    yield put(
      targetsActions.update.failed({ params: {}, error: { message: err.response.data.message } })
    );
  }
}

function* deleteTarget(action: Action<Partial<Target>>) {
  const target = action.payload;

  const vkParams = getStringParams();

  try {
    yield call(deleteTargetFetcher, { id: target.id, ...vkParams });

    yield put(targetsActions.delete.done({ params: {}, result: null }));

    if (vkParams.sign) {
      bridge.send('VKWebAppClose', { status: 'success' });
    } else {
      yield put(targetsActions.get.started({}));
      yield put(myGamesActions.get.started({}));
    }
  } catch (err) {
    yield put(
      targetsActions.delete.failed({ params: {}, error: { message: err.response.data.message } })
    );
  }
}

function* getVkLimitsSaga() {
  try {
    const response: AxiosResponse<VkLimits> = yield call(getVkLimits);

    yield put(targetsActions.getVkLimits.done({ params: {}, result: response.data }));
  } catch (err) {
    yield put(
      targetsActions.getVkLimits.failed({
        params: {},
        error: { message: err.response.data.message },
      })
    );
  }
}

function* getLimitsSaga() {
  try {
    const response: AxiosResponse<Limits> = yield call(getLimits);

    yield put(targetsActions.getLimits.done({ params: {}, result: response.data }));
  } catch (err) {
    yield put(
      targetsActions.getLimits.failed({
        params: {},
        error: { message: err.response.data.message },
      })
    );
  }
}

export function* watchTargets() {
  yield takeLatest(targetsActions.get.started, getTargets);
  yield takeLatest(targetsActions.create.started, createTarget);
  yield takeLatest(targetsActions.update.started, updateTarget);
  yield takeLatest(targetsActions.delete.started, deleteTarget);
  yield takeLatest(targetsActions.getVkLimits.started, getVkLimitsSaga);
  yield takeLatest(targetsActions.getLimits.started, getLimitsSaga);
}
