import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Button,
  Card,
  CardGrid,
  Header,
  InfoRow,
  Input,
  RichCell,
  SimpleCell,
  Spinner,
} from '@vkontakte/vkui';
import { useVirtual } from 'react-virtual';

import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { Panels, Views } from 'src/store/vk/types';
import { RootState } from 'src/core/rootReducer';
import { statisticsActions } from 'src/store/game-statistics/actions';
import { ApiStatus } from 'src/core/ApiStatus';
import { CollectedUserData } from 'src/store/game-statistics/types';
import { getVkQuery } from 'src/vk-app/utils/query';
import { pluralize } from '@alfalab/utils';
import { BaseGame } from 'src/games/common/types';

export const Statistics = () => {
  const dispatch = useDispatch();
  const { vk_group_id } = getVkQuery();

  const leadsContainerRef = useRef<HTMLDivElement>(null);

  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);
  const loading = useSelector<RootState, boolean>(
    (state) => state.statistics.status === ApiStatus.FETCHING
  );
  const events = useSelector((state: RootState) => state.statistics.data.events);
  const leads = useSelector<RootState, CollectedUserData[]>((state) => state.statistics.data.leads);
  const campaigns = useSelector<RootState, BaseGame[]>((state) => state.myGames.data);

  const campaign = campaigns.find(
    (c) => c.mainGameSettingsId === vkData.gameSettings.mainSettingsId
  );

  const [filteredLeads, setFilteredLeads] = useState<CollectedUserData[]>([]);
  const [search, setSearch] = useState('');

  const usersIdsBlob = useRef<Blob>();

  const isCompetition = campaign?.mainGameSettings.campaignType === 'competition';

  useEffect(() => {
    dispatch(
      statisticsActions.get.started({
        mainSettingId: vkData.gameSettings.mainSettingsId.toString(),
      })
    );

    return () => {
      dispatch(statisticsActions.reset());
    };
  }, [dispatch, vkData.gameSettings.mainSettingsId]);

  useEffect(() => {
    if (vkData.requestSave) {
      dispatch(vkActions.setPanel({ panel: Panels[Views.MY_GAMES]['GAME'] }));
    }
  }, [dispatch, vkData.requestSave]);

  useEffect(() => {
    setFilteredLeads(leads.filter((lead) => Boolean(lead.vkUserId)));
  }, [leads]);

  useEffect(() => {
    if (leads.length === 0) {
      return;
    }

    const uniqUserIds = Array.from(
      new Set(leads.map(({ vkUserId = '' }) => vkUserId).filter(Boolean))
    );

    const textContent = uniqUserIds.join('\r\n');

    usersIdsBlob.current = new Blob([textContent], {
      type: 'text/plain',
    });
  }, [leads]);

  const handleLeadSearchChange = useCallback(
    (event) => {
      const value = event.target.value.toLowerCase();

      const contains = (str?: string): boolean => {
        if (!str) {
          return false;
        }

        return str.toLowerCase().includes(value);
      };

      if (value.length >= 2) {
        const filtered = leads.filter(
          (lead) =>
            contains(lead.vkUserName) ||
            contains(lead.gameResult?.slotText) ||
            contains(lead.gameResult?.slotValue) ||
            contains(lead.createdAt?.toString())
        );

        setFilteredLeads(filtered);
      } else {
        setFilteredLeads(leads);
      }

      setSearch(event.target.value);
    },
    [leads]
  );

  const { closeAmount, looseAmount, winAmount, promocodes = {} } = events || {};

  const doneGames = looseAmount + winAmount;

  const conversion = (doneGames / (doneGames + closeAmount)) * 100;

  const rowVirtualizer = useVirtual({
    size: filteredLeads.length,
    parentRef: leadsContainerRef,
    estimateSize: useCallback(
      (index: number) => {
        if (isCompetition) {
          return 126;
        }

        return filteredLeads[index].score !== undefined ? 142 : 126;
      },
      [filteredLeads, isCompetition]
    ),
  });

  return (
    <Wrap>
      <CardGrid>
        {!isCompetition && (
          <Card size="l" className="eg-card" mode="outline">
            {loading && (
              <LoaderWrap>
                <Spinner />
              </LoaderWrap>
            )}

            <SimpleCell disabled={true}>
              <InfoRow header="Завершенные игры">{doneGames}</InfoRow>
            </SimpleCell>

            <SimpleCell disabled={true}>
              <InfoRow header="Выигрыши">{winAmount}</InfoRow>
            </SimpleCell>
            <SimpleCell disabled={true}>
              <InfoRow header="Проигрыши">{looseAmount}</InfoRow>
            </SimpleCell>
            <SimpleCell disabled={true}>
              <InfoRow header="Отказы">{closeAmount}</InfoRow>
            </SimpleCell>

            {!isNaN(conversion) && (
              <SimpleCell disabled={true}>
                <InfoRow header="Конверсия">{conversion.toFixed(2)} %</InfoRow>
              </SimpleCell>
            )}
          </Card>
        )}

        {!isCompetition && (
          <Card size="l" className="eg-card" mode="outline">
            {loading && (
              <LoaderWrap>
                <Spinner />
              </LoaderWrap>
            )}

            <Header mode="secondary">Разыгранные промокоды</Header>

            {Object.keys(promocodes).length === 0 && (
              <SimpleCell>
                <Placeholder>Пока нет разыгранных промокодов</Placeholder>
              </SimpleCell>
            )}

            {Object.keys(promocodes).map((promocode) => (
              <SimpleCell key={promocode}>
                <InfoRow header={promocode}>{promocodes[promocode]}</InfoRow>
              </SimpleCell>
            ))}
          </Card>
        )}

        {leads.length > 0 && usersIdsBlob.current && (
          <Card size="l" className="eg-card" mode="outline">
            <Placeholder>
              Здесь можно скачать id всех {isCompetition ? 'игроков' : 'победителей'}. Скачать можно
              только с помощью ПК или через мобильный браузер (не через приложение ВК).
              <br />
              <br />
              Этот файл можно загрузить, к примеру, в Senler, и добавить всех{' '}
              {isCompetition ? 'игроков' : 'победителей'} в рассылку.
            </Placeholder>

            <a
              href={window.URL.createObjectURL(usersIdsBlob.current)}
              download="users.txt"
              className="download-users-link"
            >
              <Button size="m">Скачать</Button>
            </a>
          </Card>
        )}

        <Card size="l" className="eg-card" mode="outline">
          {loading && (
            <LoaderWrap>
              <Spinner />
            </LoaderWrap>
          )}

          <Header mode="secondary">
            {isCompetition ? `Игроки (${filteredLeads.length})` : 'Победители'}
          </Header>

          <SearchWrapper>
            <Input value={search} onChange={handleLeadSearchChange} placeholder="Найти" />
          </SearchWrapper>

          <LeadsList ref={leadsContainerRef}>
            <div style={{ height: `${rowVirtualizer.totalSize}px`, position: 'relative' }}>
              {rowVirtualizer.virtualItems.map((virtualRow) => {
                const lead = filteredLeads[virtualRow.index];
                const meta = lead.meta;

                const metaInfo = meta
                  ? Object.keys(meta)
                      .reduce((acc, key) => {
                        acc += `${key}=${meta[key]} `;

                        return acc;
                      }, '')
                      .trim()
                  : null;

                return (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                      width: '100%',
                      backgroundColor:
                        virtualRow.index % 2 === 0
                          ? 'transparent'
                          : 'var(--content_tint_background)',
                    }}
                    key={virtualRow.index}
                  >
                    <RichCell
                      disabled
                      multiline
                      before={
                        isCompetition ? (
                          <Avatar>#{lead.place}</Avatar>
                        ) : (
                          <Avatar size={48} src={lead.vkUserImg} />
                        )
                      }
                      text={
                        <>
                          {lead.gameResult?.slotText && (
                            <Overflowed>{lead.gameResult?.slotText}</Overflowed>
                          )}
                          {lead.score !== undefined && (
                            <>
                              {isCompetition ? (
                                <Overflowed>
                                  набрал {lead.score}{' '}
                                  {pluralize(lead.score, 'очко', 'очка', 'очков')}
                                </Overflowed>
                              ) : (
                                <Score className="RichCell__caption">
                                  набрал {lead.score}{' '}
                                  {pluralize(lead.score, 'очко', 'очка', 'очков')}
                                </Score>
                              )}
                            </>
                          )}
                          {lead.gamesAmount !== undefined && (
                            <Score className="RichCell__caption">
                              сыграл {lead.gamesAmount}{' '}
                              {pluralize(lead.gamesAmount, 'раз', 'раза', 'раз')}
                            </Score>
                          )}
                        </>
                      }
                      caption={
                        <>
                          {lead.createdAt && <div>{lead.createdAt} (МСК)</div>}
                          {metaInfo && <LeadMetaInfo title={metaInfo}>{metaInfo}</LeadMetaInfo>}
                        </>
                      }
                      actions={
                        lead.vkUserId && (
                          <Button
                            href={`https://vk.com/gim${vk_group_id}?sel=${lead.vkUserId}`}
                            target="_blank"
                          >
                            Открыть диалог
                          </Button>
                        )
                      }
                    >
                      <Overflowed>{lead.vkUserName}</Overflowed>
                    </RichCell>
                  </div>
                );
              })}
            </div>

            {filteredLeads.length === 0 ? (
              <EmptyPlaceholder>
                {search
                  ? 'Ничего не найдено'
                  : `Пока нет ${isCompetition ? 'игроков' : 'победителей'}`}
              </EmptyPlaceholder>
            ) : null}
          </LeadsList>
        </Card>
      </CardGrid>
    </Wrap>
  );
};

const Wrap = styled.div`
  margin-top: 20px;

  .eg-card {
    margin-bottom: 15px;
  }

  .Card__in {
    flex-direction: column;
  }

  .download-users-link {
    display: flex;
    text-decoration: none;
    margin-top: 12px;
  }
`;

const Placeholder = styled.div`
  color: #818c99;
  color: var(--text_secondary);
  font-size: 14px;
`;

const LoaderWrap = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeadsList = styled.div`
  height: 420px;
  overflow: auto;
  margin-bottom: 16px;

  .Avatar__img_placeholder {
    background-color: transparent;
  }
`;

const Overflowed = styled.div`
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SearchWrapper = styled.div`
  padding: 12px;
`;

const EmptyPlaceholder = styled.div`
  opacity: 0.5;
  padding: 12px;
`;

const LeadMetaInfo = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
`;

const Score = styled.div`
  font-size: 11px;
  margin-bottom: 4px;
`;
