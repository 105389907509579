import styled from 'styled-components';
import { Avatar, Div, RichCell, Spinner, Text } from '@vkontakte/vkui';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { config } from 'src/config/config';

import { useApi } from 'src/core/hooks/useApi';
import { pluralize } from 'src/core/utils/pluralize';
import { getStringParams, getVkQuery } from 'src/vk-app/utils/query';

type RatingProps = {
  mainGameSettingsId: number;
};

type UserRating = {
  score: number;
  vkUserImg: string;
  vkUserName: string;
  vkUserId: string;
  place?: number;
};

type CompetitionData = {
  top: UserRating[];
  currentUser?: UserRating;
  totalPlayers: number;
};

export const Rating: FC<RatingProps> = ({ mainGameSettingsId }) => {
  const [loading, setLoading] = useApi();
  const [rating, setRating] = useState<CompetitionData>({ top: [], totalPlayers: 0 });
  const { vk_user_id } = getVkQuery();

  useEffect(() => {
    setLoading(true);

    axios
      .post(`${config.gameApi}/get-competition-data`, { ...getStringParams(), mainGameSettingsId })
      .then(({ data }) => {
        setRating(data);
      })
      .catch(() => {
        setRating({ top: [], totalPlayers: 0 });
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setLoading(true);
      setRating({ top: [], totalPlayers: 0 });
    };
  }, [mainGameSettingsId, setLoading]);

  const isCurrentUserInTop = rating.currentUser
    ? rating.top.some((topUser) => topUser.vkUserId === rating.currentUser?.vkUserId)
    : false;

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {rating.top.length === 0 && <div>Пока нет рейтинга</div>}

          {rating.totalPlayers ? (
            <Text weight="semibold" style={{ fontSize: '18px', marginBottom: '12px' }}>
              {rating.totalPlayers}{' '}
              {pluralize(rating.totalPlayers, 'участник', 'участника', 'участников')}
            </Text>
          ) : null}

          <Wrapper>
            {rating.top.map((lead, index) => {
              const cell = (
                <RichCell
                  key={`${lead.vkUserId}-${index}`}
                  disabled
                  multiline
                  before={<Avatar size={48} src={lead.vkUserImg} />}
                  text={
                    <div className="RichCell__caption">
                      {lead.score} {pluralize(lead.score, 'очко', 'очка', 'очков')}
                    </div>
                  }
                >
                  <div>
                    #{index + 1} {lead.vkUserName}
                  </div>
                </RichCell>
              );

              return vk_user_id === lead.vkUserId ? (
                <CurrentUserWrapper>{cell}</CurrentUserWrapper>
              ) : (
                cell
              );
            })}

            {rating.currentUser && !isCurrentUserInTop && (
              <>
                <Div style={{ display: 'flex', justifyContent: 'center' }}>. . .</Div>

                <CurrentUserWrapper>
                  <RichCell
                    disabled
                    multiline
                    before={<Avatar size={48} src={rating.currentUser.vkUserImg} />}
                    text={
                      <div className="RichCell__caption">
                        {rating.currentUser.score}{' '}
                        {pluralize(rating.currentUser.score, 'очко', 'очка', 'очков')}
                      </div>
                    }
                  >
                    <div>
                      #{rating.currentUser.place} {rating.currentUser.vkUserName}
                    </div>
                  </RichCell>
                </CurrentUserWrapper>
              </>
            )}
          </Wrapper>
        </>
      )}
    </div>
  );
};

const Wrapper = styled.div`
  margin: 0 -15px;
`;

const CurrentUserWrapper = styled.div`
  background-color: #f2f2f2;
`;
