import {
  Cell,
  FormLayout,
  FormLayoutGroup,
  FormStatus,
  ScreenSpinner,
  Separator,
} from '@vkontakte/vkui';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { RootState } from 'src/core/rootReducer';
import { BoxSettings } from 'src/games/common/types';
import { myGamesActions } from 'src/store/my-games/actions';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { Promocodes as PromocodesList, Switch } from 'src/vk-app/components';
import { usePrevious } from 'src/core/hooks/usePrevious';

const Promocodes = () => {
  const values = useSelector<RootState>((state) => state.form.game.values) as BoxSettings;
  const dispatch = useDispatch();
  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);

  const formSelector = formValueSelector('game');
  const prizes =
    useSelector<RootState, BoxSettings['prizes']['prizes']>((state) =>
      formSelector(state, 'prizes.prizes')
    ) || [];

  const [formErrorText, setFormErrorTest] = useState('');

  const getSumm = useCallback(() => {
    const chances = prizes.reduce((acc, prize) => {
      acc += +prize.chance;

      return acc;
    }, 0);

    return chances;
  }, [prizes]);

  const handleSubmit = useCallback(() => {
    const summ = getSumm();
    const isNan = isNaN(summ);

    if (summ <= 100 && !isNan) {
      dispatch(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));
      dispatch(myGamesActions.saveSettings.started({ gameId: values.gameId }));
    } else {
      setFormErrorTest(
        isNan ? 'Сейчас у вас ошибка в вероятностях' : `Сейчас у вас ${summ.toFixed(2)}%.`
      );
      window.scrollTo({ top: 0, behavior: 'smooth' });
      dispatch(vkActions.requestSaveForm({ save: false }));
    }
  }, [dispatch, getSumm, values.gameId]);

  const prevRequestSave = usePrevious(vkData.requestSave);

  useEffect(() => {
    if (vkData.requestSave && !prevRequestSave) {
      handleSubmit();
    }
  }, [vkData.requestSave, handleSubmit, prevRequestSave]);

  return (
    <Wrap>
      <FormLayout>
        {formErrorText && (
          <FormStatus header="Сумма вероятностей должна быть меньше или равна 100%" mode="error">
            {formErrorText}
          </FormStatus>
        )}

        {!formErrorText && (
          <FormStatus header="Добавьте призы">
            Призом может быть промокод или ссылка. Сумма вероятностей должна быть меньше или равна
            100%
          </FormStatus>
        )}

        <Cell asideContent={<Field name="prizesPreview" component={Switch} />}>
          Выводить список призов
          <br />
          на экран
        </Cell>

        <Separator />

        <FormLayoutGroup top="Призы">
          <FieldArray
            name="prizes.prizes"
            // @ts-ignore
            component={PromocodesList}
          />
        </FormLayoutGroup>
      </FormLayout>
    </Wrap>
  );
};

const Wrap = styled.div``;

const withForm = reduxForm<any, any, any>({
  form: 'game',
})(Promocodes);

const withStore = connect((state: RootState) => {
  const { mainSettingsId } = state.vk.gameSettings;

  return {
    initialValues: state.myGames.data.find(
      (game: any) => game.mainGameSettings.id === mainSettingsId
    ),
  };
}, {})(withForm);

export { withStore as Promocodes };
