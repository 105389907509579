import React, { FC } from 'react';
import cn from 'classnames';

import { GameProps, ChestSettings } from '../common/types';

import styles from './chest.module.css';

type ChestGameProps = GameProps & {
  settings: ChestSettings;
};

const getClassName = (imageUrl: string) => {
  if (imageUrl.includes('brown')) {
    return styles.chestBrown;
  }

  if (imageUrl.includes('red')) {
    return styles.chestRed;
  }

  if (imageUrl.includes('blue')) {
    return styles.chestBlue;
  }

  if (imageUrl.includes('gold')) {
    return styles.chestGold;
  }

  if (imageUrl.includes('green')) {
    return styles.chestGreen;
  }

  return '';
};

export const Chest: FC<ChestGameProps> = ({
  settings: { chests },
  startGame,
  endGame,
}) => {
  const onChestClick = async () => {
    const started = await startGame();

    if (started) {
      endGame();
    }
  };

  return (
    <div
      className={styles.wrapper}
    >
      <div className={cn(styles.row, styles.row0)}>
        <div className={cn(styles.chest, getClassName(chests[0]))} onClick={onChestClick} />
        <div className={cn(styles.chest, getClassName(chests[1]))} onClick={onChestClick} />
      </div>

      <div className={cn(styles.row, styles.row1)}>
        <div className={cn(styles.chest, getClassName(chests[2]))} onClick={onChestClick} />
      </div>

      <div className={cn(styles.row, styles.row2)}>
        <div className={cn(styles.chest, getClassName(chests[3]))} onClick={onChestClick} />
        <div className={cn(styles.chest, getClassName(chests[4]))} onClick={onChestClick} />
      </div>

      <div
        style={{ position: 'absolute', opacity: 0, width: '0', height: '0', pointerEvents: 'none' }}
      >
        <div
          style={{ backgroundImage: 'url(https://cdn2.embedgames.app/CHEST/chest_brown_1.png)' }}
        />
        <div
          style={{ backgroundImage: 'url(https://cdn2.embedgames.app/CHEST/chest_blue_1.png)' }}
        />
        <div
          style={{ backgroundImage: 'url(https://cdn2.embedgames.app/CHEST/chest_gold_1.png)' }}
        />
        <div
          style={{ backgroundImage: 'url(https://cdn2.embedgames.app/CHEST/chest_green_1.png)' }}
        />
        <div
          style={{ backgroundImage: 'url(https://cdn2.embedgames.app/CHEST/chest_red_1.png)' }}
        />
      </div>
    </div>
  );
};
