const MAX_SCORE = 100;
const MIN_SCORE = 1;

export const getScore = (prizesAmount: number, prizeIndex: number): [number, number] => {
  const step = MAX_SCORE / prizesAmount;

  const segments: [number, number][] = [];

  for (let i = 0; i < prizesAmount; i++) {
    segments.push([Math.ceil(i * step), Math.ceil((i + 1) * step)]);
  }

  const [min, max] = segments[prizeIndex];

  return [prizeIndex === 0 ? MIN_SCORE : min, prizeIndex === prizesAmount - 1 ? MAX_SCORE : max - 1];
};
