import React, { FC, useEffect } from 'react';
import copy from 'copy-to-clipboard';

import { SfCrossMIcon, CopyLineMIcon } from '@alfalab/icons-glyph';
import { MainGameSettings } from '../types';
import { GameResult } from '../types/index';

import styles from './result-screen.module.css';
import { Loader } from '@alfalab/core-components-loader';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { formatSubtitle } from '../utils';
import { Button } from 'src/components/Button';

type ContentProps = {
  style: CSSProperties;
  onCloseClick: () => void;
};

const Content: FC<ContentProps> = ({ style, onCloseClick, children }) => {
  return (
    <div className={styles.winWrap} style={style}>
      <SfCrossMIcon onClick={onCloseClick} className={styles.closeWinIcon} />
      {children}
    </div>
  );
};

type Props = {
  title: string;
  subtitle: string;
  visible: boolean;
  mainGameSettings: MainGameSettings;
  result?: GameResult;
  resultFetching: boolean;
  resultError: string;
  hasPromocode: boolean;
  onCloseResultClick: () => void;
  lockGame: () => void;
};

export const ResultScreen = ({
  title,
  subtitle,
  visible,
  mainGameSettings: { collectDataType, linkText, ...restMainGameSettings },
  result,
  resultFetching,
  resultError,
  hasPromocode,
  onCloseResultClick,
  lockGame,
}: Props) => {
  const [copyed, setCopyed] = React.useState(false);

  const onCopyClick = () => {
    copy(result ? result.slotValue || '' : '');
    setCopyed(true);

    setTimeout(() => {
      setCopyed(false);
    }, 1000);
  };

  const resultScreenTextColor = restMainGameSettings.resultScreenTextColor || '#fff';
  const resultScreenBgColor = restMainGameSettings.resultScreenBgColor || '#000';

  const style: CSSProperties = {
    color: resultScreenTextColor,
    backgroundColor: resultScreenBgColor,
    zIndex: visible ? 1000 : -1,
    opacity: visible ? 1 : 0,
    transform: visible ? 'scale(1)' : 'scale(0.7)',
  };

  useEffect(() => {
    if (visible && result) {
      lockGame();
    }
  }, [result, visible, lockGame]);

  if (!result || resultFetching) {
    /**
     * Состояние загрузки.
     * Показываем лоадер
     */
    return (
      <Content style={style} onCloseClick={onCloseResultClick}>
        <Loader />
      </Content>
    );
  }

  if (!result.win) {
    // Проигрыш
    return (
      <Content style={style} onCloseClick={onCloseResultClick}>
        <h4 className={styles.resultTitle} style={{ color: resultScreenTextColor }}>
          {title}
        </h4>
        <h5 className={styles.resultSubtitle} style={{ color: resultScreenTextColor }}>
          {subtitle}
        </h5>
      </Content>
    );
  }

  if (resultError) {
    return (
      <Content style={style} onCloseClick={onCloseResultClick}>
        <h5 className={styles.resultSubtitle} style={{ color: resultScreenTextColor }}>
          {resultError}
        </h5>
      </Content>
    );
  }

  return (
    <Content style={style} onCloseClick={onCloseResultClick}>
      <h4 className={styles.resultTitle} style={{ color: resultScreenTextColor }}>
        {title}
      </h4>

      <h5 className={styles.resultSubtitle} style={{ color: resultScreenTextColor }}>
        {result.slotType === 'LINK' ? (
          <>
            {result.slotDescription && (
              <>
                <span
                  dangerouslySetInnerHTML={{ __html: formatSubtitle(result.slotDescription) }}
                />
                <br />
                <br />
              </>
            )}

            <a
              href={result.slotValue}
              target="__blank"
              rel="noopener noreferer"
              className={styles.linkToPrize}
              style={{ color: resultScreenTextColor }}
            >
              {linkText}
            </a>
          </>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: formatSubtitle(subtitle) }} />
        )}
      </h5>

      {result.slotType !== 'LINK' && hasPromocode && (
        <Button
          onClick={onCopyClick}
          className={styles.copy}
          rightAddons={<CopyLineMIcon className={styles.copyIcon} />}
          size="s"
        >
          {copyed ? 'Промокод скопирован' : 'Скопировать промокод'}
        </Button>
      )}
    </Content>
  );
};
