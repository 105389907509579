import React from 'react';

import { Props } from './WheelSvg';

const Wheel = React.forwardRef<SVGSVGElement, Props>(
  ({ onAnimationEnd, kantColor, lampColor, className }, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      viewBox="0 0 1000 1000"
      className={className}
      onTransitionEnd={onAnimationEnd}
    >
      <path
        d="M908.452 725.341c38.427-69.271 57.376-144.238 58.557-218.487.086-5.459.075-10.914-.029-16.362-1.466-76.422-21.688-151.572-58.777-218.419a466.101 466.101 0 00-8.179-14.152c-38.278-63.625-92.282-118.971-160.298-159.622a484.568 484.568 0 00-6.23-3.667 475.166 475.166 0 00-7.803-4.395c-69.343-38.31-144.366-57.139-218.649-58.192a464.685 464.685 0 00-16.06.054c-76.453 1.548-151.619 21.859-218.456 59.042a463.553 463.553 0 00-13.728 7.945c-63.653 38.301-119.017 92.338-159.667 160.396-1.2 2.008-2.39 4.025-3.563 6.057a470.346 470.346 0 00-4.235 7.508C52.951 342.388 34.07 417.422 32.978 491.722c-.076 5.21-.061 10.416.038 15.616 1.438 76.473 21.658 151.678 58.766 218.57a466.576 466.576 0 007.81 13.542c38.2 63.725 92.159 119.179 160.151 159.936a468.548 468.548 0 006.76 3.987 468.37 468.37 0 006.833 3.86c69.289 38.49 144.288 57.486 218.575 58.705 5.313.088 10.623.082 15.927-.013 76.463-1.356 151.674-21.478 218.593-58.483a465.058 465.058 0 0013.971-8.042c63.717-38.166 119.168-92.076 159.94-160.006a477.46 477.46 0 004.089-6.929 503.221 503.221 0 004.021-7.124"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M719.607 118.687c210.074 121.268 282.05 389.848 160.764 599.891-121.286 210.043-389.905 282.009-599.979 160.74C70.318 758.05-1.658 489.47 119.628 279.427 240.914 69.384 509.534-2.581 719.607 118.687"
        id="slot04"
      />
      <path
        d="M554.237 593.723c16.095-9.194 30.058-22.61 40.057-39.762.076-.131.157-.259.233-.392 9.873-17.095 14.592-35.751 14.641-54.172a109.65 109.65 0 00-14.516-54.772c-9.264-16.156-22.785-30.157-40.077-40.139-17.292-9.982-36.18-14.691-54.805-14.634a109.696 109.696 0 00-54.7 14.817c-15.93 9.254-29.73 22.667-39.602 39.764-.076.131-.148.266-.223.398-9.857 17.233-14.496 36.031-14.411 54.567a109.663 109.663 0 0014.733 54.379c9.258 15.989 22.704 29.84 39.854 39.74 17.15 9.9 35.872 14.617 54.348 14.638a109.693 109.693 0 0054.468-14.432"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M733.709 110.031a7.882 7.882 0 00-2.886-10.768 7.883 7.883 0 102.886 10.768z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M740.519 98.778c6.596-5.833 12.804-11.704 14.382-14.437 3.697-6.403 1.503-14.591-4.901-18.288-6.405-3.697-14.593-1.503-18.291 4.9-1.578 2.733-3.559 11.044-5.313 19.673a463.735 463.735 0 017.103 4.008 479.659 479.659 0 016.229 3.666c.265.157.527.319.791.478"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M279.946 102.149a7.883 7.883 0 10-2.886 10.768 7.883 7.883 0 002.886-10.768z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M273.603 90.624c-1.754-8.627-3.735-16.938-5.313-19.67-3.697-6.404-11.887-8.598-18.291-4.901-6.404 3.697-8.599 11.885-4.901 18.288 1.578 2.733 7.786 8.604 14.383 14.437a459.165 459.165 0 017.023-4.146 485.413 485.413 0 016.29-3.561c.269-.15.54-.297.809-.447z"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M46.237 491.119a7.884 7.884 0 10.002 15.768 7.884 7.884 0 00-.002-15.768z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M33.084 490.849c-8.349-2.795-16.538-5.235-19.694-5.235-7.395 0-13.39 5.994-13.39 13.388s5.995 13.388 13.39 13.388c3.156 0 11.345-2.44 19.696-5.236a460.76 460.76 0 01-.08-8.154c0-2.413.025-4.821.061-7.227.004-.308.012-.616.017-.924"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M266.291 887.972a7.884 7.884 0 0013.655 7.884 7.883 7.883 0 00-2.885-10.769 7.887 7.887 0 00-10.77 2.885z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M259.48 899.227c-6.595 5.832-12.803 11.703-14.381 14.436-3.697 6.403-1.503 14.591 4.901 18.288 6.405 3.697 14.593 1.503 18.291-4.9 1.578-2.733 3.559-11.044 5.313-19.673a455.44 455.44 0 01-7.103-4.008 491.322 491.322 0 01-6.229-3.666c-.265-.158-.527-.319-.792-.477"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M720.053 895.855a7.884 7.884 0 0013.656-7.882 7.884 7.884 0 00-13.656 7.882z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M726.396 907.38c1.754 8.627 3.735 16.938 5.313 19.67 3.698 6.404 11.887 8.598 18.291 4.901 6.404-3.697 8.599-11.885 4.901-18.288-1.578-2.733-7.786-8.604-14.383-14.437a454.38 454.38 0 01-7.023 4.146 485.413 485.413 0 01-6.29 3.561c-.269.15-.54.297-.809.447"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M953.762 506.885a7.884 7.884 0 100-15.768 7.884 7.884 0 000 15.768z"
        fill={lampColor}
        className="lamp"
      />
      <path
        d="M966.916 507.155c8.349 2.795 16.538 5.235 19.694 5.235 7.395 0 13.39-5.994 13.39-13.388s-5.995-13.388-13.39-13.388c-3.156 0-11.345 2.44-19.696 5.236.048 2.713.08 5.43.08 8.154 0 2.413-.025 4.822-.061 7.227-.004.308-.013.616-.017.924"
        fill={kantColor}
        className="kant"
      />
      <path
        d="M719.607 118.687c-140.778-81.266-307.848-75.755-439.252-.064L445.414 404.47a109.705 109.705 0 0154.355-14.618c18.626-.057 37.514 4.652 54.807 14.635l165.031-285.8z"
        id="slot03"
        fill="#0101F6"
      />
      <path
        d="M939.288 499.002h-330.12v.395c-.049 18.421-4.768 37.077-14.641 54.172-.076.133-.158.26-.234.392-9.927 17.029-23.762 30.377-39.712 39.564l165.063 285.857c64.681-37.258 120.72-91.519 160.727-160.804 40.008-69.285 58.987-144.94 58.917-219.576z"
        id="slot01"
        fill="#0101F6"
      />
      <path
        d="M280.392 879.318l165.029-285.801c-17.15-9.9-30.597-23.751-39.854-39.74a109.654 109.654 0 01-14.734-54.38l-.001-.395H60.711c.141 151.628 78.902 299.05 219.681 380.316z"
        id="slot05"
        fill="#0101F6"
      />
      <path
        d="M594.652 444.625a109.64 109.64 0 0114.516 54.377h330.12c-.142-151.627-78.903-299.049-219.681-380.315L554.575 404.486c17.292 9.982 30.814 23.983 40.078 40.139"
        id="slot02"
      />
      <path
        d="M719.644 879.382L554.581 593.525l-.344.198a109.696 109.696 0 01-54.468 14.432c-18.477-.021-37.198-4.738-54.346-14.637l-165.031 285.8c140.778 81.267 307.849 75.755 439.252.064"
        id="slot00"
      />
      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(30 -496.575 354.504)"
        id="text_slot04"
      ></text>
      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(-30 1429.973 124.199)"
        id="text_slot05"
      ></text>
      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(90 213.333 277.59)"
        id="text_slot03"
      ></text>
      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(30 -766.56 1392.772)"
        id="text_slot01"
      ></text>
      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(90 -56.3 551.856)"
        id="text_slot00"
      ></text>
      <text
        style={{ whiteSpace: 'pre', fontSize: '30px' }}
        fontStyle="normal"
        fontWeight="700"
        transform="rotate(-30 1150.847 -897.22)"
        id="text_slot02"
      ></text>
    </svg>
  )
);

export default Wheel;
