import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  title?: ReactNode;
  content: ReactNode;
  mode?: string;
  className?: string;
};

export const FormStatus: FC<Props> = ({ title, content, mode, className }) => {
  return (
    <FormStatusWrap mode={mode} className={className}>
      {title && <FormStatusHeader>{title}</FormStatusHeader>}
      <FormStatusContent>{content}</FormStatusContent>
    </FormStatusWrap>
  );
};

const FormStatusWrap = styled.div<{ mode?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid;
  color: #0b1f35;
  width: 100%;
  min-height: 56px;
  padding: 16px;
  outline: none;
  background-color: #f3f4f5;
  border-color: #6d7986;

  ${(p) =>
    p.mode === 'error' &&
    `
      background-color: #feebea;
      border-color: #d91d0b;
    `};
`;

const FormStatusHeader = styled.div`
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
`;

const FormStatusContent = styled.div``;
