import { getRandomInteger } from '../common/utils/helpers';

export const getEnvSize = (level: number): [number, number, number] => {
  const base = 25;
  const showSpecial = getRandomInteger(0, 100) < 5 && level > 5;

  if (showSpecial) {
    return [base * 3, base, -base * 2];
  }

  const blocks: Array<[number, number, number]> = [
    [base, base, base * 2],
    [base, base * 2, base],
    [base * 2, base, base * 2],
    [base, base * 3, 0],
    [base * 3, base, base * 2],
    [base * 2, base * 2, base],
    [base * 3, base * 2, base],
    [base * 2, base * 3, 0],
    [base * 3, base * 3, 0],
  ];

  const range: [number, number] = level < 5 ? [0, 5] : [5, blocks.length - 1];

  const blockIndex = getRandomInteger(...range);

  return blocks[blockIndex];
};

export const CHARACTER_SIZE = [100, 100];
export const CLOUD_SIZE = [150, 150];
