import actionCreatorFactory from 'typescript-fsa';
import { ApiError } from 'src/core/ApiStatus';
import { Target, VkLimits, Limits } from '../types';

const factory = actionCreatorFactory('TARGETS');

export const targetsActions = {
  get: factory.async<{ isVk?: boolean }, { targets: Target[] }, ApiError>('GET'),
  create: factory.async<Partial<Target>, null, ApiError>('CREATE'),
  update: factory.async<Partial<Target>, null, ApiError>('UPDATE'),
  delete: factory.async<Partial<Target>, null, ApiError>('DELETE'),
  getVkLimits: factory.async<{}, VkLimits, ApiError>('GET_VK_LIMITS'),
  getLimits: factory.async<{}, Limits, ApiError>('GET_LIMITS'),
  resetLimits: factory<{}>('RESET_LIMITS'),
};
