import React, { FC } from 'react';
import styled from 'styled-components';
import { Radio } from '@vkontakte/vkui';
import { useDispatch } from 'react-redux';
import { Field, WrappedFieldProps, change } from 'redux-form';

type Props = {};

const ChooseStyle: FC<WrappedFieldProps> = ({ input, meta }) => {
  const dispatch = useDispatch();

  return (
    <RadioWrapper>
      <Radio
        name="view"
        value="classic"
        onChange={() => {
          input.onChange('classic');
          dispatch(change('game', 'titleColor', '#000'));
          dispatch(change('game', 'subtitleColor', '#7d7d7d'));
        }}
        checked={input.value === 'classic'}
      >
        С фоновым изображением
      </Radio>

      <Radio
        name="view"
        value="mono"
        onChange={() => {
          input.onChange('mono');
          dispatch(change('game', 'titleColor', '#fff'));
          dispatch(change('game', 'subtitleColor', '#969696'));
        }}
        checked={input.value === 'mono'}
      >
        Без фонового изображения
      </Radio>
    </RadioWrapper>
  );
};

export const GameStyleSelect: FC<Props> = () => {
  return (
    <Wrapper>
      <Field name="view" component={ChooseStyle} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const RadioWrapper = styled.div``;
