import React, { FC } from 'react';

import { GameWrap } from '../common/game-wrap';
import { GameWrapProps, WheelSettings } from '../common/types';
import { Wheel } from './Wheel';

import styles from './wheel.module.css';

type Props = Omit<GameWrapProps, 'GameContent'>;

const WheelGame: FC<Props> = (props) => {
  const {
    buttonText,
    buttonTextColor,
    buttonColor,
    backgroundColor,
    view,
  } = props.settings as WheelSettings;

  return (
    <GameWrap
      {...props}
      GameContent={Wheel}
      showResultScreenManual={true}
      startButton={{
        styles: {
          color: buttonTextColor,
          backgroundColor: buttonColor,
          marginTop: '25px',
        },
        text: buttonText,
      }}
      mainSectionClassname={styles.mainSection}
      style={{ backgroundColor: view === 'mono' ? backgroundColor : '' }}
    />
  );
};

export { WheelGame };

export default WheelGame;
