import Cookies from 'universal-cookie';

const cookies = new Cookies();
const ONE_YEAR = 365 * 24 * 60 * 60 * 1000;

class Storage {
  setItem(key: string, value: string) {
    try {
      localStorage.setItem(key, value);
    } catch {
      const expires = Date.now() + ONE_YEAR;

      cookies.set(key, value, {
        expires: new Date(expires),
      });
    }
  }

  getItem(key: string): string {
    let value = '';

    try {
      value = localStorage.getItem(key) || '';
    } catch {
      value = cookies.get(key);
    }

    return value;
  }

  removeItem(key: string) {
    try {
      localStorage.removeItem(key);
    } catch {
      cookies.remove(key);
    }
  }
}

export const storage = new Storage();
