export const drawImage = (
  context: CanvasRenderingContext2D,
  image: HTMLImageElement | null,
  coords: [number, number],
  size: [number, number]
) => {
  if (image) {
    context.drawImage(image, coords[0], coords[1], size[0], size[1]);
  }
};

export const drawBackground = (
  context: CanvasRenderingContext2D,
  image: HTMLImageElement | null,
  size: [number, number]
) => {
  if (image) {
    context.drawImage(image, 0, 0, size[0], size[1]);
  }
};

export const drawGround = (
  context: CanvasRenderingContext2D,
  image: HTMLImageElement | null,
  canvasSize: [number, number]
) => {
  if (image) {
    const [sliceWidth, sliceHeight] = [100, 100];

    const [width, height] = canvasSize;

    let i = 0;

    while (i <= width) {
      context.drawImage(image, i, height - sliceHeight / 2, sliceWidth, sliceHeight);
      i += sliceWidth;
    }
  }
};
