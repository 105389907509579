import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { IntlPhoneInput } from '../../../components/intl-phone-input';

import styles from './index.module.css';
import { BaseGame } from '../types';

type ControlsProps = {
  collectDataPlaceholder: string;
  gdprChecked: boolean;
  gdprError: boolean;
  phone: string;
  email: string;
  validationError: boolean;
  collectDataType: BaseGame['mainGameSettings']['collectDataType'];
  color: string;
  privacyPolicyUrl: string | null;
  error?: string;
  errorColor?: string;
  setGdprChecked: (checked: boolean) => void;
  setPhone: (phone: string) => void;
  setEmail: (email: string) => void;
};

export const Controls: FC<ControlsProps> = ({
  email,
  phone,
  validationError,
  gdprChecked,
  collectDataPlaceholder,
  gdprError,
  collectDataType,
  color,
  privacyPolicyUrl,
  error,
  errorColor,
  setPhone,
  setGdprChecked,
  setEmail,
}) => {
  const [needInputShake, setNeedInputShake] = useState(false);

  useEffect(() => {
    if (validationError) {
      setNeedInputShake(true);
    }
  }, [validationError]);

  return (
    <div className={styles.controlsWrap}>
      {collectDataType === 'EMAIL' ? (
        <input
          className={cn(styles.emailInput, {
            [styles.emailInputError]: validationError,
            [styles.shake]: needInputShake,
          })}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder={collectDataPlaceholder}
          name="email"
          onAnimationEnd={() => {
            setNeedInputShake(false);
          }}
        />
      ) : (
        <IntlPhoneInput
          value={phone}
          onChange={setPhone}
          size="s"
          className={cn(styles.phoneInput, {
            [styles.emailInputError]: validationError,
            [styles.shake]: needInputShake,
          })}
        />
      )}

      {error && (
        <div className={styles.error} style={{ color: errorColor }}>
          {error}
        </div>
      )}

      <div
        className={cn(styles.gdprCheckboxWrapper, {
          [styles.red]: gdprError,
        })}
      >
        <input
          type="checkbox"
          id="eg-gdpr"
          className={cn(styles.gdprCheckbox, { [styles.gdprCheckboxError]: gdprError })}
          checked={gdprChecked}
          onChange={() => setGdprChecked(!gdprChecked)}
        />
        <label htmlFor="eg-gdpr" className={styles.gdprCheckboxLabel} style={{ color }}>
          Я согласен с{' '}
          <a
            href={privacyPolicyUrl || 'https://embedgames.app/embed-games-policy'}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color, textDecoration: 'underline' }}
            ref={(node) => {
              if (node) {
                node.style.setProperty('color', color, 'important');
              }
            }}
          >
            политикой конфиденциальности
          </a>
          .
        </label>
      </div>
    </div>
  );
};
