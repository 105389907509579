export enum Tarif {
  FREE = 'FREE',
  THREE = 'THREE',
  UNLIM = 'UNLIM',
}

export enum VkTarif {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM',
}

export type PaymentInfo = {
  id: number;
  cardNumber: string;
};

export type AuthData = {
  id: number;
  apiToken: string;
  email: string;
  name: string;
  photo: string;
  emailConfirmed: boolean;
  tarif?: Tarif;
  lastPaymentDate?: string;
  paidUntil?: string;
  paymentId?: number;
  paymentInfo?: PaymentInfo;
  paymentsList: PaymentInfo[];
  subscriptionRenewProblem?: boolean;
  autopayment: boolean;
  notPayed: boolean | null;
  refId: string;
  referralPc: number;
  referralSum: number;
  refsCount: number;
  hasRefBonus?: boolean;
  vkId?: string;
};
