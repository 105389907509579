import React from 'react';
import { FormLayoutGroup } from '@vkontakte/vkui';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { RootState } from 'src/core/rootReducer';
import { LuckySettings } from 'src/games/common/types';

import { TextField } from 'src/vk-app/components';
import { Texts as CommonTexts } from '../common';

export const Texts = () => {
  const values = useSelector<RootState>((state) => state.form.game?.values) as LuckySettings;

  return (
    <CommonTexts
      additional={
        <FormLayoutGroup top={`Текст кнопки (${values?.buttonText?.length}/25)`}>
          <Field name="buttonText" component={TextField} maxLength={25} />
        </FormLayoutGroup>
      }
    />
  );
};
