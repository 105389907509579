import React, { FC } from 'react';
import Slider from 'react-slick';

import { GameWrap } from '../common/game-wrap';
import { GameWrapProps } from '../common/types';
import { Runner } from './runner';

type Props = Omit<GameWrapProps, 'GameContent'>;

const RunnerGame: FC<Props> = (props) => {
  return <GameWrap {...props} GameContent={Runner} Slider={Slider} />;
};

export { RunnerGame };

export default RunnerGame;
