import { call, spawn, takeLatest, put } from 'redux-saga/effects';
import bridge from '@vkontakte/vk-bridge';

import { vkActions } from './reducer';
import { getPublicCodesFetcher } from './fetchers';
import { AxiosResponse } from 'axios';
import { PublicCode } from './types';
import { getStringParams } from 'src/vk-app/utils/query';
import { Action } from 'typescript-fsa';

const getVars = () =>
  bridge.send('VKWebAppStorageGet', {
    keys: ['resultScreenNotification', 'showGameScreenNotification'],
  });

function* getStorageVars() {
  try {
    const data = yield call(getVars);

    const [{ value: resultScreenNotification }, { value: showGameScreenNotification }] = data.keys;

    yield put(vkActions.setStorageVars({ resultScreenNotification, showGameScreenNotification }));
  } catch (err) {
    //
  }
}

function* getPublicCodes(action: Action<{ mainGameSettingsId: number }>) {
  const { mainGameSettingsId } = action.payload;

  try {
    const result: AxiosResponse<{ codes: PublicCode[] }> = yield call(getPublicCodesFetcher, {
      mainGameSettingsId,
      ...getStringParams(),
    });

    yield put(
      vkActions.getPublicCodes.done({ params: { mainGameSettingsId }, result: result.data.codes })
    );
  } catch (err) {
    yield put(
      vkActions.getPublicCodes.failed({
        params: { mainGameSettingsId },
        error: { message: err.response?.data?.message },
      })
    );
  }
}

function* watchVkSagas() {
  yield takeLatest(vkActions.getStorageVars, getStorageVars);
  yield takeLatest(vkActions.getPublicCodes.started, getPublicCodes);
}

export function* vkSaga() {
  yield spawn(watchVkSagas);
}
