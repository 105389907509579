import React, { FC, ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

type Props = {
  uploadUrl: string;
  uploadButtonText?: string;
  name: string;
  setPreviewSrc: (src: string) => void;
  onInputClick?: () => void;
};

export const UploadButton: FC<Props> = ({
  uploadButtonText,
  uploadUrl,
  name,
  setPreviewSrc,
  onInputClick,
}) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && (event.target.files[0] as Blob);

    if (file) {
      if (file.size > 5000000) {
        setError('Размер файла должен быть меньше 5МБ');
        setLoading(false);
        return;
      }

      if (!['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(file.type)) {
        setError('Формат файла не поддерживается. Используйте jpeg, png или gif');
        setLoading(false);
        return;
      }

      const formData = new FormData();

      formData.append('file', file);

      setError('');
      setLoading(true);

      axios
        .post(uploadUrl, formData, { withCredentials: true })
        .then((res) => {
          const src = `${res.data.url}?${Math.random()}`;
          setPreviewSrc(src);

          setLoading(false);
        })
        .catch((err) => {
          let errorMessage: string;

          if (err.response) {
            errorMessage = err.response.data.message;
          } else {
            errorMessage =
              'Ошибка на сервере. Возможно, слишком большой размер файла. Максимальный размер файла - 5 МБ';
          }

          setLoading(false);

          setError(errorMessage);
        });
    }
  };

  return (
    <Wrapper>
      <InputWrapper>
        <label htmlFor={name}>{uploadButtonText}</label>
        <input
          type="file"
          accept="image/*"
          onChange={onChange}
          disabled={loading}
          name={name}
          onClick={onInputClick}
        />
      </InputWrapper>

      <FileSizeError>{error}</FileSizeError>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    opacity: 0.7;
  }
`;

const FileSizeError = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: red;
  min-height: 17px;
`;
