export enum ApiStatus {
  INITIAL = 'INITIAL',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INVALID = 'INVALID',
  FETCHING = 'FETCHING',
}

export type ApiError = {
  message: string;
};
