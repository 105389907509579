import React from 'react';

import { Props } from './WheelSvg';

const Wheel = React.forwardRef<SVGSVGElement, Props>(
  ({ onAnimationEnd, kantColor, lampColor, className }, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      viewBox="0 0 1000 1000"
      className={className}
      onTransitionEnd={onAnimationEnd}
    >
      <g>
        <path
          d="M835.944 824.374c55.049-56.975 92.758-124.494 113.118-195.918a465.036 465.036 0 004.208-15.814c18.366-74.207 18.287-152.041-.235-226.22a467.702 467.702 0 00-4.237-15.788c-20.503-71.373-58.341-138.818-113.517-195.694a463.932 463.932 0 00-5.068-5.154 477.664 477.664 0 00-6.399-6.267C766.75 108.562 699.157 70.956 627.678 50.712a467.286 467.286 0 00-15.526-4.105c-74.249-18.291-152.111-18.123-226.295.498a466.48 466.48 0 00-15.318 4.123c-71.398 20.527-138.863 58.4-195.745 113.628-1.679 1.63-3.35 3.27-5.01 4.93a472.234 472.234 0 00-6.034 6.156c-55.025 57.054-92.686 124.655-112.973 196.151a461.538 461.538 0 00-4.006 15.096c-18.407 74.251-18.344 152.137.185 226.363a464.4 464.4 0 004.038 15.104c20.403 71.45 58.168 138.988 113.293 195.959a474.98 474.98 0 005.498 5.601 475.124 475.124 0 005.601 5.498c56.964 55.117 124.49 92.88 195.931 113.284a462.04 462.04 0 0015.387 4.109c74.209 18.481 152.065 18.508 226.284.078a466.675 466.675 0 0015.577-4.153c71.424-20.38 138.941-58.109 195.907-113.181a483.403 483.403 0 005.744-5.635 507.86 507.86 0 005.728-5.842z"
          fill={kantColor}
          className="kant"
        />
        <path
          d="M527.869 605.545c17.927-4.716 34.887-14.064 48.985-28.045.107-.108.219-.21.327-.318 13.963-13.959 23.349-30.761 28.165-48.544a109.681 109.681 0 00.157-56.671c-4.766-18.006-14.203-35.031-28.322-49.15s-31.143-23.556-49.149-28.322a109.681 109.681 0 00-56.671.157c-17.783 4.817-34.585 14.203-48.546 28.164-.108.108-.212.219-.319.328-13.982 14.097-23.329 31.057-28.045 48.985a109.68 109.68 0 00.156 56.347c4.803 17.843 14.206 34.704 28.208 48.707 14.003 14.003 30.866 23.405 48.707 28.207a109.683 109.683 0 0056.348.156"
          fill={kantColor}
          className="kant"
        />
        <path
          d="M826.432 184.717a7.883 7.883 0 000-11.149 7.883 7.883 0 00-11.149 0 7.883 7.883 0 000 11.149 7.883 7.883 0 0011.149 0z"
          fill={lampColor}
          className="lamp"
        />
        <path
          d="M835.924 175.608c7.881-3.928 15.397-7.993 17.629-10.224 5.229-5.229 5.229-13.707 0-18.936-5.23-5.23-13.707-5.23-18.937 0-2.231 2.231-6.296 9.747-10.224 17.629a460.143 460.143 0 015.823 5.711 472.914 472.914 0 015.068 5.154c.215.22.426.445.641.666"
          className="kant"
          fill={kantColor}
        />
        <path
          d="M184.715 173.568a7.883 7.883 0 00-11.149 0 7.883 7.883 0 000 11.149 7.883 7.883 0 0011.149 0 7.883 7.883 0 000-11.149z"
          fill={lampColor}
          className="lamp"
        />
        <path
          d="M175.606 164.076c-3.928-7.881-7.993-15.397-10.224-17.628-5.229-5.229-13.707-5.229-18.936 0-5.229 5.229-5.23 13.706 0 18.936 2.231 2.231 9.747 6.296 17.629 10.224a460.143 460.143 0 015.711-5.823 472.914 472.914 0 015.154-5.068c.221-.215.445-.427.666-.641"
          className="kant"
          fill={kantColor}
        />
        <path
          d="M173.566 815.285a7.883 7.883 0 000 11.149 7.883 7.883 0 0011.149 0 7.883 7.883 0 000-11.149 7.883 7.883 0 00-11.149 0z"
          fill={lampColor}
          className="lamp"
        />
        <path
          d="M164.074 824.394c-7.881 3.928-15.397 7.993-17.628 10.224-5.229 5.229-5.229 13.707 0 18.936 5.229 5.23 13.707 5.23 18.936 0 2.231-2.231 6.296-9.747 10.224-17.629a460.143 460.143 0 01-5.823-5.711 472.914 472.914 0 01-5.068-5.154c-.215-.221-.426-.444-.641-.666"
          className="kant"
          fill={kantColor}
        />
        <path
          d="M815.283 826.434a7.883 7.883 0 0011.149 0 7.883 7.883 0 000-11.149 7.883 7.883 0 00-11.149 0 7.883 7.883 0 000 11.149z"
          fill={lampColor}
          className="lamp"
        />
        <path
          d="M824.392 835.926c3.928 7.881 7.993 15.397 10.224 17.628 5.229 5.229 13.707 5.229 18.937 0 5.229-5.229 5.229-13.706 0-18.936-2.232-2.231-9.748-6.296-17.63-10.224a466.974 466.974 0 01-5.71 5.823 482.177 482.177 0 01-5.155 5.068c-.22.215-.445.426-.666.641z"
          className="kant"
          fill={kantColor}
        />
        <path
          d="M810.571 189.429l-233.39 233.388c14.119 14.119 23.556 31.144 28.322 49.149a109.685 109.685 0 01-.157 56.672c-4.816 17.783-14.203 34.584-28.166 48.544l233.391 233.391c171.525-171.524 171.525-449.619 0-621.143"
          id="slot01"
        />
        <path
          d="M394.451 472.128c4.716-17.928 14.062-34.888 28.045-48.985.107-.108.21-.22.318-.327L189.427 189.429c-171.524 171.524-171.524 449.619 0 621.143l233.387-233.39c-14.003-14.003-23.406-30.864-28.209-48.707a109.694 109.694 0 01-.155-56.348"
          id="slot03"
        />
        <path
          d="M471.521 605.39c-17.841-4.803-34.704-14.205-48.705-28.206L189.427 810.573c171.525 171.524 449.62 171.524 621.144 0l-233.39-233.392c-.109.108-.22.211-.328.318-14.098 13.982-31.058 23.329-48.984 28.045a109.672 109.672 0 01-56.348-.155"
          id="slot00"
        />
        <path
          d="M422.814 422.816c13.961-13.961 30.764-23.348 48.546-28.164a109.681 109.681 0 0156.671-.157c18.006 4.765 35.031 14.203 49.151 28.323l233.389-233.389c-171.524-171.524-449.619-171.524-621.144 0l233.387 233.387z"
          id="slot02"
        />
        <text
          transform="translate(235 175.377)"
          id="text_slot02"
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          fontStyle="normal"
          fontWeight="700"
        ></text>
        <text
          transform="translate(235 789.377)"
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          id="text_slot00"
          fontStyle="normal"
          fontWeight="700"
        ></text>
        <text
          transform="rotate(-90 468.688 292.312)"
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          id="text_slot03"
          fontStyle="normal"
          fontWeight="700"
        ></text>
        <text
          id="text_slot01"
          transform="rotate(90 296.812 527.812)"
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          fontStyle="normal"
          fontWeight="700"
        ></text>
      </g>
    </svg>
  )
);

export default Wheel;
