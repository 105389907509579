export const nyBackgrounds = [
  'https://cdn2.embedgames.app/ny-bg/1.webp',
  'https://cdn2.embedgames.app/ny-bg/2.webp',
  'https://cdn2.embedgames.app/ny-bg/3.webp',
  'https://cdn2.embedgames.app/ny-bg/4.webp',
  'https://cdn2.embedgames.app/ny-bg/5.webp',
  'https://cdn2.embedgames.app/ny-bg/6.webp',
  'https://cdn2.embedgames.app/ny-bg/7.webp',
  'https://cdn2.embedgames.app/ny-bg/8.webp',
  'https://cdn2.embedgames.app/ny-bg/9.webp',
];

export const giftVariants = [
  'https://cdn2.embedgames.app/BOX/gift-1.png',
  'https://cdn2.embedgames.app/BOX/gift-2.png',
  'https://cdn2.embedgames.app/BOX/gift-3.png',
  'https://cdn2.embedgames.app/BOX/gift-4.png',
  'https://cdn2.embedgames.app/BOX/gift-5.png',
  'https://cdn2.embedgames.app/BOX/gift-6.png',
  'https://cdn2.embedgames.app/BOX/gift-7.png',
  'https://cdn2.embedgames.app/BOX/gift-8.png',
  //
  'https://static.embedgames.app/images/boxes/1.png',
  'https://static.embedgames.app/images/boxes/2.png',
  'https://static.embedgames.app/images/boxes/3.png',
  'https://static.embedgames.app/images/boxes/4.png',
  'https://static.embedgames.app/images/boxes/5.png',
  'https://static.embedgames.app/images/boxes/6.png',
  'https://static.embedgames.app/images/boxes/7.png',
  'https://static.embedgames.app/images/boxes/8.png',
  'https://static.embedgames.app/images/boxes/9.png',
  'https://static.embedgames.app/images/boxes/10.png',
  'https://static.embedgames.app/images/boxes/11.png',
  'https://static.embedgames.app/images/boxes/12.png',
  'https://static.embedgames.app/images/boxes/13.png',
  'https://static.embedgames.app/images/boxes/14.png',
  'https://static.embedgames.app/images/boxes/15.png',
  'https://static.embedgames.app/images/boxes/16.png',
  'https://static.embedgames.app/images/boxes/17.png',
  'https://static.embedgames.app/images/boxes/18.png',
  'https://static.embedgames.app/images/boxes/19.png',
  'https://static.embedgames.app/images/boxes/20.png',
  'https://static.embedgames.app/images/boxes/21.png',
  'https://static.embedgames.app/images/boxes/22.png',
  'https://static.embedgames.app/images/boxes/23.png',
  'https://static.embedgames.app/images/boxes/24.png',
  'https://static.embedgames.app/images/boxes/25.png',
  //
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_1.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_2.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_3.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_4.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_5.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_6.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_7.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_8.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_9.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_10.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_11.png',
  'https://cdn2.embedgames.app/BOX/bags/ny_bag_12.png',
  //
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_1.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_2.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_3.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_4.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_5.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_7.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_8.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_9.png',
  'https://cdn2.embedgames.app/BOX/balls/ny_ball_10.png',
  //
  'https://cdn2.embedgames.app/BOX/socks/ny_sock_1.png',
  'https://cdn2.embedgames.app/BOX/socks/ny_sock_2.png',
  'https://cdn2.embedgames.app/BOX/socks/ny_sock_3.png',
  'https://cdn2.embedgames.app/BOX/socks/ny_sock_4.png',
  'https://cdn2.embedgames.app/BOX/socks/ny_sock_5.png',
  'https://cdn2.embedgames.app/BOX/socks/ny_sock_6.png',
  'https://cdn2.embedgames.app/BOX/socks/ny_sock_7.png',
];
