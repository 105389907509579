import { spawn, take, cancel, fork } from 'redux-saga/effects';

import { SagaMiddleware } from 'redux-saga';
import { authSaga } from '../store/auth/saga';
import { Store } from 'redux';
import { IS_DEV } from './constants';
import { gamesSaga } from 'src/store/game/sagas';
import { myGamesSaga } from 'src/store/my-games/sagas';
import { targetsSaga } from 'src/store/targets/sagas';
import { statisticsSaga } from 'src/store/game-statistics/sagas';
import { vkSaga } from 'src/store/vk/sagas';

export function* rootSaga() {
  yield spawn(authSaga);
  yield spawn(gamesSaga);
  yield spawn(myGamesSaga);
  yield spawn(targetsSaga);
  yield spawn(statisticsSaga);
  yield spawn(vkSaga);
}

const CANCEL_SAGAS_HMR = 'CANCEL_SAGAS_HMR';

function createAbortableSaga(saga: any) {
  if (IS_DEV) {
    return function* main() {
      const sagaTask = yield fork(saga);
      yield take(CANCEL_SAGAS_HMR);
      yield cancel(sagaTask);
    };
  }
  return saga;
}

export default {
  startSaga<T extends object>(sagaMiddleware: SagaMiddleware<T>) {
    return sagaMiddleware.run(createAbortableSaga(rootSaga));
  },
  cancelSaga(store: Store) {
    store.dispatch({ type: CANCEL_SAGAS_HMR });
  },
};
