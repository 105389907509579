import React, { Component } from 'react';
import { ChestGame } from 'src/games';
import { GameId } from 'src/store/game/types';
import { GameResult } from 'src/games/common/types';
import { ChestSettings } from 'src/games/common/types';
import { config } from 'src/config/config';

type Props = {
  getResult: (gameId: GameId) => Promise<GameResult>;
};

const getResult = (gameId: GameId): Promise<GameResult> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        win: true,
        slotValue: 'MAC999',
        slotText: 'iPhone 11 Pro',
        slotType: 'PROMO_CODE',
      });
    }, 500);
  });
};

const mockSettings: ChestSettings = {
  id: 11,
  userId: 5,
  titleText: 'Испытайте удачу!',
  subtitleText: 'Выберите сундук!',
  backgroundImgSrc: 'https://cdn2.embedgames.app/CHEST/backgrounds/5/1-min.jpg',
  winTitle: 'Поздравляем! Ваш выигрыш: {prize}',
  winSubtitle: 'Держите промокод: {prize}',
  createdAt: '2020-07-08',
  failTitle: 'Удача сегодня не на вашей стороне :(',
  failSubtitle: '',
  updatedAt: '2020-07-08',
  gameId: GameId.CHEST,
  targetId: 120,
  titleColor: '#fff',
  subtitleColor: '#969696',
  view: 'mono',
  backgroundColor: '#262738',
  mainGameSettingsId: 95,
  chests: [
    'https://cdn2.embedgames.app/CHEST/chest_gold_0.png',
    'https://cdn2.embedgames.app/CHEST/chest_gold_0.png',
    'https://cdn2.embedgames.app/CHEST/chest_gold_0.png',
    'https://cdn2.embedgames.app/CHEST/chest_gold_0.png',
    'https://cdn2.embedgames.app/CHEST/chest_gold_0.png',
  ],
  // @ts-ignore
  mainGameSettings: {
    id: 95,
    gameId: GameId.CHEST,
    collectDataType: null,
    gameBreak: 24,
    userId: 5,
    targetId: 120,
    showDelay: 3,
    isPublish: false,
  },
  // @ts-ignore
  targetInfo: {
    vkClubID: config.vkClubId,
  },
  secondSectionBgColor: '#fff',
  prizesPreview: true,
  prizes: {
    prizes: [
      {
        value: 'IPHONE_SALE',
        text: 'Приз #1',
        type: 'PROMO_CODE',
        chance: 10,
      },
      {
        value: '123',
        text: 'Приз #2',
        type: 'PROMO_CODE',
        chance: 10,
      },
      {
        value: '23',
        type: 'PROMO_CODE',
        chance: 10,
        text: 'Приз #3',
      },
      {
        value: '123',
        text: 'Приз #4',
        type: 'PROMO_CODE',
        chance: 10,
      },
      {
        value: '123',
        text: 'Приз #5',
        type: 'PROMO_CODE',
        chance: 10,
      },
    ],
  },
};

export default class ChestDemo extends Component<Props> {
  render() {
    return (
      <ChestGame
        {...this.props}
        getResult={getResult}
        lockGame={() => {}}
        settings={mockSettings}
        isDemo={true}
        sendAnalytics={(params) =>
          new Promise((resolve, reject) => {
            console.log('send analytics ====>', params);
            resolve({});
          })
        }
      />
    );
  }
}
