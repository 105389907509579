import React, { FC, useCallback, useEffect, useRef } from 'react';

import WheelSvg from './components/WheelSvg';
import WheelSvg4 from './components/WheelSvg4';
import WheelSvg6 from './components/WheelSvg6';
import WheelSvg8 from './components/WheelSvg8';
import WheelSvg10 from './components/WheelSvg10';
import { WheelPointer } from './components/WheelPointer';
import { GameProps, WheelSettings } from '../common/types';

import styles from './wheel.module.css';

const MaxTextLength: { [key: number]: number } = {
  4: 40,
  6: 20,
  8: 20,
  10: 20,
  12: 20,
};

const WheelSvgComponent: { [key: number]: FC<any> } = {
  4: WheelSvg4,
  6: WheelSvg6,
  8: WheelSvg8,
  10: WheelSvg10,
  12: WheelSvg,
};

const THREE_TURNS = 1080;

type WheelGameProps = Omit<GameProps, 'settings'> & {
  settings: WheelSettings;
};

export const Wheel: FC<WheelGameProps> = ({
  settings: { lampColor, kantColor, pointerColor, slotsColors, slotsTextColor, prizes, slotsCount },
  started,
  showResultScreen,
  endGame,
}) => {
  const wheelSvg = useRef<SVGSVGElement>(null);
  const slotTexts = useRef<SVGTextElement[]>([]);
  const slots = useRef<SVGPathElement[]>([]);
  const reqId = useRef(0);
  const wheelCurrentAngle = useRef(0);
  const idleAnimation = useRef(true);

  const resetWheel = useCallback(() => {
    if (wheelSvg.current) {
      wheelSvg.current.style.transition = 'none';
      wheelSvg.current.style.transform = `rotate(0deg)`;
    }
  }, []);

  const onAnimationEnd = useCallback(() => {
    if (!idleAnimation.current) {
      showResultScreen();

      setTimeout(() => {
        resetWheel();
      }, 1000);
    }
  }, [resetWheel, showResultScreen]);

  const fillSlots = useCallback(() => {
    slotTexts.current.forEach((slot: SVGTextElement, index) => {
      const text = (prizes && prizes.prizes[index]?.text) || '';

      const prize = prizes && prizes.prizes[index];

      let pizeNotActive = Boolean(prize.inactive);

      const color = slotsColors[index];
      const textColor = slotsTextColor[index];

      let gap = '';
      const maxTextLength = MaxTextLength[slotsCount || 12];

      if (text.length < maxTextLength) {
        const diff = maxTextLength - text.length;
        gap = Array(diff).fill(' ').join('');
      }

      slot.textContent = `${gap}${text}`;

      if (slots.current && slots.current[index]) {
        slots.current[index].setAttribute('fill', color);
      }

      if (slotTexts.current && slotTexts.current[index]) {
        slotTexts.current[index].setAttribute(
          'fill',
          pizeNotActive ? 'rgba(0, 0, 0, 0)' : textColor
        );

        if (text.length > 15) {
          slotTexts.current[index].style.fontSize = '27px';
        }
      }
    });
  }, [prizes, slotsColors, slotsCount, slotsTextColor]);

  useEffect(() => {
    if (wheelSvg.current) {
      const texts = wheelSvg.current.querySelectorAll('text');
      const paths = wheelSvg.current.querySelectorAll('path');

      slotTexts.current = Array.from(texts).sort((a: SVGTextElement, b: SVGTextElement) => {
        const slotAIndex = Number(a.id.replace('text_slot', ''));
        const slotBIndex = Number(b.id.replace('text_slot', ''));

        return slotAIndex - slotBIndex;
      });

      slots.current = Array.from(paths)
        .filter((pathElem) => pathElem.id.includes('slot'))
        .sort((a: SVGPathElement, b: SVGPathElement) => {
          const slotAIndex = Number(a.id.replace('slot', ''));
          const slotBIndex = Number(b.id.replace('slot', ''));

          return slotAIndex - slotBIndex;
        });
    }

    fillSlots();
  }, [fillSlots]);

  useEffect(() => {
    if (started) {
      endGame().then((result) => {
        if (result && wheelSvg.current && result.slotNumber !== undefined) {
          idleAnimation.current = false;

          const ADDITIONAL = result.slotNumber * (360 / slotsCount);
          const targetAngle = THREE_TURNS + ADDITIONAL;

          wheelSvg.current.style.transform = `rotate(${targetAngle}deg) translateZ(0)`;
          wheelSvg.current.style.transition = 'transform 5s cubic-bezier(0.5, 0.2, 0.05, 0.95)';
        }
      });
    }
  }, [started, endGame, slotsCount]);

  useEffect(() => {
    const rotate = () => {
      if (wheelSvg.current && idleAnimation.current) {
        wheelCurrentAngle.current += 0.25;

        if (wheelCurrentAngle.current > 360) {
          wheelCurrentAngle.current = wheelCurrentAngle.current - 360;
        }

        wheelSvg.current.style.transform = `rotate(${wheelCurrentAngle.current}deg) translateZ(0)`;

        reqId.current = window.requestAnimationFrame(rotate);
      }
    };

    if (!reqId.current) {
      reqId.current = window.requestAnimationFrame(rotate);
    }

    return () => {
      window.cancelAnimationFrame(reqId.current);
    };
  }, []);

  const WheelComponent = WheelSvgComponent[slotsCount] || WheelSvg;

  return (
    <div className={styles.game}>
      <div className={styles.wheelContainer}>
        <WheelComponent
          ref={wheelSvg}
          onAnimationEnd={onAnimationEnd}
          lampColor={lampColor}
          kantColor={kantColor}
          className={styles.wheelSvg}
        />

        <WheelPointer className={styles.pointer} color={pointerColor} />
      </div>
    </div>
  );
};
