import React, { useEffect } from 'react';
import {
  Card,
  CardGrid,
  Headline,
  PanelHeader,
  PanelSpinner,
  Group,
  Text,
  Button,
} from '@vkontakte/vkui';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { allGamesActions } from 'src/store/game/actions';
import { RootState } from 'src/core/rootReducer';
import { AllGamesInitState } from 'src/store/game/reducers/allGamesReducer';
import { ApiStatus } from 'src/core/ApiStatus';

import './styles.css';
import { vkActions } from 'src/store/vk/reducer';
import { Panels, Views } from 'src/store/vk/types';

export const GamesPanel = () => {
  const dispatch = useDispatch();

  const gamesData = useSelector<RootState, AllGamesInitState>((state) => state.games.allGames);

  useEffect(() => {
    if (gamesData.status === ApiStatus.INITIAL || gamesData.status === ApiStatus.ERROR) {
      dispatch(allGamesActions.get.started({}));
    }
  }, [dispatch, gamesData.status]);

  return (
    <Wrap>
      <PanelHeader>Каталог игр</PanelHeader>

      <Group>
        <CardGrid>
          {gamesData.status === ApiStatus.SUCCESS ? (
            <>
              {gamesData.data.map(({ name, cover, shortDescription, gameId }) => (
                <Card
                  size="l"
                  className="eg-card"
                  onClick={() => {
                    dispatch(vkActions.setPanel({ panel: Panels[Views.GAMES]['GAME'] }));
                    dispatch(vkActions.setGameIdDemo({ gameId }));
                  }}
                  key={gameId}
                >
                  <GameCover src={cover} />

                  <GameDescription>
                    <Headline weight="semibold" style={{ marginBottom: 4 }}>
                      {name}
                    </Headline>

                    <Text
                      weight="regular"
                      style={{ marginBottom: 16 }}
                      className="eg-game-description"
                    >
                      {shortDescription}
                    </Text>

                    <Button size="m">Смотреть демо</Button>
                  </GameDescription>
                </Card>
              ))}
            </>
          ) : (
            <PanelSpinner />
          )}
        </CardGrid>
      </Group>
    </Wrap>
  );
};

const Wrap = styled.div`
  .eg-card {
    margin-bottom: 15px;
  }
`;

const GameCover = styled.div<{ src: string }>`
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-size: cover;
  background-position: center center;
  background-image: url(${(p) => p.src});
`;

const GameDescription = styled.div`
  margin-left: 20px;
`;
