import React from 'react';
import qs from 'querystring';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import VkPage from 'src/pages/Vk/Vk';
import {
  Button,
  Div,
  FormStatus,
  PanelHeader,
  PanelHeaderBack,
  Placeholder,
  Text,
} from '@vkontakte/vkui';
import { vkActions } from 'src/store/vk/reducer';
import { Views } from 'src/store/vk/types';
import { RootState } from 'src/core/rootReducer';
import { getMsid } from 'src/vk-app/utils/query';

const ErrorPlaceholder = ({ message = 'Мы уже работаем над ее исправлением' }) => (
  <Placeholder
    style={{
      zIndex: 100,
    }}
    icon={<Icon56ErrorOutline />}
    header="Ошибка"
    action={
      <Button
        onClick={() => {
          bridge.send('VKWebAppClose', { status: 'success' });
        }}
        size="l"
      >
        Закрыть
      </Button>
    }
  >
    {message}
  </Placeholder>
);

export const ShowGamePanel = () => {
  const dispatch = useDispatch();
  const msid = getMsid();

  const query = qs.parse(window.location.search.replace('?', ''));
  const { vk_viewer_group_role } = query;

  const showNotification = useSelector<RootState, boolean>(
    (state) => !state.vk.storageVars?.showGameScreenNotification
  );

  const isAdmin = vk_viewer_group_role === 'admin';

  return (
    <Wrapper>
      {isAdmin && (
        <PanelHeader
          left={
            <PanelHeaderBack
              onClick={() => {
                dispatch(vkActions.setView({ view: Views.MY_GAMES }));
              }}
            />
          }
          style={{
            position: 'relative',
          }}
        >
          Активная игра
        </PanelHeader>
      )}

      {isAdmin && showNotification && !msid && (
        <Div>
          <FormStatus>
            <Text weight="regular">
              При входе в приложение администраторы сообщества видят весь интерфейс приложения, а
              все остальные — только опубликованную игру.
            </Text>

            <Button
              onClick={() => {
                bridge
                  .send('VKWebAppStorageSet', {
                    key: 'showGameScreenNotification',
                    value: '1',
                  })
                  .then(() => {
                    dispatch(vkActions.getStorageVars({}));
                  });
              }}
              className="ok-button"
            >
              Понятно
            </Button>
          </FormStatus>
        </Div>
      )}

      <VkPage ErrorPlaceholder={ErrorPlaceholder} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ok-button {
    margin-top: 8px;
  }
`;
