export const loadTexture = (src: string, onLoad: (image: HTMLImageElement) => void) => {
  const image = new Image();
  image.src = src;
  image.onload = () => {
    onLoad(image);
  };
};

export const loadTextures = (srcSet: string[], onLoad: (image: HTMLImageElement[]) => void) => {
  const images: Array<{ src: string; Image?: HTMLImageElement }> = srcSet.map((src) => ({
    src,
  }));

  let counter = 0;

  srcSet.forEach((src) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      counter++;
      const index = images.findIndex((item) => item.src === src);
      images[index].Image = image;

      if (counter === srcSet.length) {
        const imgArray = images.map((item) => item.Image as HTMLImageElement);
        onLoad(imgArray);
      }
    };
  });
};

export function getRandomInteger(min: number, max: number) {
  // случайное число от min до (max+1)
  let rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
}

export type Rect = {
  y1: number;
  y2: number;
  x1: number;
  x2: number;
};

const pointInRect = (x: number, y: number, a: Rect) => {
  if (x >= a.x1 && x <= a.x2 && y >= a.y1 && y <= a.y2) {
    return true;
  }

  return false;
};

export const intersects = (a: Rect, b: Rect): boolean => {
  if (pointInRect(a.x1, a.y1, b)) {
    return true;
  }

  if (pointInRect(a.x2, a.y1, b)) {
    return true;
  }

  if (pointInRect(a.x2, a.y2, b)) {
    return true;
  }

  if (pointInRect(a.x1, a.y2, b)) {
    return true;
  }

  if (pointInRect(b.x1, b.y1, a)) {
    return true;
  }

  if (pointInRect(b.x2, b.y1, a)) {
    return true;
  }

  if (pointInRect(b.x2, b.y2, a)) {
    return true;
  }

  if (pointInRect(b.x1, b.y2, a)) {
    return true;
  }

  return false;
};

export const formatScore = (score: number): string => {
  return `00${score}`.slice(-3);
};
