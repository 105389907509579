import React from 'react';

import { storage } from 'src/core/storage';
import { SendAnalyticsParams } from '../../ep/types';
import { GameResult } from '../types/index';

const CLOSE_GAME_EVENT_LAST_SEND_KEY = 'CLOSE_GAME_EVENT_LAST_SEND_KEY';

const ONE_HOUR = 60 * 60 * 1000;

type Props = {
  sendAnalytics: (params: SendAnalyticsParams) => Promise<any>;
  mainGameSettingId: number;
  touched: boolean;
  result?: GameResult;
};

export class Analytics extends React.Component<Props> {
  sendAnalytics = (eventType: string, eventParams?: any) => {
    let userId;

    try {
      userId = storage.getItem('eg-user-id');
    } catch (err) {
      userId = '';
    }

    this.props.sendAnalytics({
      eventType,
      eventParams: {
        userId,
        ...(eventParams || {}),
      },
      mainSettingId: this.props.mainGameSettingId,
    });
  };

  componentWillUnmount() {
    try {
      const { touched } = this.props;

      const lastSendCloseGameEvent = Number(storage.getItem(CLOSE_GAME_EVENT_LAST_SEND_KEY));
      const now = Date.now();
      // Если не делать задержку в час, то если закрывать/открывать игру, сыпется много эвентов о закрытии
      // и конверсия в статистике сильно упадет
      const timePassed = now - lastSendCloseGameEvent > ONE_HOUR;

      if (!touched && timePassed) {
        this.sendAnalytics('close');

        storage.setItem(CLOSE_GAME_EVENT_LAST_SEND_KEY, Date.now().toString());
      }
    } catch (error) {
      //
    }
  }

  render() {
    return null;
  }
}
