import { pluralize } from './pluralize';

export const timeDifference = (date1: Date, date2: Date) => {
  let difference = date1.getTime() - date2.getTime();

  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  const minutesDifference = Math.floor(difference / 1000 / 60);

  if (daysDifference === 0 && hoursDifference === 0) {
    return `${minutesDifference}\xa0${pluralize(minutesDifference, 'минуту', 'минуты', 'минут')}`;
  }

  if (daysDifference === 0) {
    return `${hoursDifference}\xa0${pluralize(
      hoursDifference,
      'час',
      'часа',
      'часов'
    )}\xa0${minutesDifference}\xa0${pluralize(minutesDifference, 'минуту', 'минуты', 'минут')}`;
  }

  if (hoursDifference === 0) {
    return `${daysDifference}\xa0${pluralize(daysDifference, 'день', 'дня', 'дней')}`;
  }

  return `${daysDifference}\xa0${pluralize(
    daysDifference,
    'день',
    'дня',
    'дней'
  )}\xa0и\xa0${hoursDifference}\xa0${pluralize(hoursDifference, 'час', 'часа', 'часов')}`;
};
