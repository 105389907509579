import actionCreatorFactory from 'typescript-fsa';
import { ApiError } from 'src/core/ApiStatus';
import { GameId } from 'src/store/game/types';
import { BaseGame } from '../../../games/common/types';

const factory = actionCreatorFactory('MY_GAMES');

export const myGamesActions = {
  get: factory.async<{ redirectAfter?: string; isVk?: boolean }, BaseGame[], ApiError>('GET'),
  addGame: factory.async<
    { gameId: GameId; targetId: number | null; campaignName?: string },
    any,
    ApiError
  >('ADD_GAME'),
  saveSettings: factory.async<{ gameId: GameId }, any, ApiError>('SAVE_SETTINGS'),
  deleteGame: factory.async<
    { gameId?: GameId; targetId?: number; mainGameSettingsId?: number },
    any,
    ApiError
  >('DELTE_GAME'),
  publish: factory.async<{ targetId?: number; isPublish: boolean }, any, ApiError>('PUBLISH'),
  publishOnDirectLink: factory.async<{ isPublish: boolean }, any, ApiError>(
    'PUBLISH_ON_DIRECT_LINK'
  ),
  setPublishSuccessModal: factory<{ open: boolean }>('SET_PUBLISH_SUCESS_MODAL'),
  setAddedGameUrl: factory<{ url: string }>('SET_ADDED_GAME_URL'),
  setNeedShowPrompt: factory<boolean>('SET_NEED_SHOW_PROMPT'),
};
