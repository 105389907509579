import { IS_DEV, IS_TEST } from 'src/core/constants';

const SERVER_TEST_URL = 'https://api-test.embedgames.app';
const LOCAL_TEST_URL = 'http://localhost:3002';

const SERVER_GAME_TEST_URL = 'https://game-api-test.embedgames.app';
const LOCAL_GAME_TEST_URL = 'http://localhost:3004';

const API_TEST_URL = process.env.REACT_APP_LOCAL === 'true' ? LOCAL_TEST_URL : SERVER_TEST_URL;
const GAME_API_TEST_URL =
  process.env.REACT_APP_LOCAL === 'true' ? LOCAL_GAME_TEST_URL : SERVER_GAME_TEST_URL;

const getApiUrl = (): string => {
  if (IS_DEV) {
    return API_TEST_URL;
  }

  if (IS_TEST) {
    return 'https://api-test.embedgames.app';
  }

  if (window.origin === 'null') {
    return 'vkcors://api.embedgames.app';
  }

  return 'https://api.embedgames.app';
};

const getGameApiUrl = (): string => {
  if (IS_DEV) {
    return GAME_API_TEST_URL;
  }

  if (IS_TEST) {
    return 'https://game-api-test.embedgames.app';
  }

  if (window.origin === 'null') {
    return 'vkcors://game-api.embedgames.app';
  }

  return 'https://game-api.embedgames.app';
};

export const config = {
  api: getApiUrl(),
  gameApi: getGameApiUrl(),
  vkAppId: process.env.REACT_APP_TEST_APP ? 7800280 : 7685942,
  vkClubId: '197158919',
  vkPayMerchantId: 316763911,
  authVKAppId: 7709422,
  authGoogleClientId: '412429154414-s3t1mm7epkqqtigl38hc0us1bk8cqne5.apps.googleusercontent.com',
};
