import { ReactNode, FC } from 'react';
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { GameId } from '../game/types';
import { Views, SettingsForm, Panels, ModalName, PublicCode } from './types';
import { ApiError, ApiStatus } from 'src/core/ApiStatus';

const factory = actionCreatorFactory('VK');

type StorageVars = {
  resultScreenNotification: string;
  showGameScreenNotification: string;
};

export const vkActions = {
  setView: factory<{ view: Views; panel?: string; params?: any }>('SET_VIEW'),
  setPanel: factory<{ panel: string; params?: any }>('SET_PANEL'),
  setGameIdDemo: factory<{ gameId: GameId }>('SET_GAME_ID_DEMO'),
  setGameSettings: factory<{ gameId: GameId; mainSettingsId: number }>('SET_GAME_SETTINGS'),
  setGameSettingsForm: factory<{ form: SettingsForm }>('SET_GAME_SETTINGS_FORM'),
  setActiveModal: factory<{ modal: ModalName | null }>('SET_ACTIVE_MODAL'),
  setModalContent: factory<{ content: FC<any>; title: string; props?: any }>('SET_MODAL_CONTENT'),
  setPopoutContent: factory<{ popout: ReactNode }>('SET_POPOUT_CONTENT'),
  requestSaveForm: factory<{ save: boolean }>('REQUEST_SAVE_FORM'),
  setGlobalError: factory<{ title: string; description: string }>('SET_GLOBAL_ERROR'),
  getStorageVars: factory<{}>('GET_STORAGE_VARS'),
  setStorageVars: factory<StorageVars>('SET_STORAGE_VARS'),
  getPublicCodes: factory.async<{ mainGameSettingsId: number }, PublicCode[], ApiError>(
    'GET_PUBLIC_CODES'
  ),
};

export type PublicCodesState = {
  status: ApiStatus;
  data: {
    [mainGameSettingsId: number]: PublicCode[];
  };
  error?: string;
};

export type VKInitialState = {
  view: Views;
  panel: string;
  modal: ModalName | null;
  gameIdDemo: GameId;
  gameSettings: {
    gameId: GameId;
    mainSettingsId: number;
    form: SettingsForm;
  };
  ModalContent: FC<{}>;
  modalTitle: string;
  popout: ReactNode;
  requestSave: boolean;
  modalProps: any;
  globalError?: {
    title: string;
    description: string;
  };
  history: Array<{ view: Views; panel: string }>;
  panelParams?: any;
  storageVars?: StorageVars;
  publicCodes: PublicCodesState;
};

export const VK_INITIAL_STATE: VKInitialState = {
  view: Views.GAMES,
  panel: Panels[Views.GAMES]['MAIN'],
  modal: null,
  gameIdDemo: GameId.RUNNER,
  gameSettings: {
    gameId: GameId.RUNNER,
    mainSettingsId: 0,
    form: SettingsForm.DESIGN,
  },
  ModalContent: () => null,
  modalTitle: '',
  popout: null,
  requestSave: false,
  modalProps: {},
  history: [],
  publicCodes: {
    status: ApiStatus.INITIAL,
    data: {},
  },
};

export const vkReducer = reducerWithInitialState(VK_INITIAL_STATE)
  .case(vkActions.setView, (state, { view, panel = 'main', params }) => {
    const history = [...state.history];

    history.unshift({ view, panel });

    return {
      ...state,
      view,
      panel,
      history,
      panelParams: params,
    };
  })
  .case(vkActions.setPanel, (state, { panel, params }) => ({
    ...state,
    panel,
    panelParams: params,
  }))
  .case(vkActions.setGameIdDemo, (state, { gameId }) => ({
    ...state,
    gameIdDemo: gameId,
  }))
  .case(vkActions.setGameSettings, (state, { gameId, mainSettingsId }) => ({
    ...state,
    gameSettings: {
      ...state.gameSettings,
      gameId,
      mainSettingsId,
    },
  }))
  .case(vkActions.setGameSettingsForm, (state, { form }) => ({
    ...state,
    gameSettings: {
      ...state.gameSettings,
      form,
    },
  }))
  .case(vkActions.setActiveModal, (state, { modal }) => ({
    ...state,
    modal,
  }))
  .case(vkActions.setModalContent, (state, { content, title, props = {} }) => ({
    ...state,
    ModalContent: content,
    modalTitle: title,
    modalProps: props,
  }))
  .case(vkActions.setPopoutContent, (state, { popout }) => ({
    ...state,
    popout,
  }))
  .case(vkActions.requestSaveForm, (state, { save }) => ({ ...state, requestSave: save }))
  .case(vkActions.setGlobalError, (state, globalError) => ({ ...state, globalError }))
  .case(vkActions.setStorageVars, (state, storageVars) => ({ ...state, storageVars }))
  // public codes
  .case(vkActions.getPublicCodes.started, (state) => ({
    ...state,
    publicCodes: { ...state.publicCodes, status: ApiStatus.FETCHING },
  }))
  .case(vkActions.getPublicCodes.done, (state, payload) => ({
    ...state,
    publicCodes: {
      ...state.publicCodes,
      data: {
        ...state.publicCodes.data,
        [payload.params.mainGameSettingsId]: payload.result
      },
      status: ApiStatus.SUCCESS,
    },
  }))
  .case(vkActions.getPublicCodes.failed, (state, payload) => ({
    ...state,
    publicCodes: {
      ...state.publicCodes,
      error: payload.error.message,
      status: ApiStatus.ERROR,
    },
  }));
