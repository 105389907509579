import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import bridge from '@vkontakte/vk-bridge';
import { Loader } from '@alfalab/core-components-loader';
import axios from 'axios';
import qs from 'querystring';

import { Icon56HideOutline, Icon56UserAddOutline } from '@vkontakte/icons';

import { sendAnalytics, getVkResult } from 'src/games/ep/fetchers';
import { getGame } from 'src/games/ep/eg-browser-ep';
import { GameId } from 'src/store/game/types';
import { config } from 'src/config/config';
import { getMsid, getStringParams, getVkQuery } from 'src/vk-app/utils/query';
import { timeDifference } from 'src/core/utils/formatters';
import { Button, Cell, Placeholder } from '@vkontakte/vkui';
import { Icon28UserAddOutline } from '@vkontakte/icons';
import { VkTarif } from 'src/store/auth/types';
import { BaseGame, MainGameSettings } from 'src/games/common/types';
import { PublicCode } from './components';

type Props = {
  ErrorPlaceholder: FC<{ message?: string }>;
  isDemoPreview?: boolean;
};

let GameRef: any;

type GameSettings = BaseGame & {
  mainGameSettings: MainGameSettings;
};

const getActiveGame = async () => {
  const msid = getMsid();

  const { data } = await axios.post(`${config.gameApi}/vk/get-active-game`, {
    ...getStringParams(),
    msid: msid ?? undefined,
  });

  data.settings.mainGameSettings = data.mainSettings;

  return {
    ...data,
    gameId: data.settings.gameId,
    token: data.token,
    getResultToken: data.getResultToken,
  };
};

const Vk: FC<Props> = (props) => {
  const localStorageKeyRef = useRef('');
  const settingsRef = useRef<GameSettings>();
  const getResultRef = useRef<any>();
  const [gameError, setGameError] = useState('');
  const fetching = useRef(false);
  const [taskError, setTaskError] = useState('');
  const [onlyForDonsError, setOnlyForDonsError] = useState(false);

  const [gameLoaded, setGameLoaded] = useState(false);
  const [counterText, setCounterText] = useState('');
  const [joinButtonDisabled, setJoinButtonDisabled] = useState(false);
  const [joinedToGroup, setJoinedToGroup] = useState(false);

  const { ErrorPlaceholder, isDemoPreview } = props;

  const { vk_user_id: vkUserId } = qs.parse(window.location.search);

  const { vk_viewer_group_role, vk_group_id } = getVkQuery();

  const isAdmin = vk_viewer_group_role === 'admin';

  const accessToPremiumFeatures = settingsRef.current?.targetInfo?.vkTarif !== VkTarif.FREE;

  const onlyForVkSubscribers = Boolean(settingsRef.current?.mainGameSettings.onlyForVkSubscribers);
  const vkPublicCommunity = Boolean(settingsRef.current?.targetInfo?.vkPublicCommunity);
  const additionalAttemptForVkSubscription = Boolean(
    settingsRef.current?.mainGameSettings?.additionalAttemptForVkSubscription
  );

  const showJoinButton =
    additionalAttemptForVkSubscription &&
    ['admin', 'none'].includes(vk_viewer_group_role as string) &&
    vkPublicCommunity &&
    accessToPremiumFeatures &&
    !joinedToGroup;

  const needSubscribe =
    accessToPremiumFeatures &&
    onlyForVkSubscribers &&
    vk_viewer_group_role === 'none' &&
    !joinedToGroup;

  useEffect(() => {
    if (vkUserId && !fetching.current) {
      fetching.current = true;

      getActiveGame()
        .then(({ gameId, settings, lastGameTimestamp }) => {
          getGame(gameId as GameId).then(({ default: Game }) => {
            if (Game) {
              const mainGameSettingsId = settings.mainGameSettings.id;
              localStorageKeyRef.current = `eg-${settings.mainGameSettings.gameId}-${mainGameSettingsId}-last-game`;
              settingsRef.current = settings;

              const stringParams = getStringParams();

              getResultRef.current = getVkResult({
                mainGameSettingsId,
                isDemoPreview,
                ...stringParams,
              });
              GameRef = Game;

              const timestamp = +lastGameTimestamp;

              if (timestamp) {
                const breakMs =
                  (settingsRef.current?.mainGameSettings?.gameBreak || 0) * 60 * 60 * 1000;

                const now = Date.now();

                const diff = timestamp + breakMs - now;

                if (diff > 0) {
                  const text = `Повторить попытку можно через\xa0${timeDifference(
                    new Date(timestamp + breakMs),
                    new Date(now)
                  )}`;

                  setCounterText(text);
                }
              }

              setGameLoaded(true);
            }
          });
        })
        .catch((error) => {
          if (error.response?.data.code === 1111) {
            setGameError(error.response.data.message);
            return;
          }

          if (error.response?.data.code === 2222) {
            setOnlyForDonsError(true);
            return;
          }

          setGameError('Чтобы здесь появилась игра, ее нужно опубликовать');
        });
    }
  }, [vkUserId, accessToPremiumFeatures, isDemoPreview]);

  useEffect(() => {
    bridge.send('VKWebAppInit');
  }, []);

  const onGameOver = useCallback(() => {
    window.location.reload();
  }, []);

  const onJoinButtonClick = () => {
    if (isAdmin) {
      return;
    }

    const targetInfo = settingsRef.current?.targetInfo;
    const group_id = +(targetInfo?.vkClubID || '');

    setJoinButtonDisabled(true);

    bridge
      .send('VKWebAppJoinGroup', { group_id })
      .then(() => {
        setJoinedToGroup(true);

        axios
          .post(`${config.gameApi}/vk/check-join-group`, { ...getStringParams() })
          .then((response) => {
            setJoinButtonDisabled(false);

            if (!response.data.ok) {
              setTaskError(response.data.message || 'Ошибка при выполнении задания');
              return;
            }

            if (response.data.ok && !isAdmin) {
              setCounterText('');
            }
          })
          .catch(() => {
            setTaskError('Ошибка на сервере');
            setJoinButtonDisabled(false);
          });
      })
      .catch(() => {
        setJoinButtonDisabled(false);
      });
  };

  const disabledGame = Boolean(counterText);
  const targetInfo = settingsRef.current?.targetInfo;

  const titleColor = settingsRef.current?.titleColor || '';
  const subtitleColor = settingsRef.current?.subtitleColor || '';
  const backgroundColor =
    settingsRef.current?.view === 'mono'
      ? settingsRef.current?.backgroundColor || ''
      : settingsRef.current?.secondSectionBgColor || '#fff';

  const publicCodesEnabled =
    accessToPremiumFeatures && settingsRef.current?.mainGameSettings.usePublicCodes;

  const attemptsLeftComponent = (
    <AttemptsLeft>
      <AttemptsLeftTitle color={titleColor}>{counterText}</AttemptsLeftTitle>

      {showJoinButton && (
        <>
          <AttemptsLeftSubTitle color={subtitleColor}>
            Вы можете получить еще одну попытку, если выполните задание:
          </AttemptsLeftSubTitle>

          <TaskWrap color={titleColor}>
            <Cell
              disabled
              before={<Icon28UserAddOutline />}
              description={
                <TaskDescriptionWrap>
                  <TaskTitle color={subtitleColor}>
                    Вступить в сообщество «{targetInfo?.name}». <br />
                    Задание можно выполнить 1 раз.
                  </TaskTitle>
                  <Button onClick={onJoinButtonClick} disabled={joinButtonDisabled}>
                    Вступить
                  </Button>
                </TaskDescriptionWrap>
              }
              className="eg-simple-cell"
              multiline
            >
              <CellChild color={titleColor}>Вступить в сообщество</CellChild>
            </Cell>
          </TaskWrap>
        </>
      )}

      {publicCodesEnabled && settingsRef.current && (
        <PublicCode
          titleColor={titleColor}
          subtitleColor={subtitleColor}
          mainGameSettingsId={settingsRef.current?.mainGameSettings.id}
        />
      )}

      {taskError && !isAdmin && <Error>{taskError}</Error>}
    </AttemptsLeft>
  );

  if (gameError) {
    return (
      <Wrap color={titleColor} backgroundColor={backgroundColor}>
        <ErrorPlaceholder message={gameError} />
      </Wrap>
    );
  }

  if (needSubscribe) {
    return (
      <Wrap color={titleColor} backgroundColor={backgroundColor}>
        <Placeholder
          style={{
            zIndex: 100,
          }}
          icon={<Icon56UserAddOutline />}
          header="Подпишитесь на сообщество"
          action={
            <Button
              onClick={() => {
                setJoinButtonDisabled(true);

                const targetInfo = settingsRef.current?.targetInfo;
                const group_id = +(targetInfo?.vkClubID || '');

                bridge
                  .send('VKWebAppJoinGroup', { group_id })
                  .then(() => {
                    setJoinedToGroup(true);
                  })
                  .finally(() => {
                    setJoinButtonDisabled(false);
                  });
              }}
              size="l"
              disabled={joinButtonDisabled}
            >
              Подписаться
            </Button>
          }
        >
          Чтобы сыграть в игру, нужно подписаться на сообщество
        </Placeholder>
      </Wrap>
    );
  }

  if (onlyForDonsError) {
    return (
      <Wrap color={titleColor} backgroundColor={backgroundColor}>
        <Placeholder
          style={{
            zIndex: 100,
          }}
          icon={<Icon56HideOutline />}
          // header="Игра доступна только Донам сообщества"
          action={
            <Button size="l" href={`https://vk.com/donut/club${vk_group_id}`} target="_blank">
              Стать Доном
            </Button>
          }
        >
          Игра доступна только Донам сообщества
        </Placeholder>
      </Wrap>
    );
  }

  return (
    <Wrap color={titleColor} backgroundColor={backgroundColor}>
      {gameLoaded ? (
        <ContentWrap>
          <GameRef
            key={`${disabledGame}`}
            settings={settingsRef.current}
            getResult={getResultRef.current}
            bridge={bridge}
            spaceId={settingsRef.current?.targetInfo?.targetUrl}
            disabled={disabledGame}
            attemptsLeftComponent={attemptsLeftComponent}
            showJoinButton={showJoinButton}
            sendAnalytics={sendAnalytics}
            onGameOver={onGameOver}
          />
        </ContentWrap>
      ) : (
        <Loader className="eg-loader" />
      )}
    </Wrap>
  );
};

const Wrap = styled.div<{ color: string; backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: ${(p) => p.color};
  background-color: ${(p) => p.backgroundColor};
  min-height: 100vh;
  padding-top: env(safe-area-inset-top);

  .eg-loader {
    align-self: center;
  }

  @media screen and (min-width: 768px) {
    background-color: #38394e;
  }
`;

const ContentWrap = styled.div`
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
`;

const AttemptsLeft = styled.div``;

const AttemptsLeftTitle = styled.div<{ color: string }>`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 24px;
  color: ${(p) => p.color};
`;

const AttemptsLeftSubTitle = styled.div<{ color: string }>`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  color: ${(p) => p.color};
`;

const TaskWrap = styled.div<{ color: string }>`
  margin-bottom: 16px;

  .Cell__before {
    align-items: flex-start;
  }

  .Cell__before-in {
    color: ${(p) => p.color};
  }
`;

const TaskDescriptionWrap = styled.div``;

const TaskTitle = styled.div<{ color: string }>`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  color: ${(p) => p.color};
`;

const Error = styled.div`
  font-size: 12px;
  color: red;
  margin-top: 8px;
`;

const CellChild = styled.div<{ color: string }>`
  color: ${(p) => p.color};
`;

export default Vk;
