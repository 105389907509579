import qs from 'querystring';

type VkQuery = {
  vk_viewer_group_role: 'none' | 'member' | 'moder' | 'editor' | 'admin';
  vk_group_id: string;
  vk_user_id: string;
  sign: string;
  vk_app_id: string;
};

export const getVkQuery = (): VkQuery => {
  return (qs.parse(window.location.search.replace('?', '')) as unknown) as VkQuery;
};

export const getStringParams = () => {
  const query = qs.parse(window.location.search.replace('?', ''));

  const ordered: { [key: string]: string } = {};

  Object.keys(query)
    .sort()
    .forEach((key) => {
      if (key.slice(0, 3) === 'vk_') {
        ordered[key] = query[key] as string;
      }
    });

  return { stringParams: qs.stringify(ordered), sign: query['sign'] as string };
};

export const getMsid = (): string | undefined | null => {
  const hash = window.location.hash.replace(/^#/, '');
  let msid;

  if (hash) {
    const searchParams = new URLSearchParams(hash);

    msid = searchParams.get('msid');
  }

  return msid;
}
