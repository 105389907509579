import { call, put, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { Action } from 'typescript-fsa';

import { statisticsActions } from '../actions';
import { config } from 'src/config/config';
import { StatisticsEvent, CollectedUserData } from '../types';
import { getStringParams } from 'src/vk-app/utils/query';

const getStatisticsFetcher = (settingId: string, data: any) =>
  axios.post(`${config.api}/game/${settingId}/statistics`, data, { withCredentials: true });

function* getStatistics(action: Action<{ mainSettingId: string }>) {
  const {
    payload: { mainSettingId },
  } = action;

  const vkParams = getStringParams();

  try {
    const res: AxiosResponse<{
      events: StatisticsEvent;
      emails: CollectedUserData[];
      phones: CollectedUserData[];
      leads: CollectedUserData[];
    }> = yield call(getStatisticsFetcher, mainSettingId, vkParams);

    yield put(statisticsActions.get.done({ params: { mainSettingId }, result: res.data }));
  } catch (err) {
    yield put(
      statisticsActions.get.failed({
        params: { mainSettingId },
        error: { message: err.response.data.message },
      })
    );
  }
}

export function* watchStatistics() {
  yield takeLatest(statisticsActions.get.started, getStatistics);
}
