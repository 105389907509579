import { Action } from 'typescript-fsa';
import { spawn, call, put, takeLatest } from 'redux-saga/effects';

import { authActions } from './reducer';
import axios, { AxiosResponse } from 'axios';
import { config } from '../../config/config';
import { getStringParams } from 'src/vk-app/utils/query';
import { myGamesActions } from '../my-games/actions';
import { targetsActions } from '../targets/actions';

const fetcher = () => axios.get(`${config.api}/auth`, { withCredentials: true });
const logoutFetcher = () => axios.get(`${config.api}/logout`, { withCredentials: true });
const vkAuthDataFetcher = (data: any) =>
  axios.post(`${config.api}/auth-vk`, data, { withCredentials: true });

function* getAuthData(action: Action<any>) {
  try {
    const res: AxiosResponse = yield call(fetcher);

    yield put(authActions.getAuthData.done(res.data));
  } catch (err) {
    yield put(authActions.getAuthData.failed(err));
  }
}

function* getVkAuthData(action: Action<any>) {
  try {
    const { sign, stringParams } = getStringParams();

    const res: AxiosResponse = yield call(vkAuthDataFetcher, { sign, stringParams });

    yield put(authActions.getVkAuthData.done(res.data));
    yield put(myGamesActions.get.started({ isVk: true }));
    yield put(targetsActions.get.started({ isVk: true }));
  } catch (err) {
    yield put(
      authActions.getVkAuthData.failed({
        params: {},
        error: { message: err.response.data.code },
      })
    );
  }
}

function* watchGetAuthData() {
  yield takeLatest(authActions.getAuthData.started, getAuthData);
}

function* watchGetVkAuthData() {
  yield takeLatest(authActions.getVkAuthData.started, getVkAuthData);
}

function* logoutSaga() {
  try {
    yield call(logoutFetcher);

    window.location.href = '/';
  } catch (err) {
    yield put(authActions.logout.failed(err));
    // yield put(errorActions.showModal());
  }
}

function* watchLogout() {
  yield takeLatest(authActions.logout.started, logoutSaga);
}

export function* authSaga() {
  yield spawn(watchGetAuthData);
  yield spawn(watchGetVkAuthData);
  yield spawn(watchLogout);
}
