import React, { Component } from 'react';
import { BoxGame } from 'src/games';
import { GameId } from 'src/store/game/types';
import { GameResult } from 'src/games/common/types';
import { BoxSettings } from 'src/games/common/types';
import { config } from 'src/config/config';
// import { Modal } from 'src/components/Modal';

type Props = {
  getResult: (gameId: GameId) => Promise<GameResult>;
};

const getResult = (): Promise<GameResult> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        win: true,
        slotValue: 'MAC999',
        slotText: 'Приз #1',
        slotType: 'PROMO_CODE',
      });
    }, 500);
  });
};

const mockSettings: BoxSettings = {
  id: 11,
  userId: 5,
  titleText: 'Испытайте удачу!',
  subtitleText: 'Выберите коробку!',
  backgroundImgSrc: 'https://cdn2.embedgames.app/BOX/backgrounds/5/1-min.jpg',
  winTitle: 'Поздравляем! Ваш выигрыш: {prize}',
  winSubtitle: 'Держите промокод: {prize}',
  createdAt: '2020-07-08',
  failTitle: 'Удача сегодня не на вашей стороне :(',
  failSubtitle: '',
  updatedAt: '2020-07-08',
  gameId: GameId.BOX,
  targetId: 120,
  titleColor: '#fff',
  subtitleColor: '#969696',
  view: 'mono',
  backgroundColor: '#262738',
  mainGameSettingsId: 95,
  boxVariants: [
    'https://cdn2.embedgames.app/BOX/gift-1.png',
    'https://cdn2.embedgames.app/BOX/gift-2.png',
    'https://cdn2.embedgames.app/BOX/gift-3.png',
    'https://cdn2.embedgames.app/BOX/gift-4.png',
    'https://cdn2.embedgames.app/BOX/gift-5.png',
    'https://cdn2.embedgames.app/BOX/gift-6.png',
    'https://cdn2.embedgames.app/BOX/gift-7.png',
    'https://cdn2.embedgames.app/BOX/gift-8.png',
  ],
  boxes: [
    'https://cdn2.embedgames.app/BOX/gift-1.png',
    'https://cdn2.embedgames.app/BOX/gift-2.png',
    'https://cdn2.embedgames.app/BOX/gift-3.png',
    'https://cdn2.embedgames.app/BOX/gift-4.png',
    'https://cdn2.embedgames.app/BOX/gift-5.png',
    'https://cdn2.embedgames.app/BOX/gift-6.png',
  ],
  // @ts-ignore
  mainGameSettings: {
    id: 95,
    gameId: GameId.BOX,
    // collectDataType: 'PHONE',
    gameBreak: 24,
    userId: 5,
    targetId: 120,
    showDelay: 3,
    isPublish: false,
  },
  // @ts-ignore
  targetInfo: {
    vkClubID: config.vkClubId,
  },
  secondSectionBgColor: '#fff',
  prizesPreview: true,
  prizes: {
    prizes: [
      {
        value: 'IPHONE_SALE',
        text: 'Приз #1',
        type: 'PROMO_CODE',
        chance: 10,
      },
      {
        value: '123',
        text: 'Приз #2',
        type: 'PROMO_CODE',
        chance: 10,
      },
      {
        value: '23',
        type: 'PROMO_CODE',
        chance: 10,
        text: 'Приз #3',
      },
      {
        value: '123',
        text: 'Приз #4',
        type: 'PROMO_CODE',
        chance: 10,
      },
      {
        value: '123',
        text: 'Приз #5',
        type: 'PROMO_CODE',
        chance: 10,
      },
    ],
  },
};

export default class WheelDemo extends Component<Props> {
  render() {
    return (
      <BoxGame
        {...this.props}
        getResult={getResult}
        lockGame={() => {}}
        settings={mockSettings}
        sendAnalytics={(params) =>
          new Promise((resolve, reject) => {
            console.log('send analytics ====>', params);
            resolve({});
          })
        }
        isDemo={true}
      />
    );
  }
}
