import * as Sentry from '@sentry/react';
import { MainGameSettings } from 'src/games/common/types';
import { GameId } from 'src/store/game/types';

import { SendAnalyticsParams } from './types';
import { getUserData, sendAnalytics } from './fetchers';
import { GameManager } from './GameManager';
import { saveEnv } from './save-env';
import { storage } from 'src/core/storage';

Sentry.init({
  dsn: 'https://01cd28d6da9fdf01f24e8362c28a43a9@o4505788935897088.ingest.sentry.io/4506173533192192',
  beforeSend: (event: any) => {
    if (event.location && ~event.location.indexOf('build_games_prod')) {
      return event;
    }

    return null;
  },
});

export type ComponentProps = {
  settings: {
    [key: string]: any;
    mainGameSettings: MainGameSettings;
  };
  token: string;
  gameId: GameId;
  getResultToken: string;
  sendAnalytics: (params: SendAnalyticsParams) => Promise<any>;
};

export const getGame = (gameId: GameId): Promise<any> => {
  switch (gameId) {
    case GameId.WHEEL:
      return import(/* webpackChunkName: "wheel-game" */ '../wheel');
    case GameId.BOX:
      return import(/* webpackChunkName: "box-game" */ '../box');
    case GameId.CHEST:
      return import(/* webpackChunkName: "chest-game" */ '../chest');
    case GameId.RUNNER:
      return import(/* webpackChunkName: "runner-game" */ '../runner');
    case GameId.NINJA:
      return import(/* webpackChunkName: "ninja-game" */ '../ninja');
    case GameId.LUCKY:
      return import(/* webpackChunkName: "lucky-game" */ '../lucky');
    default:
      return Promise.resolve({ default: null });
  }
};

export class EmbedGames {
  GameManager?: GameManager;

  init({ token }: { token: string }) {
    // @ts-ignore
    if (window['embed_games_init']) {
      return;
    }

    // @ts-ignore
    window['embed_games_init'] = true;

    getUserData({ token })
      .then(({ gameId, settings, getResultToken = '' }) => {
        getGame(gameId as GameId).then(({ default: Game }) => {
          if (Game) {
            this.GameManager = new GameManager(Game, {
              settings,
              token,
              gameId: gameId as GameId,
              getResultToken,
              sendAnalytics,
            });

            saveEnv(window, storage);
          }
        });
      })
      .catch(() => {
        //
      });
  }

  render() {
    this.GameManager?.render();
  }
}

// @ts-ignore
window['embed_games'] = new EmbedGames();
