import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Div, FormStatus, Text } from '@vkontakte/vkui';
import bridge from '@vkontakte/vk-bridge';

import { RootState } from 'src/core/rootReducer';
import { vkActions } from 'src/store/vk/reducer';

type Props = {
  Game: ReactNode;
};

export const Result: FC<Props> = ({ Game }) => {
  const showNotification = useSelector<RootState, boolean>(
    (state) => !state.vk.storageVars?.resultScreenNotification
  );

  const dispatch = useDispatch();

  return (
    <Wrap>
      {showNotification && (
        <Div>
          <FormStatus>
            <Text weight="regular">
              Так будет выглядеть ваша игра. Не забудьте ее опубликовать, чтобы она стала доступна
              для всех.
            </Text>

            <Button
              onClick={() => {
                bridge
                  .send('VKWebAppStorageSet', {
                    key: 'resultScreenNotification',
                    value: '1',
                  })
                  .then(() => {
                    dispatch(vkActions.getStorageVars({}));
                  });
              }}
              className="ok-button"
            >
              Понятно
            </Button>
          </FormStatus>
        </Div>
      )}

      {Game}
    </Wrap>
  );
};

const Wrap = styled.div`
  .ok-button {
    margin-top: 8px;
  }
`;
