import axios from 'axios';
import { config } from 'src/config/config';
import { getStringParams } from 'src/vk-app/utils/query';

export const getTargetsFetcher = () =>
  axios.get(`${config.api}/targets`, { withCredentials: true });

export const createTargetFetcher = (data: any) =>
  axios.post(`${config.api}/target`, data, { withCredentials: true });

export const updateTargetFetcher = (data: any) =>
  axios.put(`${config.api}/targets/${data.id}`, data, { withCredentials: true });

export const deleteTargetFetcher = (data: any) =>
  axios.post(`${config.api}/delete-target/${data.id}`, data, { withCredentials: true });

export const getTargetsVkFetcher = (data: any) =>
  axios.post(`${config.api}/targets`, data, { withCredentials: true });

export const getVkLimits = () => {
  const vkParams = getStringParams();

  return axios.post(`${config.api}/get-vk-limits`, vkParams, { withCredentials: true });
};

export const getLimits = () => {
  return axios.get(`${config.api}/get-limits`, { withCredentials: true });
};
