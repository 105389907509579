import { combineReducers, Reducer } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter, RouterState } from 'connected-react-router';

import { authReducer, AUTH_INITIAL_STATE } from '../store/auth/reducer';
import { gamesReducer, GAMES_INITIL_STATE } from '../store/game/reducers';
import { myGamesReducer, MY_GAMES_INITIAL_STATE } from 'src/store/my-games/reducers';
import { targetsReducer, TARGETS_INITIAL_STATE } from 'src/store/targets/reducers';
import { statisticsReducer, STATISTICS_INITIAL_STATE } from 'src/store/game-statistics/reducers';
import { vkReducer, VK_INITIAL_STATE } from 'src/store/vk/reducer';

const rootObjReducer = {
  auth: authReducer,
  form: formReducer,
  games: gamesReducer,
  myGames: myGamesReducer,
  targets: targetsReducer,
  statistics: statisticsReducer,
  vk: vkReducer,
};

type RootType = typeof rootObjReducer & { router: RouterState };
type UnboxReducer<T> = T extends Reducer<infer U> ? U : T;

export type RootState = {
  readonly [P in keyof RootType]: UnboxReducer<RootType[P]>;
};

export const ROOT_INITIAL_STATE: Partial<RootState> = {
  auth: AUTH_INITIAL_STATE,
  form: {},
  games: GAMES_INITIL_STATE,
  myGames: MY_GAMES_INITIAL_STATE,
  targets: TARGETS_INITIAL_STATE,
  statistics: STATISTICS_INITIAL_STATE,
  vk: VK_INITIAL_STATE,
};

export default (history: any) =>
  combineReducers<RootState>({ ...rootObjReducer, router: connectRouter(history) });
