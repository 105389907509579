import React from 'react';

export const WheelPointer = ({ className, color }: { className?: string; color: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" className={className}>
    <defs />
    <g fillRule="evenodd">
      <path
        fill={color}
        d="M26.109 40.17c0 28.913 14.693 55.994 37.945 74.074 23.271-18.08 37.951-45.161 37.951-74.074 0-7.21-.91-12.111-2.654-15.676C95.145 11.258 80.967 1.5 64.055 1.5c-16.889 0-31.08 9.758-35.283 22.994-1.747 3.565-2.663 8.466-2.663 15.676z"
      />
    </g>
  </svg>
);
