import React, { FC, useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormLayout, FormLayoutGroup, ScreenSpinner, Separator } from '@vkontakte/vkui';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { RootState } from 'src/core/rootReducer';
import { RunnerSettings } from 'src/games/common/types';
import { ColorSelect, ImageSelect, IntegerInput, SelectCharacter } from 'src/vk-app/components';
import { myGamesActions } from 'src/store/my-games/actions';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { AllGamesInitState } from 'src/store/game/reducers/allGamesReducer';
import { GameId } from 'src/store/game/types';
import { usePrevious } from 'src/core/hooks/usePrevious';
import { loadTextures } from 'src/games/common/utils/helpers';

type Props = {
  initialValues: RunnerSettings;
};

const Design: FC<Props> = () => {
  const values = useSelector<RootState>((state) => state.form.game.values) as RunnerSettings;
  const dispatch = useDispatch();
  const formSelector = formValueSelector('game');
  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);

  const handleSubmit = useCallback(() => {
    dispatch(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));
    dispatch(myGamesActions.saveSettings.started({ gameId: values.gameId }));
  }, [dispatch, values.gameId]);

  const games = useSelector<RootState, AllGamesInitState>((state) => state.games.allGames);

  const gameInfo = games.data.find((game) => game.gameId === GameId.RUNNER);

  const groundVariants = useSelector((state: RootState) => formSelector(state, 'groundVariants'));

  const characterVariants = useSelector((state: RootState) =>
    formSelector(state, 'characterVariants')
  );

  const prevRequestSave = usePrevious(vkData.requestSave);

  useEffect(() => {
    if (vkData.requestSave && !prevRequestSave) {
      handleSubmit();
    }
  }, [vkData.requestSave, handleSubmit, prevRequestSave]);

  useEffect(() => {
    const imgs = [];

    for (let i = 1; i <= 6; i++) {
      for (let j = 0; j < 5; j++) {
        imgs.push(`https://cdn2.embedgames.app/NINJA/assets/ch${i}_run_${j}.png`);
      }
    }

    loadTextures(imgs, () => {});
  }, []);

  return (
    <Wrap>
      <FormLayout>
        <FormLayoutGroup top="Кол-во попыток">
          <Field name="attempts" component={IntegerInput} maxValue={10000} />
        </FormLayoutGroup>

        <Separator />

        <FormLayoutGroup top="Цвет заголовка">
          <Field
            name="titleColor"
            component={ColorSelect}
            TextPreview={<TitlePreview color={values.titleColor}>{values.titleText}</TitlePreview>}
          />
        </FormLayoutGroup>

        <Separator />

        <FormLayoutGroup top="Цвет подзаголовка">
          <Field
            name="subtitleColor"
            component={ColorSelect}
            TextPreview={
              <SubtitlePreview color={values.subtitleColor}>{values.subtitleText}</SubtitlePreview>
            }
          />
        </FormLayoutGroup>

        <Separator />

        <FormLayoutGroup top="Фон">
          <Field
            name="backgroundImgSrc"
            component={ImageSelect}
            variants={gameInfo?.backgroundVariants}
            title="Выберите фон"
            className="select-background"
            size={[200, 200]}
          />
        </FormLayoutGroup>

        <Separator />

        <FormLayoutGroup top="Земля">
          <Field
            name="groundImgSrc"
            component={ImageSelect}
            variants={groundVariants}
            title="Выберите землю"
            className="select-background"
            size={[200, 200]}
          />
        </FormLayoutGroup>

        <Separator />

        <FormLayoutGroup top="Персонаж">
          <Field
            name="character"
            component={SelectCharacter}
            variants={characterVariants}
            title="Выберите персонажа"
            className="select-background"
            size={[200, 200]}
            gameId={GameId.RUNNER}
          />
        </FormLayoutGroup>
      </FormLayout>
    </Wrap>
  );
};

const Wrap = styled.div`
  .select-background {
    margin: 0 12px;
  }
`;

const TitlePreview = styled.div<{ color?: string }>`
  margin: 0 12px;
  font-size: 24px;
  font-weight: bold;
  color: ${(p) => p.color};
  background-color: #fff;
`;

const SubtitlePreview = styled.div<{ color?: string }>`
  margin: 0 12px;
  font-size: 16px;
  line-height: 20px;
  color: ${(p) => p.color};
  background-color: #fff;
`;

const withForm = reduxForm<any, any, any>({
  form: 'game',
})(Design);

const withStore = connect((state: RootState) => {
  const { mainSettingsId } = state.vk.gameSettings;

  return {
    initialValues: state.myGames.data.find(
      (game: any) => game.mainGameSettings.id === mainSettingsId
    ),
  };
}, {})(withForm);

export { withStore as Design };
