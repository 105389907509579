import { createMuiTheme } from '@material-ui/core';
import { colors } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { ZIndex } from '@material-ui/core/styles/zIndex';

const white = '#FFFFFF';
const black = '#000000';

export const palette = {
  black,
  white,
  primary: {
    contrastText: 'rgba(255, 255, 255, 1)',
    dark: '#5f31e0',
    main: '#5f31e0',
    light: '#5f31e0',
  },
  secondary: {
    contrastText: white,
    dark: colors.cyan[900],
    main: colors.cyan[700],
    light: colors.cyan['A400'],
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.cyan[900],
    main: colors.cyan[600],
    light: colors.cyan[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: '#F4F6F8',
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
};

const typography = {
  h1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '35px',
    letterSpacing: '-0.24px',
    lineHeight: '40px',
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '29px',
    letterSpacing: '-0.24px',
    lineHeight: '32px',
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '24px',
    letterSpacing: '-0.06px',
    lineHeight: '28px',
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '-0.06px',
    lineHeight: '24px',
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '25px',
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '21px',
  },
  body1: {
    color: palette.text.primary,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '21px',
  },
  body2: {
    color: palette.text.secondary,
    fontSize: '12px',
    letterSpacing: '-0.04px',
    lineHeight: '18px',
  },
  button: {
    color: palette.text.primary,
    fontSize: '14px',
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '11px',
    letterSpacing: '0.33px',
    lineHeight: '13px',
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.33px',
    lineHeight: '13px',
    textTransform: 'uppercase',
  },
};

// @ts-ignore
const theme: Theme = createMuiTheme({
  palette,
  // @ts-ignore
  typography,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#a0a0a0',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 16,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        width: 'calc(100% - 30px)',
        margin: 0,
        borderRadius: 0,
        '@media (min-width: 600px)': {
          height: 'auto',
          borderRadius: 8,
        },
      },
    },
    MuiDrawer: {
      paper: {
        zIndex: 100,
      },
    },
  },
});

export interface Theme {
  palette: PaletteOptions;
  typography: TypographyOptions;
  zIndex: Partial<ZIndex>;
  breakpoints: any;
}

export default theme;
