import React, { FC } from 'react';
import cn from 'classnames';

import { GameProps, BoxSettings } from '../common/types';

import styles from './box.module.css';

type BoxGameProps = GameProps & {
  settings: BoxSettings;
};

export const Box: FC<BoxGameProps> = ({
  settings: { boxes },
  startGame,
  endGame,
}) => {
  const onBoxClick = async () => {
    const started = await startGame();

    if (started) {
      endGame();
    }
  };

  return (
    <div
      className={styles.mainSection}
    >
      <div className={cn(styles.prizeRow, styles.prizeRow0)}>
        <div
          className={cn(styles.prize, styles.hvrPulse)}
          style={{ backgroundImage: `url(${boxes[0]})` }}
          onClick={onBoxClick}
        />
        <div
          className={cn(styles.prize, styles.hvrPulse)}
          style={{ backgroundImage: `url(${boxes[1]})` }}
          onClick={onBoxClick}
        />
      </div>

      <div className={cn(styles.prizeRow, styles.prizeRow1)}>
        <div
          className={cn(styles.prize, styles.hvrPulse)}
          style={{ backgroundImage: `url(${boxes[2]})` }}
          onClick={onBoxClick}
        />
      </div>

      <div className={cn(styles.prizeRow, styles.prizeRow2)}>
        <div
          className={cn(styles.prize, styles.hvrPulse)}
          style={{ backgroundImage: `url(${boxes[3]})` }}
          onClick={onBoxClick}
        />
        <div
          className={cn(styles.prize, styles.hvrPulse)}
          style={{ backgroundImage: `url(${boxes[4]})` }}
          onClick={onBoxClick}
        />
      </div>
    </div>
  );
};
