import React, { Component } from 'react';
import { NinjaGame } from 'src/games';
import { GameId } from 'src/store/game/types';
import { GameResult } from 'src/games/common/types';
import { NinjaSettings } from 'src/games/common/types';
import { config } from 'src/config/config';
// import { Modal } from 'src/components/Modal';

type Props = {
  getResult: (gameId: GameId) => Promise<GameResult>;
};

const getResult = (gameId: GameId): Promise<GameResult> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        win: true,
        slotValue: 'MAC999',
        slotText: 'iPhone 11 Pro',
        slotType: 'PROMO_CODE',
      });
    }, 500);
  });
};

const mockSettings: NinjaSettings = {
  id: 11,
  userId: 5,
  titleText: 'Прыгайте через препятствия!',
  subtitleText:
    'Чем больше очков - тем лучше будет приз. У вас 2 попытки. Зачтется лучший результат.',
  winTitle: 'Поздравляем! Ваш выигрыш: {prize}',
  winSubtitle: 'Держите промокод: {prize}',
  createdAt: '2020-07-08',
  failTitle: 'Удача сегодня не на вашей стороне :(',
  failSubtitle: '',
  updatedAt: '2020-07-08',
  gameId: GameId.RUNNER,
  targetId: 120,
  titleColor: '#000',
  subtitleColor: '#5a5a5a',
  secondSectionBgColor: '#fff',
  mainGameSettingsId: 95,
  // groundImgSrc: 'https://cdn2.embedgames.app/RUNNER/assets/ground_0.png',
  // backgroundImgSrc: 'https://cdn2.embedgames.app/RUNNER/assets/background_0.png',
  character: 'ch1',
  // @ts-ignore
  mainGameSettings: {
    id: 95,
    gameId: GameId.RUNNER,
    gameBreak: 24,
    userId: 5,
    targetId: 120,
    showDelay: 3,
    isPublish: false,
  },
  // @ts-ignore
  targetInfo: {
    vkClubID: config.vkClubId,
  },
  prizesPreview: true,
  prizes: {
    prizes: [
      {
        value: 'IPHONE_SALE',
        text: 'Приз #1',
        type: 'PROMO_CODE',
        chance: 10,
      },
      {
        value: '123',
        text: 'Приз #2',
        type: 'PROMO_CODE',
        chance: 10,
      },
      {
        value: '23',
        type: 'PROMO_CODE',
        chance: 10,
        text: 'Приз #3',
      },
      {
        value: '123',
        text: 'Приз #4',
        type: 'PROMO_CODE',
        chance: 10,
      },
      {
        value: '123',
        text: 'Приз #5',
        type: 'PROMO_CODE',
        chance: 10,
      },
    ],
  },
};

type State = {
  closeKey: boolean;
};

export default class ChestDemo extends Component<Props, State> {
  state: State = {
    closeKey: false, // для ререндера
  };

  onClose = () => {
    this.setState({ closeKey: !this.state.closeKey });
  };

  render() {
    const closeKey = `${this.state.closeKey}`;

    return (
      <NinjaGame
        {...this.props}
        lockGame={() => {}}
        key={closeKey}
        getResult={getResult}
        settings={mockSettings}
        sendAnalytics={(params) =>
          new Promise((resolve, reject) => {
            console.log('send analytics ====>', params);
            resolve({});
          })
        }
        close={this.onClose}
      />
    );
  }
}
