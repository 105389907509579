import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import bridge from '@vkontakte/vk-bridge';
import axios from 'axios';
import {
  PanelHeader,
  Group,
  Button,
  Card,
  CardGrid,
  Headline,
  PanelSpinner,
  CellButton,
  ActionSheet,
  ActionSheetItem,
  usePlatform,
  IOS,
  Alert,
  FormStatus,
  Div,
  Counter,
} from '@vkontakte/vkui';
import styled from 'styled-components';

import * as Sentry from '@sentry/react';
import { Icon24Add, Icon24MoreVertical } from '@vkontakte/icons';

import { RootState } from 'src/core/rootReducer';
import { MyGamesInitState } from 'src/store/my-games/reducers';
import { ApiStatus } from 'src/core/ApiStatus';
import { vkActions } from 'src/store/vk/reducer';
import { Panels, Views } from 'src/store/vk/types';
import { GameId } from 'src/store/game/types';
import { TargetsInitState } from 'src/store/targets/reducers';
import { getStringParams, getVkQuery } from 'src/vk-app/utils/query';
import { myGamesActions } from 'src/store/my-games/actions';
import { config } from 'src/config/config';
import { targetsActions } from 'src/store/targets/actions';
import { FormStatusComponent } from './form-status-components';

type GameData = {
  mainGameSettingsId: number;
};

export const MyGamesPanel = () => {
  const dispatch = useDispatch();
  const games = useSelector<RootState, MyGamesInitState>((state) => state.myGames);
  const targets = useSelector<RootState, TargetsInitState>((state) => state.targets);
  const [buttonJoinToGroupDisabled, setButtonJoinToGroupDisabled] = useState(false);
  const [tokenError, setTokenError] = useState('');

  const [needShowSubscription, setNeedShowSubscription] = useState(false);
  const [needShowTurnOnMessages, setNeedShowTurnOnMessages] = useState(false);

  const gameIds = Object.keys(games.data) as GameId[];
  const query = getVkQuery();

  const currentTarget = targets.data.targets.find(
    (t) => t.targetType === 'VK_CLUB' && t.vkClubID === `${query.vk_group_id}`
  );

  const handleAddGameClick = useCallback(() => {
    dispatch(vkActions.setView({ view: Views.GAMES }));
  }, [dispatch]);

  const closeDeleteConfirm = useCallback(() => {
    dispatch(vkActions.setPopoutContent({ popout: null }));
  }, [dispatch]);

  const openDeleteConfirm = useCallback(
    (gameData: GameData) => {
      dispatch(
        vkActions.setPopoutContent({
          popout: (
            <Alert
              actionsLayout="vertical"
              actions={[
                {
                  title: 'Удалить',
                  autoclose: true,
                  mode: 'destructive',
                  action: () => {
                    dispatch(myGamesActions.deleteGame.started(gameData));
                  },
                },
                {
                  title: 'Отмена',
                  autoclose: true,
                  mode: 'cancel',
                },
              ]}
              onClose={closeDeleteConfirm}
            >
              <h2>Подтвердите действие</h2>
              <p>Вы уверены, что хотите удалить игру?</p>
            </Alert>
          ),
        })
      );
    },
    [closeDeleteConfirm, dispatch]
  );

  const platform = usePlatform();

  const closeActions = useCallback(() => {
    dispatch(vkActions.setPopoutContent({ popout: null }));
  }, [dispatch]);

  const openActions = useCallback(
    (gameData: GameData) => {
      dispatch(
        vkActions.setPopoutContent({
          popout: (
            <ActionSheet onClose={closeActions}>
              <ActionSheetItem
                autoclose
                mode="destructive"
                onClick={() => {
                  openDeleteConfirm(gameData);
                }}
              >
                Удалить
              </ActionSheetItem>
              {platform === IOS && (
                <ActionSheetItem autoclose mode="cancel">
                  Отменить
                </ActionSheetItem>
              )}
            </ActionSheet>
          ),
        })
      );
    },
    [closeActions, dispatch, openDeleteConfirm, platform]
  );

  const handleMoreActionsClick = useCallback(
    (gameData: GameData) => {
      openActions(gameData);
    },
    [openActions]
  );

  const activeGame = games.data.find((settings) => settings.mainGameSettings.isPublish);

  const joinToGroup = useCallback(() => {
    setButtonJoinToGroupDisabled(true);

    bridge
      .send('VKWebAppJoinGroup', { group_id: +config.vkClubId })
      .then(() => {
        bridge.send('VKWebAppStorageSet', { key: 'vk_joined_to_group', value: 'true' });
        setNeedShowSubscription(false);
      })
      .catch(() => {
        setButtonJoinToGroupDisabled(false);
      });
  }, []);

  const getCommunityToken = useCallback(() => {
    bridge
      .send('VKWebAppGetCommunityToken', {
        app_id: config.vkAppId,
        group_id: +query.vk_group_id,
        scope: 'messages',
      })
      .then((data) => {
        axios
          .post(
            `${config.api}/vk/add-community-token`,
            {
              communityToken: data.access_token,
              ...getStringParams(),
            },
            { withCredentials: true }
          )
          .then(() => {
            setNeedShowTurnOnMessages(false);
            dispatch(targetsActions.get.started({ isVk: true }));
          })
          .catch((err) => {
            Sentry.captureException(err);
          });
      })
      .catch((err) => {
        // User denied
        if (err?.error_data?.error_code !== 4) {
          setTokenError(err?.error_data?.error_reason);

          Sentry.captureException(err);
        }
      });
  }, [dispatch, query.vk_group_id]);

  useEffect(() => {
    bridge
      .send('VKWebAppStorageGet', { keys: ['vk_joined_to_group'] })
      .then(({ keys: [joinedToGroup] }) => {
        if (joinedToGroup.value !== 'true') {
          setNeedShowSubscription(true);
        }
      });
  }, []);

  useEffect(() => {
    if (currentTarget && currentTarget.vkToken === 'empty') {
      setNeedShowTurnOnMessages(true);
    }
  }, [currentTarget]);

  useEffect(() => {
    dispatch(myGamesActions.get.started({ isVk: true }));
  }, [dispatch]);

  useEffect(() => {
    if (currentTarget && currentTarget.vkToken === 'empty') {
      getCommunityToken();
    }
  }, [currentTarget, getCommunityToken]);

  return (
    <Wrap>
      <PanelHeader>Мои кампании</PanelHeader>

      {activeGame && currentTarget ? (
        <FormStatusComponent activeGame={activeGame} currentTarget={currentTarget} />
      ) : null}

      {needShowTurnOnMessages && (
        <Div>
          <FormStatus header="Включите сообщения сообщества">
            Мы отсылаем сообщения от имени вашего сообщества, чтобы в дальнейшем вы могли продолжить
            коммуникацию с игроками.
            <br />
            Для этого нужно <b>включить сообщения сообщества</b> и{' '}
            <b>разрешить отправку сообщений от вашего сообщества</b>.
            <br />
            <br />
            <Button onClick={getCommunityToken}>Разрешить</Button>
            {tokenError && <Error>{tokenError}</Error>}
          </FormStatus>
        </Div>
      )}

      <Group>
        <CardGrid>
          {games.data.map((game) => {
            const gameInfo = game.gameInfo;
            const { isPublish, isPublishOnDirectLink, campaignName } = game.mainGameSettings;

            return (
              <Card size="l" className="eg-card" key={game.mainGameSettings.id}>
                <GameCover src={gameInfo.cover} />

                <GameDescription>
                  <HeadLineWrap>
                    <Headline weight="semibold" style={{ marginBottom: 4 }}>
                      <CampaignName>{campaignName || gameInfo.name}</CampaignName>
                    </Headline>

                    <Icon24MoreVertical
                      onClick={() =>
                        handleMoreActionsClick({
                          mainGameSettingsId: game.mainGameSettingsId,
                        })
                      }
                    />
                  </HeadLineWrap>

                  <LabelsWrapper>
                    {isPublish && (
                      <Counter size="s" mode="primary">
                        Опубликована
                      </Counter>
                    )}
                    {isPublishOnDirectLink && (
                      <Counter size="s" mode="secondary">
                        Опубликована по прямой ссылке
                      </Counter>
                    )}
                  </LabelsWrapper>

                  <Button
                    size="m"
                    onClick={() => {
                      dispatch(
                        vkActions.setGameSettings({
                          gameId: game.gameId,
                          mainSettingsId: game.mainGameSettingsId,
                        })
                      );
                      dispatch(vkActions.setView({ view: Views.MY_GAMES }));
                      dispatch(vkActions.setPanel({ panel: Panels[Views.MY_GAMES]['GAME'] }));
                    }}
                  >
                    Настроить
                  </Button>
                </GameDescription>
              </Card>
            );
          })}
        </CardGrid>

        <CellButton before={<Icon24Add />} onClick={handleAddGameClick} className="add-button">
          Создать кампанию
        </CellButton>

        {games.status === ApiStatus.FETCHING && gameIds.length === 0 && <PanelSpinner />}
      </Group>

      {needShowSubscription && (
        <Div>
          <FormStatus header="Не пропусти новые игры">
            Подпишись на нашу группу Вконтакте! Так ты первым узнаешь о выходе новых игр.
            <br />
            <br />
            <Button onClick={joinToGroup} disabled={buttonJoinToGroupDisabled}>
              Подписаться
            </Button>
          </FormStatus>
        </Div>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  .eg-card {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 15px;
    }
  }

  .Card__in {
    width: 100%;
    max-width: none;
  }
`;

const GameCover = styled.div<{ src: string }>`
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-size: cover;
  background-position: center center;
  background-image: url(${(p) => p.src});
`;

const GameDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-left: 20px;

  .Counter {
    background-color: #4bb34b;
  }
`;

const HeadLineWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Error = styled.div`
  font-size: 12px;
  color: red;
  margin-top: 8px;
`;

const LabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0 12px;
  gap: 4px;
`;

const CampaignName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-word;
  max-height: 40px;

  /*! autoprefixer: off */
  -webkit-box-orient: vertical;

  /*! autoprefixer: on */
  -webkit-line-clamp: 2;
`;
