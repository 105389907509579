import React from 'react';
import styled from 'styled-components';
import { Loader } from '@alfalab/core-components-loader';

import { withDelay } from '../DelayedRender/DelayedRender';

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SLoader = styled(Loader)`
  width: 50px;
  height: 50px;
`;

export const ChunkLoader = () => (
  <Wrap>
    <SLoader />
  </Wrap>
);

export const DelayedChunkLoader = withDelay(ChunkLoader);
