import React, { FC } from 'react';
import { PanelHeader, PanelHeaderBack } from '@vkontakte/vkui';
import { useDispatch, useSelector } from 'react-redux';
import { VKBridge } from '@vkontakte/vk-bridge';

import { RootState } from 'src/core/rootReducer';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { FormName, SettingsForm } from 'src/store/vk/types';
import { GameId } from 'src/store/game/types';

import {CampaignSettings} from './common';

import {
  Result as BoxResult,
  Design as BoxDesign,
  Texts as BoxTexts,
  Publish as BoxPublish,
  Promocodes as BoxPromocodes,
  Rules as BoxRules,
  Statistics as BoxStatistics,
  Premium as BoxPremium,
  PublicCodes as BoxPublicCodes,
} from './box';

import {
  Result as ChestResult,
  Design as ChestDesign,
  Texts as ChestTexts,
  Publish as ChestPublish,
  Promocodes as ChestPromocodes,
  Rules as ChestRules,
  Statistics as ChestStatistics,
  Premium as ChestPremium,
  PublicCodes as ChestPublicCodes,
} from './chest';

import {
  Result as WheelResult,
  Design as WheelDesign,
  Texts as WheelTexts,
  Publish as WheelPublish,
  Promocodes as WheelPromocodes,
  Rules as WheelRules,
  Statistics as WheelStatistics,
  Premium as WheelPremium,
  PublicCodes as WheelPublicCodes,
} from './wheel';

import {
  Result as RunnerResult,
  Design as RunnerDesign,
  Texts as RunnerTexts,
  Publish as RunnerPublish,
  Promocodes as RunnerPromocodes,
  Rules as RunnerRules,
  Statistics as RunnerStatistics,
  Premium as RunnerPremium,
  PublicCodes as RunnerPublicCodes,
} from './runner';

import {
  Result as NinjaResult,
  Design as NinjaDesign,
  Texts as NinjaTexts,
  Publish as NinjaPublish,
  Promocodes as NinjaPromocodes,
  Rules as NinjaRules,
  Statistics as NinjaStatistics,
  Premium as NinjaPremium,
  PublicCodes as NinjaPublicCodes,
} from './ninja';

import {
  Result as LuckyResult,
  Design as LuckyDesign,
  Texts as LuckyTexts,
  Publish as LuckyPublish,
  Promocodes as LuckyPromocodes,
  Rules as LuckyRules,
  Statistics as LuckyStatistics,
  Premium as LuckyPremium,
  PublicCodes as LuckyPublicCodes,
} from './lucky';

const FormComponents = {
  [GameId.BOX]: {
    [SettingsForm.SHOW_RESULT]: BoxResult,
    [SettingsForm.DESIGN]: BoxDesign,
    [SettingsForm.TEXT]: BoxTexts,
    [SettingsForm.PUBLISH]: BoxPublish,
    [SettingsForm.PROMOCODES]: BoxPromocodes,
    [SettingsForm.RULES]: BoxRules,
    [SettingsForm.STATISTICS]: BoxStatistics,
    [SettingsForm.PREMIUM]: BoxPremium,
    [SettingsForm.PUBLIC_CODES]: BoxPublicCodes,
    [SettingsForm.CAMPAIGN_SETTINGS]: CampaignSettings,
  },
  [GameId.CHEST]: {
    [SettingsForm.SHOW_RESULT]: ChestResult,
    [SettingsForm.DESIGN]: ChestDesign,
    [SettingsForm.TEXT]: ChestTexts,
    [SettingsForm.PUBLISH]: ChestPublish,
    [SettingsForm.PROMOCODES]: ChestPromocodes,
    [SettingsForm.RULES]: ChestRules,
    [SettingsForm.STATISTICS]: ChestStatistics,
    [SettingsForm.PREMIUM]: ChestPremium,
    [SettingsForm.PUBLIC_CODES]: ChestPublicCodes,
    [SettingsForm.CAMPAIGN_SETTINGS]: CampaignSettings,
  },
  [GameId.WHEEL]: {
    [SettingsForm.SHOW_RESULT]: WheelResult,
    [SettingsForm.DESIGN]: WheelDesign,
    [SettingsForm.TEXT]: WheelTexts,
    [SettingsForm.PUBLISH]: WheelPublish,
    [SettingsForm.PROMOCODES]: WheelPromocodes,
    [SettingsForm.RULES]: WheelRules,
    [SettingsForm.STATISTICS]: WheelStatistics,
    [SettingsForm.PREMIUM]: WheelPremium,
    [SettingsForm.PUBLIC_CODES]: WheelPublicCodes,
    [SettingsForm.CAMPAIGN_SETTINGS]: CampaignSettings,
  },
  [GameId.RUNNER]: {
    [SettingsForm.SHOW_RESULT]: RunnerResult,
    [SettingsForm.DESIGN]: RunnerDesign,
    [SettingsForm.TEXT]: RunnerTexts,
    [SettingsForm.PUBLISH]: RunnerPublish,
    [SettingsForm.PROMOCODES]: RunnerPromocodes,
    [SettingsForm.RULES]: RunnerRules,
    [SettingsForm.STATISTICS]: RunnerStatistics,
    [SettingsForm.PREMIUM]: RunnerPremium,
    [SettingsForm.PUBLIC_CODES]: RunnerPublicCodes,
    [SettingsForm.CAMPAIGN_SETTINGS]: CampaignSettings,
  },
  [GameId.NINJA]: {
    [SettingsForm.SHOW_RESULT]: NinjaResult,
    [SettingsForm.DESIGN]: NinjaDesign,
    [SettingsForm.TEXT]: NinjaTexts,
    [SettingsForm.PUBLISH]: NinjaPublish,
    [SettingsForm.PROMOCODES]: NinjaPromocodes,
    [SettingsForm.RULES]: NinjaRules,
    [SettingsForm.STATISTICS]: NinjaStatistics,
    [SettingsForm.PREMIUM]: NinjaPremium,
    [SettingsForm.PUBLIC_CODES]: NinjaPublicCodes,
    [SettingsForm.CAMPAIGN_SETTINGS]: CampaignSettings,
  },
  [GameId.LUCKY]: {
    [SettingsForm.SHOW_RESULT]: LuckyResult,
    [SettingsForm.DESIGN]: LuckyDesign,
    [SettingsForm.TEXT]: LuckyTexts,
    [SettingsForm.PUBLISH]: LuckyPublish,
    [SettingsForm.PROMOCODES]: LuckyPromocodes,
    [SettingsForm.RULES]: LuckyRules,
    [SettingsForm.STATISTICS]: LuckyStatistics,
    [SettingsForm.PREMIUM]: LuckyPremium,
    [SettingsForm.PUBLIC_CODES]: LuckyPublicCodes,
    [SettingsForm.CAMPAIGN_SETTINGS]: CampaignSettings,
  },
};

type Props = {
  bridge: VKBridge;
};

export const GameSettingsFormPanel: FC<Props> = () => {
  const dispatch = useDispatch();
  const {
    gameSettings: { gameId, form },
  } = useSelector<RootState, VKInitialState>((state) => state.vk);

  const FormComponent = FormComponents[gameId][form];

  return (
    <>
      <PanelHeader
        left={
          <PanelHeaderBack
            onClick={() => {
              dispatch(vkActions.requestSaveForm({ save: true }));
            }}
          />
        }
      >
        {FormName[form]}
      </PanelHeader>

      <FormComponent />
    </>
  );
};
