import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '@vkontakte/vkui';
import { VKBridge, UserInfo } from '@vkontakte/vk-bridge';
import axios from 'axios';
import styled from 'styled-components';

import { getStringParams } from 'src/vk-app/utils/query';
import { config } from '../../../config/config';

import logo from './logo.svg';

type Props = {
  bridge: VKBridge;
};

const steps = [
  {
    title: '📲 1. Установите приложение к себе в сообщество',
    description:
      'Выберите нужную игру, и настройте ее: вы можете добавлять призы, менять дизайн и тексты',
  },
  {
    title: '🎮 2. Опубликуйте игру',
    description:
      'При входе в приложение администраторы сообщества увидят весь интерфейс приложения, а все остальные — только опубликованную игру',
  },
  {
    title: '📈 3. Получайте результат',
    description:
      'Пользователи играют и получают призы.' +
      'Чтобы получить промокод, они должны разрешить получать сообщения от вашего сообщества',
  },
  {
    title: '🧲. Работайте с аудиторией',
    description:
      'Теперь вы можете отправлять этим пользователям сообщения. ' +
      'Например, вы можете добавить их в список рассылки и уведомлять об акциях и скидках',
  },
];

const thesises = [
  {
    emoji: '☝️',
    thesis: 'Ценность выигранного\nпромокода возрастает',
  },
  {
    emoji: '🚀',
    thesis: 'Рост конверсии до 300%',
  },
  {
    emoji: '💫',
    thesis: 'Клиенты чаще возвращаются',
  },
  // {
  //   emoji: '💰',
  //   thesis: 'Сервис окупается\n уже через 2 недели',
  // },
];

export const GreetingPanel: FC<Props> = ({ bridge }) => {
  const [user, setUser] = useState<UserInfo>();
  const [success, setSuccess] = useState(false);

  const groupId = useRef(0);

  const install = useCallback(() => {
    bridge
      .send('VKWebAppAddToCommunity')
      .then((data) => {
        groupId.current = data.group_id;

        axios
          .post(
            `${config.api}/vk/register-user`,
            {
              userName: user && `${user.first_name} ${user.last_name}`,
              ...getStringParams(),
            },
            { withCredentials: true }
          )
          .then(() => {
            setSuccess(true);
          })
          .catch(() => {
            //
          });
      })
      .catch(() => {
        //
      });
  }, [bridge, user]);

  const handleInstallClick = useCallback(() => {
    install();
  }, [install]);

  useEffect(() => {
    bridge.send('VKWebAppGetUserInfo').then((data) => {
      setUser(data);
    });
  }, [bridge]);

  const renderButton = () =>
    success ? (
      <Button size="l" href={`https://vk.com/club${groupId.current}`} target="_blank">
        Перейти в сообщество
      </Button>
    ) : (
      <Button size="l" onClick={handleInstallClick}>
        Установить в сообщество
      </Button>
    );

  return (
    <Wrap>
      <Header>
        <Logo />
        <HeaderTitle>
          Конструктор игр
          <br />
          для вашего сообщества
        </HeaderTitle>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <HeaderText>Повышайте продажи при промощи игровых виджетов 🚀</HeaderText>
      </Header>

      <MainText>
        EmbedGames — это уникальный конструктор игровых виджетов.
        <br />
        <br />С помощью него вы можете в короткие сроки запустить розыгрыш призов в вашем
        сообществе.
        <br />
        <br />
        <br />
        {renderButton()}
      </MainText>

      <Section color="#fbfbf9">
        {steps.map((feature) => (
          <Feature key={feature.title}>
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureDescription>{feature.description}</FeatureDescription>
          </Feature>
        ))}

        {renderButton()}
      </Section>

      <Section>
        <SectionTitle>Людям нравятся игры. А еще больше нравится выигрывать!</SectionTitle>

        {thesises.map((thesis) => (
          <Thesis key={thesis.thesis}>
            <ThesisEmoji>{thesis.emoji}</ThesisEmoji>

            <span>{thesis.thesis}</span>
          </Thesis>
        ))}
      </Section>

      <Section color="#fbfbf9">
        <SectionTitle>Доп. возможности</SectionTitle>

        <FeatureDescription>
          Запускайте игры только для подписчиков сообщества, либо только для Донов
        </FeatureDescription>

        <br />
        <Divider />
        <br />

        <FeatureDescription>Добавляйте пользователей в рассылки Senler</FeatureDescription>

        <br />
        <Divider />
        <br />

        <FeatureDescription>
          Получайте уведомления о выигрышах в личные сообщения
        </FeatureDescription>

        <br />
        <br />

        {renderButton()}
      </Section>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding: 72px 16px 24px;
  background: linear-gradient(105.45deg, #3f4383 0.42%, #5358b1 103.63%);
  text-align: center;
`;

const HeaderTitle = styled.h1`
  color: #fff;
  font-size: 20px;
  margin: 0;
  margin-bottom: 16px;
`;

const HeaderText = styled.h2`
  font-size: 20px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
`;

const Logo = styled.div`
  width: 80px;
  height: 80px;
  background-size: cover;
  background-image: url(${logo});
  margin: 0 auto 36px;
`;

const MainText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #444;
  margin: 32px auto;
  padding: 0 16px;
  text-align: center;
  max-width: 400px;
`;

const Section = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 32px 16px;
  max-width: 400px;
  margin: 0 auto;

  ${(p) => p.color && `background-color: ${p.color};`};
`;

const Feature = styled.div`
  margin-bottom: 25px;
`;

const FeatureTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const FeatureDescription = styled.div`
  color: #929292;
`;

const Thesis = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #929292;
  margin-bottom: 32px;
  white-space: pre-line;
`;

const ThesisEmoji = styled.div`
  font-size: 32px;
  margin-bottom: 8px;
`;

const Divider = styled.div`
  width: 100px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
`;
