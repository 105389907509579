import React, { ReactNode } from 'react';

interface Props {
  defaultContent?: ReactNode;
}

export function withDelay<T extends Props>(Component: any) {
  return class extends React.Component<T> {
    state = {
      pastDelay: false,
    };

    timer: number = 0;

    componentDidMount() {
      this.timer = setTimeout(() => {
        this.setState({ pastDelay: true });
      }, 200);
    }

    componentWillUnmount() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
    }

    render() {
      const { pastDelay } = this.state;
      const { defaultContent = null, ...rest } = this.props;

      return pastDelay ? <Component {...rest} /> : defaultContent;
    }
  };
}
