import React, { CSSProperties, FC, useCallback, useEffect, useRef, useState } from 'react';
import { Loader } from '@alfalab/core-components-loader';
import cn from 'classnames';
import { pluralize } from '@alfalab/utils';

import { GameProps, NinjaSettings } from '../common/types';
import { useWindowResize } from '../../core/hooks/useWindowResize';

import { drawImage } from '../common/utils/drawers';
import {
  loadTextures,
  getRandomInteger,
  intersects,
  Rect,
  formatScore,
  loadTexture,
} from '../common/utils/helpers';

import { getEnvSize, CHARACTER_SIZE } from './helpers';

import styles from './ninja.module.css';

type NinjaGameProps = GameProps & {
  settings: NinjaSettings;
};

type CharacterState = 'IDLE' | 'RUN' | 'JUMP';

type EnvInfo = Array<{
  position: [number, number];
  size: [number, number];
  env: HTMLImageElement;
}>;

const MAX_ATTEMPTS_DEFAULT = 2;
const GROUND_HEIGHT = 75;
const MOUNTAINS_HEIGHT = 175;

export const Ninja: FC<NinjaGameProps> = ({
  settings: { character, attempts, mainGameSettings },
  started,
  startGame,
  endGame,
}) => {
  const [attempt, setAttempt] = useState(0);
  const characterState = useRef<CharacterState>('IDLE');
  const level = useRef(1);
  const [score, setScore] = useState(0);
  const lastGoingEnvIndex = useRef(-1);
  const [crashScreenVisible, setCrashScreenVisible] = useState(false);
  const [getPrizeInProgress, setGetPrizeInProgress] = useState(false);

  // time
  const startJump = useRef(0);

  const [canvasSize, setCanvasSize] = useState<[number, number]>([0, 0]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const context = useRef<CanvasRenderingContext2D | null>(null);

  const [characterIdleImages, setCharacterIdleImages] = useState<HTMLImageElement[]>([]);
  const [characterRunImages, setCharacterRunImages] = useState<HTMLImageElement[]>([]);
  const [characterJumpImages, setCharacterJumpImages] = useState<HTMLImageElement[]>([]);
  const [groundImage, setGroundImage] = useState<HTMLImageElement | null>(null);
  const [backgroundImage, setBackgroundImage] = useState<HTMLImageElement | null>(null);
  const [mountainsImage, setMountainsImage] = useState<HTMLImageElement | null>(null);
  const [envImage, setEnvImage] = useState<HTMLImageElement | null>(null);

  const crash = useRef(false);
  const maxScore = useRef(0);
  const skyXPosition = useRef(0);
  const mountainsXPosition = useRef(0);

  const envInfo = useRef<EnvInfo>([]);

  const mainRequestId = useRef(0);
  const characterRequestId = useRef(0);

  const groundY = canvasSize[1] - GROUND_HEIGHT;
  const characterBottom = groundY - 50;

  const characterPosition = useRef<[number, number]>([0, 0]);

  const currentCharacterImage = useRef<HTMLImageElement | null>(null);

  const MAX_ATTEMPTS = attempts ?? MAX_ATTEMPTS_DEFAULT;

  const characterSrcSet: Map<CharacterState, HTMLImageElement[]> = new Map([
    ['IDLE', characterIdleImages],
    ['RUN', characterRunImages],
    ['JUMP', characterJumpImages],
  ]);

  useWindowResize(() => {
    if (wrapperRef.current) {
      setCanvasSize([wrapperRef.current.clientWidth, wrapperRef.current.clientHeight]);
    }
  });

  useEffect(() => {
    if (wrapperRef.current) {
      setCanvasSize([wrapperRef.current.clientWidth, wrapperRef.current.clientHeight]);
    }

    if (canvasRef.current) {
      context.current = canvasRef.current.getContext('2d');
    }
  }, []);

  useEffect(() => {
    const chIdleSrcSet = [
      `https://cdn2.embedgames.app/NINJA/assets/${character}_idle_0.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_idle_1.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_idle_2.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_idle_3.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_idle_4.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_idle_5.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_idle_6.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_idle_7.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_idle_8.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_idle_9.png`,
    ];
    const chRunSrcSet = [
      `https://cdn2.embedgames.app/NINJA/assets/${character}_run_0.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_run_1.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_run_2.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_run_3.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_run_4.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_run_5.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_run_6.png`,
      `https://cdn2.embedgames.app/NINJA/assets/${character}_run_7.png`,
    ];
    const chJumpSrcSet = [`https://cdn2.embedgames.app/NINJA/assets/${character}_jump_0.png`];

    loadTextures(chIdleSrcSet, setCharacterIdleImages);
    loadTextures(chRunSrcSet, setCharacterRunImages);
    loadTextures(chJumpSrcSet, setCharacterJumpImages);
    loadTexture('https://cdn2.embedgames.app/NINJA/assets/ground.png', setGroundImage);
    loadTexture('https://cdn2.embedgames.app/NINJA/assets/sky.png', setBackgroundImage);
    loadTexture('https://cdn2.embedgames.app/NINJA/assets/mountains.png', setMountainsImage);
    loadTexture('https://cdn2.embedgames.app/NINJA/assets/env.png', setEnvImage);
  }, [character]);

  const drawCharacter = useCallback(() => {
    if (context.current) {
      drawImage(context.current, currentCharacterImage.current, characterPosition.current, [
        CHARACTER_SIZE[0],
        CHARACTER_SIZE[1],
      ]);
    }
  }, []);

  const fillEnvs = useCallback(() => {
    envInfo.current = [];

    let currentPosition = canvasSize[0];

    const blockAmountOnFirstLevel = 3;
    const maxBlockOnLevel = 15;
    const mult = blockAmountOnFirstLevel * level.current;
    const max = mult >= maxBlockOnLevel ? maxBlockOnLevel : mult;

    for (let i = 0; i < max; i++) {
      const [x, y, offset] = getEnvSize(level.current);
      const position: [number, number] = [currentPosition, characterBottom + offset - 5];

      envInfo.current.push({
        position,
        size: [x, y],
        env: envImage as HTMLImageElement,
      });

      let min: number;
      let max: number;

      if (level.current < 4) {
        min = 200;
        max = 600;
      } else if (level.current < 6) {
        min = 400;
        max = 600;
      } else {
        min = 600;
        max = 600;
      }

      currentPosition += getRandomInteger(min, max);
    }
  }, [canvasSize, characterBottom, envImage]);

  const drawEnvs = useCallback(() => {
    envInfo.current.forEach(({ position, size, env }) => {
      if (context.current) {
        drawImage(context.current, env, position, size);
      }
    });
  }, []);

  const texturesLoaded =
    characterIdleImages.length > 0 &&
    characterRunImages.length > 0 &&
    characterJumpImages.length > 0 &&
    Boolean(groundImage) &&
    Boolean(backgroundImage) &&
    Boolean(mountainsImage) &&
    Boolean(envImage);

  const drawSky = useCallback(() => {
    if (context.current) {
      const skyWidth = canvasSize[0] * 2;

      const firstFrameStart = skyXPosition.current;
      const firstFrameEnd = firstFrameStart + skyWidth;
      const secondFrameStart = firstFrameEnd;

      if (secondFrameStart <= 0 && firstFrameStart < 0) {
        skyXPosition.current += skyWidth;
      }

      drawImage(context.current, backgroundImage, [firstFrameStart, 0], [skyWidth, canvasSize[1]]);
      drawImage(context.current, backgroundImage, [secondFrameStart, 0], [skyWidth, canvasSize[1]]);
    }
  }, [backgroundImage, canvasSize]);

  const drawMountains = useCallback(() => {
    if (context.current) {
      const mountainsWidth = canvasSize[0];

      const firstFrameStart = mountainsXPosition.current;
      const firstFrameEnd = firstFrameStart + mountainsWidth;
      const secondFrameStart = firstFrameEnd;

      if (secondFrameStart <= 0 && firstFrameStart < 0) {
        mountainsXPosition.current += mountainsWidth;
      }

      drawImage(
        context.current,
        mountainsImage,
        [firstFrameStart, groundY - 155],
        [mountainsWidth, MOUNTAINS_HEIGHT]
      );
      drawImage(
        context.current,
        mountainsImage,
        [secondFrameStart, groundY - 155],
        [mountainsWidth, MOUNTAINS_HEIGHT]
      );
    }
  }, [canvasSize, groundY, mountainsImage]);

  const renderScene = useCallback(() => {
    if (context.current) {
      context.current.clearRect(0, 0, canvasSize[0], canvasSize[1]);
      drawSky();
      drawMountains();
      drawImage(context.current, groundImage, [0, groundY], [canvasSize[0], GROUND_HEIGHT]);
      drawEnvs();
      drawCharacter();
    }
  }, [canvasSize, drawCharacter, drawEnvs, drawMountains, drawSky, groundImage, groundY]);

  useEffect(() => {
    if (context.current && texturesLoaded) {
      characterPosition.current = [0, characterBottom];

      if (envInfo.current.length === 0) {
        fillEnvs();
      }
    }
  }, [characterBottom, fillEnvs, groundY, texturesLoaded]);

  /**
   * Анимация персонажа в зависимости от его состояния и скорости
   */
  useEffect(() => {
    let prev = Date.now();
    let index = 0;

    const setCharacterImage = () => {
      if (crash.current) {
        characterRequestId.current = 0;
        return;
      }

      const now = Date.now();
      const diff = now - prev;

      const mult = 70 - level.current * 5;
      const maxDiff = mult < 20 ? 20 : mult;

      if (diff > maxDiff && context.current) {
        prev = now;

        let srcSet = characterSrcSet.get(characterState.current);

        if (srcSet) {
          if (index >= srcSet.length - 1) {
            index = 0;
          } else {
            index++;
          }

          currentCharacterImage.current = srcSet[index];
        }

        renderScene();
      }

      characterRequestId.current = requestAnimationFrame(setCharacterImage);
    };

    if (!characterRequestId.current && texturesLoaded) {
      characterRequestId.current = requestAnimationFrame(setCharacterImage);
    }
  }, [characterSrcSet, characterState, texturesLoaded, renderScene]);

  /**
   * Главный цикл фреймов
   */
  useEffect(() => {
    const y0 = characterPosition.current[1];
    let prevY = y0;

    const render = () => {
      const v0 = 0.8 * Math.sqrt(level.current); // начальная скорость
      const g = 0.0015 * level.current; // ускорение свободного падения
      const speed = level.current + 2;

      /**
       * Обработка столкновения
       */
      const [w, h] = [35, 60]; // real caharacter size in png
      const x1 = characterPosition.current[0] + 30;
      const x2 = x1 + w;
      const y1 = characterPosition.current[1] + 10;
      const y2 = y1 + h;

      const characterRect: Rect = { x1, y1, x2, y2 };

      for (let i = 0; i < envInfo.current.length; i++) {
        const {
          position: [x1, y1],
          size: [w, h],
        } = envInfo.current[i];

        const envRect: Rect = {
          x1,
          y1,
          x2: x1 + w,
          y2: y1 + h,
        };

        if (intersects(characterRect, envRect)) {
          crash.current = true;
          break;
        }

        if (envRect.x2 <= 0 && i > lastGoingEnvIndex.current) {
          lastGoingEnvIndex.current = i;
          setScore((score) => {
            const newScore = score + 1;

            if (newScore > maxScore.current) {
              maxScore.current = newScore;
            }

            return newScore;
          });
        }
      }

      if (crash.current) {
        setCrashScreenVisible(true);
        setAttempt((attempt) => attempt + 1);
        mainRequestId.current = 0;
        return;
      }

      /**
       * Препятствия
       */
      envInfo.current = envInfo.current.map((info) => {
        info.position[0] -= speed;

        return info;
      });

      /**
       * Прыжок персонажа
       */
      if (characterState.current === 'JUMP') {
        const t = Date.now() - startJump.current;
        const y = y0 - v0 * t + (g * t * t) / 2;
        prevY = characterPosition.current[1];

        // летит вниз и достиг земли
        if (y > prevY && y >= characterBottom) {
          characterPosition.current = [characterPosition.current[0], characterBottom];
          characterState.current = 'RUN';
        } else {
          characterPosition.current = [characterPosition.current[0], y];
        }
      }

      /**
       * Если блоки закончились, поднимаем уровень, и генерируем новые блоки
       */
      if (
        envInfo.current[envInfo.current.length - 1]?.position[0] < 0 &&
        characterState.current !== 'JUMP'
      ) {
        level.current++;
        lastGoingEnvIndex.current = -1;
        fillEnvs();
      }

      /**
       * Небо
       */
      skyXPosition.current -= 0.1;

      /**
       * Горы
       */
      mountainsXPosition.current -= 0.2;

      mainRequestId.current = requestAnimationFrame(render);
      renderScene();
    };

    if (!mainRequestId.current && started && !crashScreenVisible) {
      mainRequestId.current = requestAnimationFrame(render);
    }
  }, [
    canvasSize,
    groundY,
    started,
    score,
    crashScreenVisible,
    renderScene,
    fillEnvs,
    characterBottom,
  ]);

  /**
   * cancelAnimationFrame
   */
  useEffect(() => {
    return () => {
      cancelAnimationFrame(mainRequestId.current);
      cancelAnimationFrame(characterRequestId.current);
    };
  }, []);

  const onRetryClick = useCallback(() => {
    crash.current = false;
    level.current = 1;
    characterPosition.current = [0, characterBottom];
    lastGoingEnvIndex.current = -1;
    fillEnvs();
    setCrashScreenVisible(false);
    setScore(0);
  }, [fillEnvs, characterBottom]);

  const getScoreStyles = (): CSSProperties => {
    let color = '#67E0E8';
    let backgroundColor = '#E9F459';

    return {
      color,
      backgroundColor,
    };
  };

  const onGetPrizeClick = useCallback(() => {
    if (!getPrizeInProgress) {
      setGetPrizeInProgress(true);
      endGame({ score: maxScore.current }).then(() => {
        setGetPrizeInProgress(false);
      });
    }
  }, [getPrizeInProgress, endGame]);

  const onClick = useCallback(async () => {
    if (!started && texturesLoaded) {
      if (await startGame()) {
        startJump.current = Date.now();
        characterState.current = 'JUMP';
      }
    }

    if (started) {
      if (characterState.current !== 'JUMP') {
        startJump.current = Date.now();
        characterState.current = 'JUMP';
      }
    }
  }, [startGame, started, texturesLoaded]);

  const getFinishGameText = (): string => {
    if (mainGameSettings.campaignType === 'competition') {
      return 'Зачесть результат';
    }

    return 'Получить приз';
  };

  return (
    <div className={styles.wrapper} ref={wrapperRef} onTouchStart={onClick} onMouseDown={onClick}>
      <canvas id="eg-canvas" width={canvasSize[0]} height={canvasSize[1]} ref={canvasRef} />

      {!texturesLoaded && (
        <div className={styles.loaderWrap}>
          <Loader />
        </div>
      )}

      {!started && (
        <div className={styles.splashScreen}>
          Кликните для старта.
          <br />У вас будет {MAX_ATTEMPTS}{' '}
          {pluralize(MAX_ATTEMPTS, 'попытка', 'попытки', 'попыток')}.
        </div>
      )}

      {crashScreenVisible && (
        <div className={styles.crashScreen}>
          {attempt < MAX_ATTEMPTS ? (
            <>
              <h3 className={styles.attempt}>
                Очки: <span>{score}</span>
              </h3>
              <h3 className={styles.attempt}>
                Осталось попыток: <span>{MAX_ATTEMPTS - attempt}</span>
              </h3>
            </>
          ) : (
            <h3 className={styles.attempt}>
              Лучший результат: <span>{maxScore.current}</span>
            </h3>
          )}

          <div className={styles.buttonsWrap}>
            {attempt < MAX_ATTEMPTS ? (
              <>
                <button className={cn(styles.retryButton, styles.button)} onClick={onRetryClick}>
                  Сыграть еще раз
                </button>

                <button
                  className={cn(styles.getPrizeButton, styles.button)}
                  onClick={onGetPrizeClick}
                >
                  {getPrizeInProgress ? <Loader /> : getFinishGameText()}
                </button>
              </>
            ) : (
              <button
                className={cn(styles.button, styles.getPrizeButton)}
                onClick={onGetPrizeClick}
              >
                {getPrizeInProgress ? <Loader /> : getFinishGameText()}
              </button>
            )}
          </div>
        </div>
      )}

      {started && !crashScreenVisible && (
        <div style={getScoreStyles()} className={styles.score}>
          {formatScore(score)}
        </div>
      )}
    </div>
  );
};
