import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import rootReducer, { ROOT_INITIAL_STATE } from './rootReducer';
import SagaManager from './rootSaga';
import { IS_DEV } from './constants';

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory({
  getUserConfirmation: (message, callback) => callback(window.confirm(message)),
});

const configStore = () => {
  // STORE CONFIGURATIONS
  const storeEnhancers: any = [];
  const middlewares: any = [sagaMiddleware, routerMiddleware(history)];

  // apply middlewares
  storeEnhancers.push(applyMiddleware(...middlewares));

  // add dev-tools storeEnhancer
  if (IS_DEV) {
    const debugEnhancer =
      (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__({ trace: true });

    debugEnhancer && storeEnhancers.push(debugEnhancer);
  }

  const store = {
    ...createStore(rootReducer(history), ROOT_INITIAL_STATE, compose(...storeEnhancers)),
    startAbortableSaga: () => SagaManager.startSaga(sagaMiddleware),
  };

  // enable hot reload
  // @ts-ignore
  if (IS_DEV && module.hot) {
    // @ts-ignore
    module.hot.accept('./rootReducer', () =>
      store.replaceReducer(require('./rootReducer').default)
    );

    // @ts-ignore
    module.hot.accept('./rootSaga', () => {
      SagaManager.cancelSaga(store);
      require('./rootSaga').default.startSaga(sagaMiddleware); // eslint-disable-line global-require
    });
  }

  return store;
};

const store = configStore();

store.startAbortableSaga();

export default store;
