import { Button } from '@vkontakte/vkui';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';
import { GameId } from 'src/store/game/types';
import { vkActions } from 'src/store/vk/reducer';
import { ModalName } from 'src/store/vk/types';
import styled from 'styled-components';

type SelectCharacterProps = WrappedFieldProps & {
  variants: string[];
  size?: [number, number];
  backgroundSize?: 'cover' | 'contain';
  title: string;
  className?: string;
  frameAmont?: number;
  gameId: GameId;
  animationName?: string;
};

const ModalContent = (props: any) => {
  const { input, frameAmont, variants, size, backgroundSize, gameId, animationName } = props;

  const [selected, setSelected] = useState(input.value);
  const [frame, setFrame] = useState(0);
  const modalReqId = useRef<number>(0);

  useEffect(() => {
    const changeFrame = () => {
      setFrame((value) => {
        return value === frameAmont - 1 ? 0 : value + 1;
      });
    };

    if (!modalReqId.current) {
      modalReqId.current = setInterval(changeFrame, 70);
    }
  }, [frameAmont]);

  useEffect(() => {
    return () => {
      clearInterval(modalReqId.current);
    };
  }, []);

  return (
    <VariantsList>
      {variants &&
        variants.map((variant: string) => {
          const frames = new Array(frameAmont)
            .fill(1)
            .map(
              (_, index) =>
                `https://cdn2.embedgames.app/${gameId}/assets/${variant}_${animationName}_${index}.png`
            );

          return (
            <PreviewWrap
              key={variant}
              selected={variant === selected}
              width={size[0] / 2}
              height={size[1] / 2}
              className="item"
              onClick={() => {
                input.onChange(variant);
                setSelected(variant);
              }}
            >
              {frames.map((src, index) => (
                <Preview
                  key={index}
                  backgroundSize={backgroundSize}
                  style={{
                    backgroundImage: `url(${src})`,
                    opacity: index === frame ? 1 : 0,
                  }}
                />
              ))}
            </PreviewWrap>
          );
        })}
    </VariantsList>
  );
};

export const SelectCharacter: FC<SelectCharacterProps> = ({
  input,
  size = [150, 150],
  backgroundSize = 'contain',
  variants,
  title,
  className,
  frameAmont = 5,
  gameId,
  animationName = 'run',
}) => {
  const [frame, setFrame] = useState(0);

  const reqId = useRef<number>(0);

  const dispatch = useDispatch();

  useEffect(() => {
    const changeFrame = () => {
      setFrame((value) => {
        return value === frameAmont - 1 ? 0 : value + 1;
      });
    };

    if (!reqId.current) {
      reqId.current = setInterval(changeFrame, 70);
    }
  }, [frameAmont]);

  useEffect(() => {
    return () => {
      clearInterval(reqId.current);
    };
  }, []);

  const handleChangeButtonClick = () => {
    dispatch(vkActions.setActiveModal({ modal: ModalName.MAIN }));
    dispatch(
      vkActions.setModalContent({
        content: ModalContent,
        title,
        props: {
          input,
          frameAmont,
          variants,
          size,
          backgroundSize,
          gameId,
          animationName,
        },
      })
    );
  };

  const frames = new Array(frameAmont)
    .fill(1)
    .map(
      (_, index) =>
        `https://cdn2.embedgames.app/${gameId}/assets/${input.value}_${animationName}_${index}.png`
    );

  return (
    <FieldWrap className={className}>
      <PreviewWrap width={size[0]} height={size[1]}>
        {frames.map((src, index) => (
          <Preview
            key={index}
            backgroundSize={backgroundSize}
            style={{
              backgroundImage: `url(${src})`,
              opacity: index === frame ? 1 : 0,
            }}
          />
        ))}
      </PreviewWrap>

      <Button onClick={handleChangeButtonClick}>Изменить</Button>
    </FieldWrap>
  );
};

const FieldWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PreviewWrap = styled.div<{
  width: number;
  height: number;
  selected?: boolean;
}>`
  position: relative;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  margin-bottom: 15px;
  ${(p) => p.selected && `box-shadow: 0 0 2px 2px #4986cc`};
`;

const Preview = styled.div<{
  backgroundSize: string;
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: ${(p) => p.backgroundSize};
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 15px;
`;

const VariantsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .item {
    margin: 12px;
  }
`;
