export const generateTimeZones = (): Array<{ value: string; label: string }> => {
  const result: string[] = [];

  for (let index = -12; index < 13; index++) {
    const h = `00${Math.abs(index)}`.slice(-2);
    result.push(`${index < 0 ? '-' : '+'}${h}:00`);
  }

  return result.map((tz) => ({ value: tz, label: tz === '+03:00' ? `${tz} (Москва)` : tz }));
};
