import React from 'react';
import cn from 'classnames';

import { Modal } from 'src/components/Modal';

import styles from './gift.module.css';

type Props = {
  autoOpenDelay?: number;
  imageUrl: string;
  giftPosition?: string;
  giftPositionMobile?: string;
  modalWithTransform: boolean;
  isLocked: () => boolean;
};

export const GiftComponent: React.FC<Props> = ({
  children,
  autoOpenDelay,
  imageUrl,
  giftPosition = 'left',
  giftPositionMobile = 'left',
  modalWithTransform,
  isLocked,
}) => {
  const [gameModalVisible, setGameModalVisible] = React.useState(false);
  const [giftVisible, setGiftVisible] = React.useState(true);
  const openedOnce = React.useRef(false);
  const timerId = React.useRef(0);

  const openGame = React.useCallback(() => {
    setGameModalVisible(true);
    setGiftVisible(false);
    openedOnce.current = true;
  }, []);

  const handleModalClose = React.useCallback(() => {
    setGameModalVisible(false);

    if (!isLocked()) {
      setGiftVisible(true);
    }
  }, [isLocked]);

  React.useEffect(() => {
    if (autoOpenDelay) {
      timerId.current = setTimeout(() => {
        if (!openedOnce.current) {
          openGame();
        }
      }, autoOpenDelay);
    }

    return () => {
      clearTimeout(timerId.current);
    };
  }, [autoOpenDelay, openGame]);

  return (
    <>
      <div
        className={cn(
          styles.giftShadow,
          {
            [styles.giftHidden]: !giftVisible,
          },
          styles[`mobile-${giftPositionMobile}`],
          styles[`desktop-${giftPosition}`]
        )}
      />

      <div
        className={cn(
          styles.gift,
          {
            [styles.giftHidden]: !giftVisible,
          },
          styles[`mobile-${giftPositionMobile}`],
          styles[`desktop-${giftPosition}`]
        )}
        onClick={openGame}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />

      <Modal
        onClose={handleModalClose}
        visible={gameModalVisible}
        withTransform={modalWithTransform}
      >
        {typeof children === 'function'
          ? children({
              close: handleModalClose,
            })
          : children}
      </Modal>
    </>
  );
};
