import React, { FC, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';

import { BoxSettings } from 'src/games/common/types';
import { BoxGame } from 'src/games';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/core/rootReducer';
import { MyGamesInitState } from 'src/store/my-games/reducers';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { getStringParams, getVkQuery } from 'src/vk-app/utils/query';
import { TargetsInitState } from 'src/store/targets/reducers';
import { Panels, Views } from 'src/store/vk/types';
import { getVkResult } from 'src/games/ep/fetchers';

import { Result as ResultWrap } from '../common';

type Props = {};

export const Result: FC<Props> = () => {
  const myGames = useSelector<RootState, MyGamesInitState>((state) => state.myGames);
  const targets = useSelector<RootState, TargetsInitState>((state) => state.targets);
  const {
    gameSettings: { mainSettingsId },
  } = useSelector<RootState, VKInitialState>((state) => state.vk);

  const settings = myGames.data.find(
    (data) => data.mainGameSettings.id === mainSettingsId
  ) as BoxSettings;

  const { vk_group_id, sign } = getVkQuery();

  const currentTarget = targets.data.targets.find(
    (t) => t.targetType === 'VK_CLUB' && t.vkClubID === `${vk_group_id}`
  );

  const dispatch = useDispatch();

  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);

  useEffect(() => {
    if (vkData.requestSave) {
      dispatch(vkActions.setPanel({ panel: Panels[Views.MY_GAMES]['GAME'] }));
    }
  }, [dispatch, vkData.requestSave]);

  return (
    <ResultWrap
      Game={
        <BoxGame
          lockGame={() => {}}
          getResult={getVkResult({
            mainGameSettingsId: settings.mainGameSettingsId,
            stringParams: getStringParams().stringParams,
            sign,
            isDemoPreview: true,
          })}
          settings={settings}
          bridge={bridge}
          sendAnalytics={(params) =>
            new Promise((resolve, reject) => {
              console.log('send analytics ====>', params);
              resolve({});
            })
          }
          spaceId={currentTarget?.targetUrl}
        />
      }
    ></ResultWrap>
  );
};
