import { useRef, useCallback, useEffect } from 'react';

export const useWindowResize = (callback: () => void) => {
  const running = useRef(false);

  const throttled = useCallback(() => {
    if (!running.current) {
      running.current = true;

      requestAnimationFrame(() => {
        callback();
        running.current = false;
      });
    }
  }, [callback]);

  useEffect(() => {
    window.addEventListener('resize', throttled);

    return () => {
      window.removeEventListener('resize', throttled);
    };
  }, [throttled]);
};
