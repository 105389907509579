import { Prize } from '../common/types';

const MIN_SLIDES_LENGTH = 5;

export const getSlides = (prizes: Prize[]): Prize[] => {
  const repeats = Math.ceil(MIN_SLIDES_LENGTH / prizes.length);

  if (repeats === 1) {
    return prizes;
  }

  let slides = prizes;

  for (let i = 0; i < repeats - 1; i++) {
    slides = slides.concat(prizes);
  }

  return slides;
};
