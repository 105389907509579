import React, { FC, useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Div, FormLayout, FormLayoutGroup, ScreenSpinner, Separator } from '@vkontakte/vkui';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';

import { RootState } from 'src/core/rootReducer';
import { BaseGame, WheelSettings } from 'src/games/common/types';
import { ColorSelect, GameStyleSelect, ImageSelect } from 'src/vk-app/components';
import { myGamesActions } from 'src/store/my-games/actions';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { AllGamesInitState } from 'src/store/game/reducers/allGamesReducer';
import { GameId } from 'src/store/game/types';

import styles from 'src/games/common/game-wrap/index.module.css';
import { usePrevious } from 'src/core/hooks/usePrevious';
import { config } from 'src/config/config';
import { nyBackgrounds } from '../common/design';

type Props = {
  initialValues: WheelSettings;
};

const Design: FC<Props> = () => {
  const values = useSelector<RootState>((state) => state.form.game?.values) as WheelSettings;
  const dispatch = useDispatch();
  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);
  const formSelector = formValueSelector('game');

  const mainGameSettingsId = useSelector<RootState, number>((state) =>
    formSelector(state, 'mainGameSettingsId')
  );

  const handleSubmit = useCallback(() => {
    dispatch(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));
    dispatch(myGamesActions.saveSettings.started({ gameId: values.gameId }));
  }, [dispatch, values.gameId]);

  const games = useSelector<RootState, AllGamesInitState>((state) => state.games.allGames);

  const gameInfo = games.data.find((game) => game.gameId === GameId.WHEEL);

  const prevRequestSave = usePrevious(vkData.requestSave);

  const slotsCount = useSelector<RootState, number>((state) => formSelector(state, 'slotsCount'));
  const slotsColors: string[] = useSelector<RootState, string[]>((state) =>
    formSelector(state, 'slotsColors')
  );
  const slotsTextColor = useSelector<RootState, string[]>((state) =>
    formSelector(state, 'slotsTextColor')
  );

  const view = useSelector<RootState, BaseGame['view']>((state) => formSelector(state, 'view'));
  const secondSectionBgColor = useSelector<RootState, string>((state) =>
    formSelector(state, 'secondSectionBgColor')
  );
  const backgroundColor = useSelector<RootState, string>((state) =>
    formSelector(state, 'backgroundColor')
  );

  const textPreviewBgColor = view === 'classic' ? secondSectionBgColor : backgroundColor;

  useEffect(() => {
    if (vkData.requestSave && !prevRequestSave) {
      handleSubmit();
    }
  }, [vkData.requestSave, handleSubmit, prevRequestSave]);

  const onColorChange = useCallback(
    ({ hex, indexes }: { hex: string; indexes: number[] }) => {
      const newColors = [...slotsColors];

      indexes.forEach((index) => {
        newColors[index] = hex;
      });

      dispatch(change('game', 'slotsColors', newColors));
    },
    [dispatch, slotsColors]
  );

  const onTextColorChange = useCallback(
    ({ hex, indexes }: { hex: string; indexes: number[] }) => {
      const newColors = [...slotsTextColor];

      indexes.forEach((index) => {
        newColors[index] = hex;
      });

      dispatch(change('game', 'slotsTextColor', newColors));
    },
    [dispatch, slotsTextColor]
  );

  return (
    <Wrap>
      <FormLayout>
        <Field
          top="Цвет заголовка"
          name="titleColor"
          component={ColorSelect}
          TextPreview={
            <TitlePreview color={values.titleColor} backgroundColor={textPreviewBgColor}>
              {values.titleText}
            </TitlePreview>
          }
        />

        <Field
          top="Цвет подзаголовка"
          name="subtitleColor"
          component={ColorSelect}
          TextPreview={
            <SubtitlePreview color={values.subtitleColor} backgroundColor={textPreviewBgColor}>
              {values.subtitleText}
            </SubtitlePreview>
          }
        />

        <Separator />

        <Field name="buttonColor" component={ColorSelect} top="Цвет кнопки" />

        <Field
          top="Цвет текста в кнопке"
          name="buttonTextColor"
          component={ColorSelect}
          TextPreview={
            <Div>
              <button
                style={{ color: values.buttonTextColor, backgroundColor: values.buttonColor }}
                className={styles.button}
              >
                {values.buttonText}
              </button>
            </Div>
          }
        />

        <Separator />

        <Field
          name="slotsColors[0]"
          component={ColorSelect}
          top="Цвет слотов #1"
          // @ts-ignore
          onChange={(hex: string) => {
            switch (slotsCount) {
              case 12:
                onColorChange({ hex, indexes: [0, 4, 8] });
                break;
              case 10:
                onColorChange({ hex, indexes: [0, 2, 4, 6, 8] });
                break;
              case 8:
                onColorChange({ hex, indexes: [0, 2, 4, 6] });
                break;
              case 6:
                onColorChange({ hex, indexes: [0, 2, 4] });
                break;
              case 4:
                onColorChange({ hex, indexes: [0, 2] });
                break;
            }
          }}
        />
        <Field
          name="slotsColors[1]"
          component={ColorSelect}
          top="Цвет слотов #2"
          // @ts-ignore
          onChange={(hex: string) => {
            switch (slotsCount) {
              case 12:
                onColorChange({ hex, indexes: [1, 3, 5, 7, 9, 11] });
                break;
              case 10:
                onColorChange({ hex, indexes: [1, 3, 5, 7, 9] });
                break;
              case 8:
                onColorChange({ hex, indexes: [1, 3, 5, 7] });
                break;
              case 6:
                onColorChange({ hex, indexes: [1, 3, 5] });
                break;
              case 4:
                onColorChange({ hex, indexes: [1, 3] });
                break;
            }
          }}
        />

        {slotsCount === 12 && (
          <Field
            name="slotsColors[2]"
            component={ColorSelect}
            top="Цвет слотов #3"
            // @ts-ignore
            onChange={(hex: string) => {
              switch (slotsCount) {
                case 12:
                  onColorChange({ hex, indexes: [2, 6, 10] });
                  break;
              }
            }}
          />
        )}

        <Separator />

        <Field
          name="slotsTextColor[0]"
          component={ColorSelect}
          top="Цвет текста слотов #1"
          // @ts-ignore
          onChange={(hex: string) => {
            switch (slotsCount) {
              case 12:
                onTextColorChange({ hex, indexes: [0, 4, 8] });
                break;
              case 10:
                onTextColorChange({ hex, indexes: [0, 2, 4, 6, 8] });
                break;
              case 8:
                onTextColorChange({ hex, indexes: [0, 2, 4, 6] });
                break;
              case 6:
                onTextColorChange({ hex, indexes: [0, 2, 4] });
                break;
              case 4:
                onTextColorChange({ hex, indexes: [0, 2] });
                break;
            }
          }}
        />

        <Field
          name="slotsTextColor[1]"
          component={ColorSelect}
          top="Цвет текста слотов #2"
          // @ts-ignore
          onChange={(hex: string) => {
            switch (slotsCount) {
              case 12:
                onTextColorChange({ hex, indexes: [1, 3, 5, 7, 9, 11] });
                break;
              case 10:
                onTextColorChange({ hex, indexes: [1, 3, 5, 7, 9] });
                break;
              case 8:
                onTextColorChange({ hex, indexes: [1, 3, 5, 7] });
                break;
              case 6:
                onTextColorChange({ hex, indexes: [1, 3, 5] });
                break;
              case 4:
                onTextColorChange({ hex, indexes: [1, 3] });
                break;
            }
          }}
        />

        {slotsCount === 12 && (
          <Field
            name="slotsTextColor[2]"
            component={ColorSelect}
            top="Цвет текста слотов #3"
            // @ts-ignore
            onChange={(hex: string) => {
              switch (slotsCount) {
                case 12:
                  onTextColorChange({ hex, indexes: [2, 6, 10] });
                  break;
              }
            }}
          />
        )}

        <Separator />

        <Field name="lampColor" component={ColorSelect} top="Цвет лампочек колеса" />
        <Field name="kantColor" component={ColorSelect} top="Цвет ободка колеса" />
        <Field name="pointerColor" component={ColorSelect} top="Цвет указателя" />

        <Separator />

        <FormLayoutGroup top="Внешний вид">
          <GameStyleSelect />
        </FormLayoutGroup>

        <FormLayoutGroup top="Цвет фона">
          <Field
            name={view === 'classic' ? 'secondSectionBgColor' : 'backgroundColor'}
            component={ColorSelect}
            top="Цвет фона"
          />
        </FormLayoutGroup>

        <FormLayoutGroup top="Фон">
          <Field
            name="backgroundImgSrc"
            component={ImageSelect}
            variants={[...(gameInfo?.backgroundVariants || []), ...nyBackgrounds]}
            title="Выберите фон"
            className="select-background"
            size={[150, 150]}
            uploadUrl={`${config.api}/vk/upload-game-background/${mainGameSettingsId}`}
            setPreviewSrc={(src: string) => {
              dispatch(change('game', 'backgroundImgSrc', src));
            }}
            backgroundSize="cover"
            canUpload={true}
            disabled={view === 'mono'}
          />
        </FormLayoutGroup>
      </FormLayout>
    </Wrap>
  );
};

const Wrap = styled.div`
  .select-background {
    margin: 0 12px;
  }
`;

const TitlePreview = styled.div<{ color?: string; backgroundColor?: string }>`
  margin: 0 12px;
  font-size: 24px;
  font-weight: bold;
  color: ${(p) => p.color};
  background-color: ${(p) => p.backgroundColor};
`;

const SubtitlePreview = styled.div<{ color?: string; backgroundColor?: string }>`
  margin: 0 12px;
  font-size: 16px;
  line-height: 20px;
  color: ${(p) => p.color};
  background-color: ${(p) => p.backgroundColor};
`;

const withForm = reduxForm<any, any, any>({
  form: 'game',
})(Design);

const withStore = connect((state: RootState) => {
  const { mainSettingsId } = state.vk.gameSettings;

  return {
    initialValues: state.myGames.data.find(
      (game: any) => game.mainGameSettings.id === mainSettingsId
    ),
  };
}, {})(withForm);

export { withStore as Design };
