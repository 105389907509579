import React from 'react';

export type Props = {
  onAnimationEnd?: () => void;
  kantColor: string;
  lampColor: string;
  className?: string;
};

const Wheel = React.forwardRef<SVGSVGElement, Props>(
  ({ onAnimationEnd, kantColor, lampColor, className }, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      viewBox="0 0 1000 1000"
      className={className}
      onTransitionEnd={onAnimationEnd}
    >
      <g>
        <path
          fill={kantColor}
          className="kant"
          d="M953.135 612.577c19.166-76.781 18.064-154.023.005-225.978a466.162 466.162 0 00-4.259-15.782c-21.174-73.367-60.117-140.659-113.187-195.577a465.59 465.59 0 00-11.551-11.542c-53.384-51.503-119.801-90.949-195.938-112.593a478.146 478.146 0 00-6.958-1.927 470.822 470.822 0 00-8.666-2.225c-76.812-19.041-154.067-17.815-226.011.374a464.223 464.223 0 00-15.481 4.203c-73.366 21.26-140.638 60.293-195.512 113.453a464.139 464.139 0 00-11.191 11.216c-51.512 53.417-90.958 119.875-112.581 196.06a464.532 464.532 0 00-1.872 6.767 470.32 470.32 0 00-2.145 8.339c-19.105 76.838-17.921 154.127.237 226.108a462.11 462.11 0 004.075 15.059c21.161 73.424 60.115 140.77 113.216 195.726a463.579 463.579 0 0011.036 11.049c53.336 51.619 119.736 91.182 195.877 112.934 2.508.716 5.023 1.421 7.553 2.099 2.53.678 5.06 1.325 7.591 1.959 76.806 19.229 154.081 18.171 226.072.141a461.496 461.496 0 0015.364-4.13c73.425-21.076 140.789-59.938 195.789-112.949a464.825 464.825 0 0011.401-11.373c51.608-53.303 91.171-119.661 112.945-195.754.736-2.572 1.459-5.15 2.154-7.744a491.336 491.336 0 002.037-7.913z"
        />
        <path
          id="slot06"
          d="M528.734 394.442L614.065 75.98c-77.282-20.708-155.273-19.428-227.34-.005l85.346 318.514c17.956-4.869 37.397-5.209 56.663-.047z"
        />
        <path
          id="slot05"
          d="M577.782 422.814L810.95 189.646c-52.7-52.854-119.603-92.958-196.885-113.666l-85.331 318.462c19.265 5.162 35.932 15.177 49.048 28.372z"
        />
        <path
          id="slot03"
          d="M924.462 386.572l-318.513 85.345c4.714 17.788 4.986 37.011-.118 56.061-.04.147-.084.293-.124.439l318.465 85.333c.039-.147.081-.293.121-.44 20.65-77.066 19.436-154.839.169-226.738z"
        />
        <path
          id="slot04"
          d="M605.949 471.917l318.513-85.345c-19.926-74.353-59.165-142.42-113.512-196.926L577.782 422.814a109.58 109.58 0 0128.167 49.103z"
        />
        <path
          id="slot07"
          d="M472.071 394.489L386.725 75.975c-74.319 20.029-142.335 59.358-196.767 113.787l233.168 233.167a109.573 109.573 0 0148.945-28.44z"
        />
        <path
          id="slot01"
          d="M528.517 605.152l85.346 318.511c74.236-19.925 142.199-59.104 196.647-113.351L577.34 577.144a109.588 109.588 0 01-48.823 28.008z"
        />
        <path
          id="slot02"
          d="M577.34 577.144l233.17 233.168c52.811-52.618 92.905-119.409 113.663-196.562l-318.465-85.333c-5.212 19.136-15.216 35.694-28.368 48.727z"
        />
        <path
          id="slot00"
          d="M472.294 605.077l-85.331 318.461c77.124 20.666 154.955 19.435 226.9.126l-85.346-318.512c-17.832 4.756-37.115 5.045-56.223-.075z"
        />
        <text
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          id="text_slot00"
          fill="#fff"
          fontStyle="normal"
          fontWeight="700"
          transform="matrix(0,-1,1,0,508.203,937)"
        ></text>
        <text
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          id="text_slot06"
          fill="#fff"
          fontStyle="normal"
          fontWeight="700"
          transform="matrix(0,1,-1,0,491.797,63)"
        ></text>
        <text
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          id="text_slot05"
          fill="#fff"
          fontStyle="normal"
          fontWeight="700"
          transform="matrix(0.5,-0.866,0.866,0.5,566.219,407.602)"
        ></text>
        <text
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          id="text_slot04"
          fill="#fff"
          fontStyle="normal"
          fontWeight="700"
          transform="matrix(0.866,-0.5,0.5,0.866,601.602,453.989)"
        ></text>
        <path
          id="slot11"
          d="M472.294 605.077c-19.108-5.12-35.663-15.011-48.729-28.046L190.399 810.196c52.651 52.695 119.439 92.676 196.564 113.341l85.331-318.46z"
        />
        <path
          id="slot08"
          d="M395.197 471.539c5.105-19.05 14.951-35.563 27.929-48.61L189.958 189.762c-52.636 52.632-92.573 119.379-113.223 196.447l-.115.441 318.465 85.332c.038-.148.073-.295.112-.443z"
        />
        <path
          id="slot09"
          d="M395.085 471.981L76.62 386.649c-20.6 77.195-19.274 155.085.154 227.06l318.515-85.346c-4.875-17.863-5.259-37.204-.204-56.382z"
        />
        <path
          id="slot10"
          d="M395.288 528.363L76.774 613.709c20.029 74.202 59.297 142.113 113.625 196.487L423.566 577.03a109.577 109.577 0 01-28.278-48.667z"
        />
        <text
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          id="text_slot11"
          fill="#fff"
          fontStyle="normal"
          fontWeight="700"
          transform="matrix(0.5,-0.866,0.866,0.5,289.99,881.926)"
        ></text>
        <text
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          id="text_slot10"
          fill="#fff"
          fontStyle="normal"
          fontWeight="700"
          transform="matrix(0.866,-0.5,0.5,0.866,128.277,725.219)"
        ></text>
        <text
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          id="text_slot09"
          fill="#fff"
          fontStyle="normal"
          fontWeight="700"
          transform="matrix(1,0,0,1,65,509.203)"
        ></text>
        <text
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          id="text_slot08"
          fill="#fff"
          fontStyle="normal"
          fontWeight="700"
          transform="matrix(0.866,0.5,-0.5,0.866,116.398,290.989)"
        ></text>
        <text
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          id="text_slot07"
          fill="#fff"
          fontStyle="normal"
          fontWeight="700"
          transform="matrix(0.5,0.866,-0.866,0.5,275.781,125.277)"
        ></text>
        <text
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          id="text_slot03"
          fill="#fff"
          fontStyle="normal"
          fontWeight="700"
          transform="matrix(1,0,0,1,613,509.203)"
        ></text>
        <text
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          id="text_slot02"
          fill="#fff"
          fontStyle="normal"
          fontWeight="700"
          transform="matrix(0.866,0.5,-0.5,0.866,593.398,562.219)"
        ></text>
        <text
          style={{ whiteSpace: 'pre', fontSize: '30px' }}
          id="text_slot01"
          fill="#fff"
          fontStyle="normal"
          fontWeight="700"
          transform="matrix(0.5,0.866,-0.866,0.5,547.011,599.602)"
        ></text>
        <path
          fill={kantColor}
          className="kant"
          d="M605.949 471.917a109.573 109.573 0 00-28.168-49.103c-13.116-13.195-29.783-23.21-49.048-28.372-19.266-5.162-38.707-4.824-56.663.047a109.558 109.558 0 00-48.946 28.44c-12.977 13.048-22.824 29.56-27.929 48.61-.039.147-.074.295-.112.442-5.055 19.178-4.67 38.517.204 56.382a109.577 109.577 0 0028.277 48.667c13.066 13.036 29.622 22.925 48.728 28.045 19.107 5.12 38.391 4.832 56.224.075a109.552 109.552 0 0048.821-28.008c13.153-13.033 23.156-29.59 28.368-48.726.04-.147.085-.291.124-.439 5.106-19.049 4.835-38.272.12-56.06zm-105.553 28.282z"
        />
        <path
          className="lamp"
          fill={lampColor}
          d="M609.381 59.681a7.879 7.879 0 019.645-5.568 7.879 7.879 0 015.568 9.645 7.878 7.878 0 01-9.645 5.568 7.878 7.878 0 01-5.568-9.645z"
        />
        <path
          className="lamp"
          fill={lampColor}
          d="M375.406 936.242a7.878 7.878 0 019.645-5.568 7.878 7.878 0 015.568 9.645 7.879 7.879 0 01-9.645 5.568 7.879 7.879 0 01-5.568-9.645z"
        />
        <path
          className="lamp"
          fill={lampColor}
          d="M374.568 59.906a7.879 7.879 0 019.645-5.569 7.879 7.879 0 015.568 9.645 7.88 7.88 0 01-9.645 5.569 7.879 7.879 0 01-5.568-9.645z"
        />
        <path
          className="lamp"
          fill={lampColor}
          d="M610.22 936.018a7.88 7.88 0 019.645-5.569 7.879 7.879 0 015.568 9.645 7.878 7.878 0 01-9.645 5.569 7.879 7.879 0 01-5.568-9.645z"
        />
        <path
          className="lamp"
          fill={lampColor}
          d="M171.325 177.507a7.88 7.88 0 019.645-5.569 7.879 7.879 0 015.568 9.645 7.878 7.878 0 01-9.644 5.569 7.879 7.879 0 01-5.569-9.645z"
        />
        <path
          className="lamp"
          fill={lampColor}
          d="M813.462 818.417a7.878 7.878 0 019.645-5.569 7.879 7.879 0 015.568 9.645 7.879 7.879 0 01-9.645 5.568 7.877 7.877 0 01-5.568-9.644z"
        />
        <path
          className="lamp"
          fill={lampColor}
          d="M54.113 380.974a7.879 7.879 0 019.645-5.568 7.878 7.878 0 015.568 9.645 7.878 7.878 0 01-9.645 5.568 7.879 7.879 0 01-5.568-9.645z"
        />
        <path
          className="lamp"
          fill={lampColor}
          d="M930.674 614.949a7.878 7.878 0 019.645-5.568 7.879 7.879 0 015.568 9.645 7.878 7.878 0 01-9.645 5.568 7.878 7.878 0 01-5.568-9.645z"
        />
        <path
          className="lamp"
          fill={lampColor}
          d="M54.337 615.787a7.879 7.879 0 019.645-5.568 7.879 7.879 0 015.569 9.645 7.879 7.879 0 01-9.645 5.568 7.88 7.88 0 01-5.569-9.645z"
        />
        <path
          className="lamp"
          fill={lampColor}
          d="M930.45 380.135a7.877 7.877 0 019.644-5.568 7.878 7.878 0 015.569 9.645 7.879 7.879 0 01-9.645 5.568 7.879 7.879 0 01-5.568-9.645z"
        />
        <path
          className="lamp"
          fill={lampColor}
          d="M171.94 819.03a7.877 7.877 0 019.644-5.568 7.879 7.879 0 015.569 9.645 7.879 7.879 0 01-9.645 5.568 7.878 7.878 0 01-5.568-9.645z"
        />
        <path
          fill={lampColor}
          className="lamp"
          d="M812.848 176.893a7.879 7.879 0 019.645-5.568 7.88 7.88 0 015.569 9.645 7.879 7.879 0 01-9.645 5.568 7.878 7.878 0 01-5.569-9.645z"
        />
        <path
          className="kant"
          fill={kantColor}
          d="M628.205 51.105c4.894-7.385 9.421-14.733 10.243-17.799 1.912-7.136-2.322-14.469-9.458-16.381-7.135-1.912-14.469 2.322-16.381 9.457-.822 3.07-.574 11.716-.026 20.57 2.889.716 5.777 1.451 8.665 2.225 2.329.625 4.646 1.271 6.957 1.928z"
        />
        <path
          className="kant"
          fill={kantColor}
          d="M372.228 948.243c-5.059 7.6-9.83 15.296-10.676 18.451-1.912 7.136 2.322 14.469 9.458 16.381 7.135 1.912 14.469-2.322 16.381-9.457.845-3.156.561-12.206-.02-21.318-2.53-.633-5.061-1.28-7.59-1.958a485.017 485.017 0 01-7.553-2.099z"
        />
        <path
          className="kant"
          fill={kantColor}
          d="M386.552 26.607c-1.911-7.135-9.245-11.369-16.381-9.458-7.135 1.912-11.369 9.246-9.458 16.381.829 3.093 5.429 10.548 10.375 18a461.811 461.811 0 0115.481-4.203c.556-8.907.811-17.633-.017-20.72z"
        />
        <path
          className="kant"
          fill={kantColor}
          d="M613.448 973.393c1.911 7.135 9.245 11.369 16.381 9.458 7.135-1.912 11.369-9.246 9.458-16.381-.835-3.116-5.494-10.653-10.48-18.158a468.868 468.868 0 01-15.363 4.13c-.564 8.987-.83 17.837.004 20.951z"
        />
        <path
          className="kant"
          fill={kantColor}
          d="M165.055 146.753c-5.223-5.223-13.692-5.223-18.916.001-5.222 5.223-5.223 13.691.001 18.915 2.289 2.29 10.143 6.51 18.246 10.531a469.666 469.666 0 0111.191-11.216c-4.017-8.097-8.234-15.943-10.522-18.231z"
        />
        <path
          className="kant"
          fill={kantColor}
          d="M834.945 853.247c5.223 5.223 13.692 5.223 18.916-.001 5.224-5.223 5.223-13.691-.001-18.915-2.253-2.253-9.898-6.377-17.863-10.34a470.19 470.19 0 01-11.401 11.373c3.965 7.972 8.095 15.627 10.349 17.883z"
        />
        <path
          className="kant"
          fill={kantColor}
          d="M51.804 372.259c-7.616-5.07-15.336-9.86-18.498-10.707-7.136-1.912-14.469 2.322-16.381 9.458-1.912 7.135 2.322 14.469 9.457 16.381 3.167.848 12.264.559 21.405-.025.691-2.781 1.4-5.56 2.145-8.339.606-2.265 1.234-4.52 1.872-6.768z"
        />
        <path
          className="kant"
          fill={kantColor}
          d="M973.618 612.609c-3.059-.819-11.658-.577-20.483-.032a466.953 466.953 0 01-2.039 7.916 474.304 474.304 0 01-2.154 7.743c7.37 4.883 14.694 9.392 17.753 10.212 7.136 1.912 14.469-2.322 16.381-9.457 1.911-7.136-2.323-14.47-9.458-16.382z"
        />
        <path
          className="kant"
          fill={kantColor}
          d="M26.607 613.447c-7.135 1.912-11.369 9.246-9.458 16.382 1.912 7.135 9.246 11.369 16.381 9.458 3.172-.85 10.929-5.667 18.568-10.754a467.234 467.234 0 01-4.074-15.059c-9.145-.585-18.249-.875-21.417-.027z"
        />
        <path
          className="kant"
          fill={kantColor}
          d="M973.393 386.552c7.135-1.911 11.369-9.245 9.458-16.381-1.911-7.136-9.246-11.369-16.381-9.458-3.036.814-10.275 5.262-17.588 10.103a468.515 468.515 0 014.258 15.782c8.743.534 17.22.767 20.253-.046z"
        />
        <path
          className="kant"
          fill={kantColor}
          d="M146.753 834.945c-5.223 5.223-5.223 13.692.001 18.916 5.223 5.222 13.691 5.223 18.915-.001 2.318-2.318 6.617-10.343 10.682-18.552a467.347 467.347 0 01-11.036-11.049c-8.213 4.067-16.243 8.367-18.562 10.686z"
        />
        <path
          className="kant"
          fill={kantColor}
          d="M853.247 165.055c5.223-5.223 5.223-13.692 0-18.916-5.224-5.222-13.692-5.223-18.916.001-2.225 2.224-6.272 9.704-10.188 17.558a467.32 467.32 0 0111.551 11.542c7.852-3.915 15.329-7.962 17.553-10.185z"
        />
      </g>
    </svg>
  )
);

export default Wheel;
