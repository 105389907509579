import {
  Button,
  Cell,
  FormLayout,
  FormLayoutGroup,
  FormStatus,
  ScreenSpinner,
  Switch,
} from '@vkontakte/vkui';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { FieldArray, formValueSelector, reduxForm, change, Field } from 'redux-form';
import styled from 'styled-components';
import { usePrevious } from '@alfalab/hooks';

import { RootState } from 'src/core/rootReducer';
import { WheelSettings } from 'src/games/common/types';
import { myGamesActions } from 'src/store/my-games/actions';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { Promocodes as PromocodesList, Select } from 'src/vk-app/components';
import { Panels, Views } from 'src/store/vk/types';

const Promocodes = (props: any) => {
  const values = useSelector<RootState>((state) => state.form.game?.values) as WheelSettings;
  const dispatch = useDispatch();
  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);

  const formSelector = formValueSelector('game');
  const prizes =
    useSelector<RootState, WheelSettings['prizes']['prizes']>((state) =>
      formSelector(state, 'prizes.prizes')
    ) || [];

  const slotsCount = useSelector<RootState, number>((state) => formSelector(state, 'slotsCount'));

  const [formErrorText, setFormErrorText] = useState('');

  const chances = prizes && prizes.map((prize) => prize.chance);

  const [chancesEqual, setChancesEqual] = useState(
    chances && chances.length > 1 ? new Set(chances.filter((pc) => !!pc)).size === 1 : false
  );

  const getSumm = useCallback(() => {
    const chances = prizes.reduce((acc, prize) => {
      acc += +prize.chance;

      return acc;
    }, 0);

    return chances;
  }, [prizes]);

  const updateChances = (count: number) => {
    const pc = Math.round((100 / count) * 100) / 100;

    for (let index = 0; index < 12; index++) {
      dispatch(change('game', `prizes.prizes[${index}].chance`, index < count ? pc : 0));
    }
  };

  const handleSubmit = useCallback(() => {
    const summ = getSumm();
    const isNan = isNaN(summ);

    if (summ >= 99.5 && summ <= 100.5 && !isNan) {
      dispatch(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));
      dispatch(myGamesActions.saveSettings.started({ gameId: values.gameId }));
    } else {
      setFormErrorText(isNan ? 'Сейчас у вас ошибка в вероятностях' : `Сейчас у вас ${summ.toFixed(2)}%.`);

      window.scrollTo({ top: 0, behavior: 'smooth' });
      dispatch(vkActions.requestSaveForm({ save: false }));
    }
  }, [dispatch, getSumm, values.gameId]);

  const handleChancesEqualChange = useCallback(() => {
    setChancesEqual((val) => !val);

    const pc = Math.round((100 / slotsCount) * 100) / 100;

    for (let index = 0; index < 12; index++) {
      dispatch(change('game', `prizes.prizes[${index}].chance`, index < slotsCount ? pc : 0));
    }
  }, [dispatch, slotsCount]);

  const prevRequestSave = usePrevious(vkData.requestSave);

  useEffect(() => {
    if (vkData.requestSave && !prevRequestSave) {
      handleSubmit();
    }
  }, [vkData.requestSave, handleSubmit, prevRequestSave, props]);

  const handleCancelClick = useCallback(() => {
    dispatch(vkActions.setPanel({ panel: Panels[Views.MY_GAMES]['GAME'] }));
  }, [dispatch]);

  return (
    <Wrap>
      <FormLayout>
        {formErrorText && (
          <FormStatus header="Сумма вероятностей должна быть равна 100%" mode="error">
            {formErrorText}
            <br />
            <Button onClick={handleCancelClick} mode="destructive" className="cancel-button">
              Не сохранять
            </Button>
          </FormStatus>
        )}

        {!formErrorText && (
          <FormStatus header="Добавьте призы">
            Призом может быть промокод или ссылка. Сумма вероятностей должна быть равна 100% (±0.5%).
            <br/>
            Если у приза не заполнять поле «Промокод», то слот будет проигрышным
          </FormStatus>
        )}

        <Field
          name="slotsCount"
          component={Select}
          label="Количество слотов в колесе"
          options={[
            <option value="4" key="4">
              4
            </option>,
            <option value="6" key="6">
              6
            </option>,
            <option value="8" key="8">
              8
            </option>,
            <option value="10" key="10">
              10
            </option>,
            <option value="12" key="12">
              12
            </option>,
          ]}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            updateChances(+event.target.value);
          }}
        />

        <Cell asideContent={<Switch checked={chancesEqual} onChange={handleChancesEqualChange} />}>
          Все слоты равновероятны
        </Cell>

        <FormLayoutGroup top="Призы">
          <FieldArray
            name="prizes.prizes"
            editableAmount={false}
            chancesEqual={chancesEqual}
            // @ts-ignore
            component={PromocodesList}
            count={slotsCount}
          />
        </FormLayoutGroup>
      </FormLayout>
    </Wrap>
  );
};

const Wrap = styled.div`
  .cancel-button {
    margin: 0 !important;
    margin-top: 15px !important;
  }
`;

const withForm = reduxForm<any, any, any>({
  form: 'game',
})(Promocodes);

const withStore = connect((state: RootState) => {
  const { mainSettingsId } = state.vk.gameSettings;

  return {
    initialValues: state.myGames.data.find(
      (game: any) => game.mainGameSettings.id === mainSettingsId
    ),
  };
}, {})(withForm);

export { withStore as Promocodes };
