import React, { FC } from 'react';
import Slider from 'react-slick';

import { GameWrap } from '../common/game-wrap';
import { GameWrapProps } from '../common/types';
import { Box } from './Box';

type Props = Omit<GameWrapProps, 'GameContent'>;

const BoxGame: FC<Props> = (props) => {
  return <GameWrap {...props} GameContent={Box} Slider={Slider} />;
};

export { BoxGame };

export default BoxGame;
