import React, { Component } from 'react';
import { LuckyGame } from 'src/games';
import { GameId } from 'src/store/game/types';
import { GameResult } from 'src/games/common/types';
import { LuckySettings } from 'src/games/common/types';
import { config } from 'src/config/config';

type Props = {
  getResult: (gameId: GameId) => Promise<GameResult>;
};

const getResult = (): Promise<GameResult> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        win: true,
        slotValue: 'PRIZE_4',
        slotText: 'MacBook Pro',
        slotType: 'PROMO_CODE',
        prizeIndex: 3,
      });
    }, 500);
  });
};

const mockSettings: LuckySettings = {
  id: 11,
  userId: 5,
  titleText: 'Испытайте удачу!',
  subtitleText: 'Вращайте рулетку!',
  backgroundImgSrc: 'https://cdn2.embedgames.app/BOX/backgrounds/5/1-min.jpg',
  winTitle: 'Поздравляем! Ваш выигрыш: {prize}',
  winSubtitle: 'Держите промокод: {prize}',
  createdAt: '2020-07-08',
  failTitle: 'Удача сегодня не на вашей стороне :(',
  failSubtitle: '',
  updatedAt: '2020-07-08',
  gameId: GameId.BOX,
  targetId: 120,
  titleColor: '#fff',
  subtitleColor: '#969696',
  view: 'mono',
  backgroundColor: '#262738',
  mainGameSettingsId: 95,
  // @ts-ignore
  mainGameSettings: {
    id: 95,
    gameId: GameId.LUCKY,
    // collectDataType: 'PHONE',
    gameBreak: 24,
    userId: 5,
    targetId: 120,
    showDelay: 3,
    isPublish: false,
  },
  // @ts-ignore
  targetInfo: {
    vkClubID: config.vkClubId,
  },
  secondSectionBgColor: '#fff',
  buttonColor: '#4a61dd',
  buttonTextColor: '#fff',
  buttonText: 'Крутить рулетку',
  prizes: {
    prizes: [
      {
        value: 'PRIZE_1',
        text: 'Ювелирное украшение',
        type: 'PROMO_CODE',
        chance: 20,
        imageSrc: 'https://cdn2.embedgames.app/LUCKY/0ff2defe.png',
      },
      {
        value: 'PRIZE_2',
        text: 'Футболка с логотипом',
        type: 'PROMO_CODE',
        chance: 20,
        imageSrc: 'https://cdn2.embedgames.app/LUCKY/2bf5fe1c.png',
      },
      {
        value: 'PRIZE_3',
        type: 'PROMO_CODE',
        chance: 20,
        text: 'Samsung [4K]',
        imageSrc: 'https://cdn2.embedgames.app/LUCKY/7ab59df5.png',
      },
      {
        value: 'PRIZE_4',
        text: 'MacBook Pro',
        type: 'PROMO_CODE',
        chance: 20,
        imageSrc: 'https://cdn2.embedgames.app/LUCKY/9b6cb93a.png',
      },
      {
        value: 'PRIZE_5',
        text: 'iPhone 13',
        type: 'PROMO_CODE',
        chance: 20,
        imageSrc: 'https://cdn2.embedgames.app/LUCKY/82a1b8ff.png',
      },
    ],
  },
};

export default class LuckyDemo extends Component<Props> {
  render() {
    return (
      <LuckyGame
        {...this.props}
        getResult={getResult}
        lockGame={() => {}}
        settings={mockSettings}
        sendAnalytics={(params) =>
          new Promise((resolve, reject) => {
            console.log('send analytics ====>', params);
            resolve({});
          })
        }
        isDemo={true}
      />
    );
  }
}
