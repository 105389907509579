import React, { useCallback, useEffect, useState } from 'react';
import Bridge from '@vkontakte/vk-bridge';
import cn from 'classnames';
import axios from 'axios';

import { BaseGame } from '../types';
import { GameResult } from '../types/index';
import { getMsid, getStringParams, getVkQuery } from 'src/vk-app/utils/query';
import { config } from '../../../config/config';
import styles from './vk-result-screen.module.css';
import rsStyles from '../result-screen/result-screen.module.css';
import { Loader } from '@alfalab/core-components-loader';

type Props = {
  title: string;
  subtitle: string;
  visible: boolean;
  settings: BaseGame;
  result?: GameResult;
  bridge: typeof Bridge;
  spaceId: string;
  isDemo?: boolean;
  onGameOver: () => void;
  resultError: string;
  showJoinButton?: boolean;
};

export const VkResultScreen = ({
  title,
  subtitle,
  visible,
  settings: { targetInfo, gameId, mainGameSettingsId, mainGameSettings },
  result,
  bridge,
  spaceId,
  isDemo,
  resultError,
  showJoinButton,
  onGameOver,
}: Props) => {
  const style: React.CSSProperties = visible
    ? { zIndex: 2, opacity: 1, transform: 'scale(1)' }
    : { opacity: 0, transform: 'scale(0.7)' };

  const [loading, setLoading] = useState(false);
  const [prizeVisible, setPrizeVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [joinButtonVisible, setJoinButtonVisible] = useState(showJoinButton);
  const [turnOnMessages, setTurnOnMessages] = useState(false);
  const isCompetition = mainGameSettings.campaignType === 'competition';

  const { vk_viewer_group_role } = getVkQuery();

  const handleGetPrizeClick = () => {
    setLoading(true);

    if (targetInfo && targetInfo.vkClubID && result) {
      const { stringParams, sign } = getStringParams();
      const group_id = +targetInfo.vkClubID;

      bridge
        .send('VKWebAppAllowMessagesFromGroup', {
          group_id: isDemo ? +config.vkClubId : group_id,
        })
        .then(() => {
          const msid = getMsid();

          axios
            .post(`${config.gameApi}/send-prize-to-vk`, {
              stringParams,
              sign,
              isDemo,
              spaceId,
              gameId,
              msid: msid ?? undefined,
              mainGameSettingsId,
            })
            .then(() => {
              setSuccessMessage(
                isCompetition
                  ? 'Результат отправлен вам в личные сообщения'
                  : 'Приз отправлен вам в личные сообщения'
              );
              setTimeout(() => {
                onGameOver();
              }, 2000);
            })
            .catch((error) => {
              setLoading(false);
              setPrizeVisible(true);

              if (
                error.response?.data?.err?.error_code === 15 &&
                vk_viewer_group_role === 'admin'
              ) {
                setTurnOnMessages(true);
              }
            });
        })
        .catch((err) => {
          setLoading(false);

          // User denied
          if (err?.error_data?.error_code !== 4) {
            setPrizeVisible(true);
          }
        });

      return;
    }
  };

  const handleJoinClick = () => {
    if (targetInfo && targetInfo.vkClubID && result) {
      const group_id = +targetInfo.vkClubID;

      bridge
        .send('VKWebAppJoinGroup', { group_id })
        .then(() => {
          setJoinButtonVisible(false);
        })
        .catch((err) => {
          if (err?.error_data?.error_code !== 4) {
            setJoinButtonVisible(false);
          }
        });
    }
  };

  const handleCloseClick = useCallback(() => {
    onGameOver();
  }, [onGameOver]);

  useEffect(() => {
    setJoinButtonVisible(showJoinButton);
  }, [showJoinButton]);

  if (resultError) {
    return (
      <div className={styles.winWrap} style={style}>
        <h5 className={styles.resultSubtitle}>{resultError}</h5>

        <button className={cn(rsStyles.button, styles.button)} onClick={handleCloseClick}>
          Закрыть
        </button>
      </div>
    );
  }

  return (
    <div className={styles.winWrap} style={style}>
      {!successMessage && <h4 className={styles.resultTitle}>{title}</h4>}

      {!successMessage && !result && <Loader />}

      {result && result.win ? (
        prizeVisible ? (
          <>
            {turnOnMessages && (
              <h5 className={styles.resultSubtitleError}>
                В данном сообществе выключены сообщения. Включите сообщения в настройках сообщества,
                чтобы отсылать призы в ЛС.
              </h5>
            )}

            <h5 className={styles.resultSubtitle}>{subtitle}</h5>

            <button className={cn(rsStyles.button, styles.button)} onClick={handleCloseClick}>
              {loading ? <Loader /> : 'Закрыть'}
            </button>
          </>
        ) : successMessage ? (
          <h4 className={styles.resultTitle}>{successMessage}</h4>
        ) : (
          <div className={styles.buttonsWrapper}>
            <button className={cn(rsStyles.button, styles.button)} onClick={handleGetPrizeClick}>
              {loading ? <Loader /> : isCompetition ? 'Зачесть результат' : 'Получить приз'}
            </button>

            <button
              className={cn(rsStyles.button, styles.buttonJoin, {
                [styles.hiddenButton]: !joinButtonVisible,
              })}
              onClick={handleJoinClick}
              disabled={!joinButtonVisible}
            >
              Подписаться на сообщество
            </button>
          </div>
        )
      ) : (
        <button className={cn(rsStyles.button, styles.button)} onClick={handleCloseClick}>
          {loading ? <Loader /> : 'Закрыть'}
        </button>
      )}
    </div>
  );
};
