import React, { ReactNode, useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import styled from 'styled-components';
import { TwitterPicker } from 'react-color';
import { Div, Button } from '@vkontakte/vkui';
import { useDispatch } from 'react-redux';

import { vkActions } from 'src/store/vk/reducer';
import { ModalName } from 'src/store/vk/types';

type ColorSelectOwnProps = {
  TextPreview?: ReactNode;
};

type ColorSelectProps = WrappedFieldProps & ColorSelectOwnProps;

const popularColors = [
  '#FF6900',
  '#FCB900',
  '#7BDCB5',
  '#00D084',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#EB144C',
  '#F78DA7',
  '#9900EF',
  '#262738',
  '#1E2761',
  '#460ee3',
  '#5f31e0',
  '#2F3C7E',
  '#00539C',
  '#234E70',
  '#4831D4',
  '#990011',
  '#7A2048',
  '#783937',
  '#8A307F',
  '#CC313D',
  '#2C5F2D',
  '#3A6B35',
  '#317773',
  '#B85042',
  '#EE4E34',
  '#408EC6',
  '#2BAE66',
  '#EC449B',
  '#6883BC',
  '#F96167',
  '#EA738D',
  '#FC766A',
  '#79A7D3',
  '#FF69B4',
  '#AA96DA',
  '#8AAAE5',
  '#89ABE3',
  '#97BC62',
  '#E0A96D',
  '#A7BEAE',
  '#FFA351',
  '#EEA47F',
  '#E3B448',
  '#F1AC88',
  '#DDC3A5',
  '#CBD18F',
  '#FFBE7B',
  '#ADD8E6',
  '#EED971',
  '#F7C5CC',
  '#E2D1F9',
  '#CCF381',
  '#fff',
  '#000',
];

const ModalContent = ({ input }: { input: any }) => {
  const [color, setColor] = useState(input.value);

  return (
    <TwitterPicker
      color={color}
      onChangeComplete={({ hex }) => {
        input.onChange(hex);
        setColor(hex);
      }}
      width="320"
      colors={popularColors}
    />
  );
};

export const ColorSelect = ({ input, TextPreview }: ColorSelectProps) => {
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(vkActions.setActiveModal({ modal: ModalName.MAIN }));
    dispatch(
      vkActions.setModalContent({
        content: ModalContent,
        title: 'Выберите цвет',
        props: { input },
      })
    );
  };

  return (
    <ColorPickerField>
      <Div className="wrap">
        <ColorPreview color={input.value} onClick={handleOpen} />
        <Button size="m" onClick={handleOpen}>
          Изменить
        </Button>
      </Div>

      {TextPreview}
    </ColorPickerField>
  );
};

const ColorPickerField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .wrap {
    display: flex;
    align-items: center;
  }
`;

const ColorPreview = styled.div<{ color: string }>`
  flex: none;
  margin-right: 8px;
  width: 35px;
  height: 35px;
  border: 1px solid rgba(38, 50, 56, 0.3);
  border-radius: 4px;
  background-color: ${(p) => p.color};
  cursor: pointer;
`;
