import React, { useState, useCallback, ReactNode } from 'react';
import {
  PanelHeader,
  PanelHeaderBack,
  Div,
  Title,
  Group,
  Cell,
  FormLayout,
  FormLayoutGroup,
  Select,
  Button,
  Placeholder,
} from '@vkontakte/vkui';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  Icon28LockOutline,
  Icon28ShareOutline,
  Icon28SpeedometerMaxOutline,
  Icon24ErrorCircleOutline,
  Icon24ClockOutline,
  Icon24CheckCircleOutline,
  Icon24FlashOutline,
} from '@vkontakte/icons';
import axios from 'axios';

import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { RootState } from 'src/core/rootReducer';
import { Views } from 'src/store/vk/types';
import { vkTarifCost } from 'src/pages/Account/constants';
import { VkTarif } from 'src/store/auth/types';
import { config } from 'src/config/config';
import { getStringParams } from 'src/vk-app/utils/query';
import { authActions } from 'src/store';
import { ApiStatus } from 'src/core/ApiStatus';
import { targetsActions } from 'src/store/targets/actions';

enum PaymentStatus {
  INITIAL = 'INITIAL',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

export const TarifDescriptionPanel = () => {
  const dispatch = useDispatch();

  const { history, panelParams } = useSelector<RootState, VKInitialState>((state) => state.vk);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.INITIAL);

  const prevPanel = history[1] || { view: Views.ACCOUNT };

  const [monthAmount, setMonthAmount] = useState(12);

  const [paymentLink, setPaymentLink] = useState('');

  const onSelectChange = useCallback((event) => {
    setMonthAmount(+event.target.value);
  }, []);

  const cost = parseInt(vkTarifCost[VkTarif.PREMIUM]);

  const vkParams = getStringParams();

  const getTarifCost = (): number => {
    switch (monthAmount) {
      case 1:
        return cost;
      case 3:
        return Math.ceil(cost * monthAmount * 0.9);
      case 6:
        return Math.ceil(cost * monthAmount * 0.85);
      case 12:
        return Math.ceil(cost * monthAmount * 0.7);
      default:
        return Math.ceil(cost * monthAmount * 0.7);
    }
  };

  const getButtonText = (): ReactNode => {
    const totalCost = getTarifCost();

    if (monthAmount === 1) return `Оплатить ${totalCost} ₽`;

    return (
      <>
        Оплатить <OldPrice>{cost * monthAmount}</OldPrice> {totalCost} ₽
      </>
    );
  };

  const getLastTransaction = () => {
    axios
      .post(`${config.api}/vk-pay/get-last-transaction`, vkParams, { withCredentials: true })
      .then((result) => {
        if (result.data.status === 'success_payed') {
          setPaymentStatus(PaymentStatus.SUCCESS);
        } else if (result.data.status === 'error') {
          setPaymentStatus(PaymentStatus.ERROR);
        } else {
          setTimeout(() => {
            getLastTransaction();
          }, 2000);
        }
      });
  };

  const onSubmitTarif = async () => {
    if (paymentStatus === PaymentStatus.PENDING) {
      return;
    }

    setPaymentStatus(PaymentStatus.PENDING);

    axios
      .post(
        `${config.api}/vk-pay/generate-link`,
        { ...vkParams, tarif: VkTarif.PREMIUM, monthAmount },
        { withCredentials: true }
      )
      .then((result) => {
        setPaymentLink(result.data.link);

        getLastTransaction();

        window.open(result.data.link, 'newPay');
      })
      .catch(() => {
        setPaymentStatus(PaymentStatus.ERROR);
      });
  };

  const renewTarif = Boolean(panelParams?.renewTarif);

  if (paymentStatus === PaymentStatus.ERROR) {
    return (
      <Placeholder
        icon={<Icon24ErrorCircleOutline className="payment-status-icon" />}
        header="Ошибка в процессе оплаты"
        action={
          <Button
            size="l"
            onClick={() => {
              setPaymentStatus(PaymentStatus.INITIAL);
            }}
          >
            Закрыть
          </Button>
        }
      >
        Попробуйте повторить операцию заново.
      </Placeholder>
    );
  }

  if (paymentStatus === PaymentStatus.PENDING) {
    return (
      <Placeholder
        icon={<Icon24ClockOutline className="payment-status-icon" />}
        header="Обрабатываем ваш платеж"
        action={
          <Button href={paymentLink} target="_blank">
            Перейти на страницу оплаты
          </Button>
        }
      >
        Если окно оплаты не открылось, перейдите на страницу оплаты по кнопке ниже.
      </Placeholder>
    );
  }

  if (paymentStatus === PaymentStatus.SUCCESS) {
    return (
      <Placeholder
        icon={<Icon24CheckCircleOutline className="payment-status-icon" />}
        header="Оплата прошла"
        action={
          <Button
            size="l"
            onClick={() => {
              dispatch(authActions.getVkAuthData.started({ status: ApiStatus.INVALID }));
              dispatch(vkActions.setView({ view: Views.ACCOUNT }));
              dispatch(targetsActions.get.started({ isVk: true }));
              dispatch(targetsActions.resetLimits({}));
            }}
          >
            Закрыть
          </Button>
        }
      >
        Оплата успешно прошла. Спасибо!
      </Placeholder>
    );
  }

  return (
    <Wrap>
      <PanelHeader
        left={
          <PanelHeaderBack
            onClick={() => {
              dispatch(vkActions.setView(prevPanel));
            }}
          />
        }
      >
        Премиум доступ
      </PanelHeader>

      {!renewTarif && (
        <Div>
          <Group
            header={
              <Title weight="medium" level="2">
                Преимущества премиум доступа:
              </Title>
            }
          >
            <Cell
              disabled
              before={<Icon28SpeedometerMaxOutline />}
              description="Неограниченное количество пользователей смогут сыграть в ваши игры"
              className="eg-simple-cell"
              multiline
            >
              Без ограничений
            </Cell>

            <Cell
              disabled
              before={<Icon28ShareOutline />}
              description={`Пользователи могут вступить в ваше сообщество,
              чтобы получить еще одну попытку сыграть в игру`}
              className="eg-simple-cell"
              multiline
            >
              Социальные механики
            </Cell>

            <Cell
              disabled
              before={<Icon28LockOutline />}
              description="Можно установить лимит на количество разыгрываемых призов"
              className="eg-simple-cell"
              multiline
            >
              Лимит на призы
            </Cell>

            <Cell
              disabled
              before={<Icon24FlashOutline />}
              description={
                <div>
                  — интеграция с Senler
                  <br />
                  — запуск игр только для подписчиков
                  <br />— запуск игр только для Донов
                </div>
              }
              className="eg-simple-cell"
              multiline
            >
              Прочие плюшки
            </Cell>
          </Group>
        </Div>
      )}

      <FormLayout>
        <FormLayoutGroup
          top={renewTarif ? 'Продлить премиум-доступ' : 'Подключить премиум доступ для сообщества'}
        >
          <Select value={`${monthAmount}`} onChange={onSelectChange}>
            <option value="1" key="1">
              1 месяц
            </option>
            <option value="3" key="3">
              3 месяца (-10% скидка)
            </option>
            <option value="6" key="6">
              6 месяцев (-15% скидка)
            </option>
            <option value="12" key="12">
              12 месяцев (-30% скидка)
            </option>
          </Select>
        </FormLayoutGroup>

        <Button size="xl" onClick={onSubmitTarif}>
          {getButtonText()}
        </Button>
      </FormLayout>
    </Wrap>
  );
};

const Wrap = styled.div`
  .description {
    margin-top: 15px;
  }
`;

const OldPrice = styled.span`
  text-decoration: line-through;
`;
