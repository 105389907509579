import axios from 'axios';
import { config } from 'src/config/config';
import { GameId } from 'src/store/game/types';

export const getMyGamesFetcher = () =>
  axios.get(`${config.api}/games/get-user-games`, { withCredentials: true });

export const getMyGamesVkFetcher = (data: any) =>
  axios.post(`${config.api}/get-vk-campaigns`, data, { withCredentials: true });

export const createVkCampaign = ({
  gameId,
  campaignName,
  ...rest
}: {
  gameId: GameId;
  campaignName?: string;
}) =>
  axios.post(
    `${config.api}/create-vk-campaign`,
    { gameId, campaignName, ...rest },
    { withCredentials: true }
  );

export const addGameFetcher = ({
  gameId,
  targetId,
  ...rest
}: {
  gameId: GameId;
  targetId: number | null;
}) =>
  axios.post(`${config.api}/games/${gameId}/add`, { targetId, ...rest }, { withCredentials: true });

export const saveSettingsFetcher = ({
  gameId,
  settings,
  ...rest
}: {
  gameId: GameId;
  settings: any;
}) =>
  axios.post(
    `${config.api}/games/${gameId}/save-settings`,
    { ...settings, ...rest },
    { withCredentials: true }
  );

export const saveVkCampaign = ({ settings, ...rest }: { settings: any }) =>
  axios.post(`${config.api}/save-vk-campaign`, { ...settings, ...rest }, { withCredentials: true });

export const deleteGameFetcher = ({
  gameId,
  targetId,
  ...rest
}: {
  gameId: GameId;
  targetId: number;
}) =>
  axios.post(`${config.api}/games/${gameId}/delete/${targetId}`, rest, { withCredentials: true });

export const deleteVkCampaign = (data: { mainGameSettingsId: number }) =>
  axios.post(`${config.api}/delete-vk-campaign`, data, { withCredentials: true });

export const addTargetFetcher = ({
  gameId,
  settingsId,
  targetId,
}: {
  gameId: GameId;
  settingsId: number;
  targetId: number;
}) =>
  axios.post(
    `${config.api}/games/${gameId}/set-target`,
    { settingsId, targetId },
    { withCredentials: true }
  );

export const publishFetcher = ({
  gameId,
  settingsId,
  isPublish,
  ...rest
}: {
  gameId: GameId;
  settingsId: number;
  isPublish: boolean;
}) =>
  axios.post(
    `${config.api}/games/${gameId}/publish`,
    { settingsId, isPublish, ...rest },
    { withCredentials: true }
  );

export const publishVkCampaign = ({
  mainGameSettingsId,
  isPublish,
  ...rest
}: {
  mainGameSettingsId: number;
  isPublish: boolean;
}) =>
  axios.post(
    `${config.api}/publish-vk-campaign`,
    { mainGameSettingsId, isPublish, ...rest },
    { withCredentials: true }
  );

export const publishOnDirectLinkFetcher = ({
  isPublish,
  mainGameSettingsId,
  ...rest
}: {
  mainGameSettingsId?: number;
  isPublish: boolean;
}) =>
  axios.post(
    `${config.api}/vk/publish-on-direct-link`,
    { isPublish, mainGameSettingsId, ...rest },
    { withCredentials: true }
  );
