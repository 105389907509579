import { ApiError, ApiStatus } from 'src/core/ApiStatus';
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { AuthData } from './types';

const factory = actionCreatorFactory('AUTH');

export const authActions = {
  getAuthData: factory.async<{ status?: ApiStatus }, any, ApiError>('GET_AUTH_DATA'),
  logout: factory.async('LOGOUT'),
  getVkAuthData: factory.async<{ status?: ApiStatus }, any, ApiError>('GET_VK_AUTH_DATA'),
};

export type AuthInitialState = {
  status: ApiStatus;
  data: Partial<AuthData>;
  error?: {
    message: string;
  };
};

export const AUTH_INITIAL_STATE: AuthInitialState = {
  status: ApiStatus.INITIAL,
  data: {
    email: '',
    name: '',
    photo: '',
    apiToken: '',
    emailConfirmed: false,
  },
};

export const authReducer = reducerWithInitialState(AUTH_INITIAL_STATE)
  .case(authActions.getAuthData.started, (state, payload) => ({
    ...state,
    status: payload.status ?? ApiStatus.FETCHING,
  }))
  .case(authActions.getAuthData.done, (state, data: any) => ({
    ...state,
    status: ApiStatus.SUCCESS,
    data,
  }))
  .case(authActions.getAuthData.failed, (state) => ({
    ...state,
    status: ApiStatus.ERROR,
  }))
  .case(authActions.getVkAuthData.started, (state, payload) => ({
    ...state,
    status: payload.status ?? ApiStatus.FETCHING,
  }))
  .case(authActions.getVkAuthData.done, (state, data: any) => ({
    ...state,
    status: ApiStatus.SUCCESS,
    data,
  }))
  .case(authActions.getVkAuthData.failed, (state, { error }) => ({
    ...state,
    status: ApiStatus.ERROR,
    error,
  }));
