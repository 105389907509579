import React, { FC } from 'react';
import { Input, Textarea } from '@vkontakte/vkui';
import { WrappedFieldProps } from 'redux-form';

type TextFieldOwnProps = {
  className?: string;
  top?: string;
  bottom?: string;
};

type TextFieldProps = WrappedFieldProps & TextFieldOwnProps;

export const TextField: FC<TextFieldProps> = ({ input, meta, ...rest }) => {
  return <Input {...input} {...rest} />;
};

type TextAreaOwnProps = {
  className?: string;
  top?: string;
  bottom?: string;
  maxLength?: number;
};

type TextAreaProps = WrappedFieldProps & TextAreaOwnProps;

export const TextArea: FC<TextAreaProps> = ({ input, className, maxLength }) => {
  return <Textarea {...input} className={className} maxLength={maxLength} />;
};
