import { BaseGame, GameResult } from '../types';

type Titles = {
  resultTitle: string;
  resultSubtitle: string;
  hasPromocode: boolean;
};

export const getTitles = (settings: BaseGame, result?: GameResult): Titles => {
  if (!result) {
    return { resultTitle: '', resultSubtitle: '', hasPromocode: false };
  }

  const { winSubtitle, winTitle, failSubtitle, failTitle } = settings;

  if (settings.mainGameSettings.campaignType === 'competition') {
    if (!result.score) {
      return {
        resultTitle: failTitle,
        resultSubtitle: failSubtitle,
        hasPromocode: false,
      };
    }

    return {
      resultTitle: winTitle.replace(/{prize}/g, result.score?.toString() || ''),
      resultSubtitle: winSubtitle.replace(/{prize}/g, '').replace(/{prize_description}/g, ''),
      hasPromocode: false,
    };
  }

  return result.win
    ? {
        resultTitle: winTitle.replace(/{prize}/g, result.slotText || ''),
        resultSubtitle: winSubtitle
          .replace(/{prize}/g, result.slotValue || '')
          .replace(/{prize_description}/g, result.slotDescription || ''),
        hasPromocode: Boolean(result.slotValue) && winSubtitle.includes('{prize}'),
      }
    : {
        resultTitle: failTitle,
        resultSubtitle: failSubtitle,
        hasPromocode: false,
      };
};

export const formatSubtitle = (subtitle: string): string => {
  return subtitle.split('\n').join('<br/>');
};
