import React, { FC, useCallback, useEffect } from 'react';
import bridge, { VKBridgeSubscribeHandler } from '@vkontakte/vk-bridge';
import {
  View,
  Panel,
  Epic,
  Tabbar,
  TabbarItem,
  ModalRoot,
  ModalPage,
  ModalPageHeader,
  usePlatform,
  ANDROID,
  IOS,
  PanelHeaderButton,
} from '@vkontakte/vkui';

import { useDispatch, useSelector } from 'react-redux';
import { Icon28ListOutline, Icon28Game, Icon28User, Icon24Done, Icon24Cancel } from '@vkontakte/icons';
import axios from 'axios';

import { RootState } from 'src/core/rootReducer';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { Views, Panels, ModalName } from 'src/store/vk/types';
import {
  GamesPanel,
  MyGamesPanel,
  AccountPanel,
  GreetingPanel,
  GameDemoPanel,
  GameSettingsPanel,
  GameSettingsFormPanel,
  ShowGamePanel,
  ErrorPanel,
  TarifDescriptionPanel,
} from './panels';

import './app.css';
import '@vkontakte/vkui/dist/vkui.css';
import { authActions, AuthInitialState } from 'src/store';
import { myGamesActions } from 'src/store/my-games/actions';
import { targetsActions } from 'src/store/targets/actions';
import { getMsid, getStringParams, getVkQuery } from './utils/query';
import { ApiStatus } from 'src/core/ApiStatus';
import { config } from 'src/config/config';

type Props = {};

const App: FC<Props> = () => {
  const dispatch = useDispatch();
  const platform = usePlatform();
  const auth = useSelector<RootState, AuthInitialState>((state) => state.auth);

  const { view, panel, modal, ModalContent, modalTitle, popout, modalProps } = useSelector<
    RootState,
    VKInitialState
  >((state) => state.vk);

  useEffect(() => {
    const handleVkEvents: VKBridgeSubscribeHandler = (event) => {
      const isMobilePlatform = [IOS, ANDROID].includes(platform);

      if (event.detail.type === 'VKWebAppUpdateConfig') {
        document.body.setAttribute('scheme', event?.detail?.data?.scheme);
        return;
      }

      if (isMobilePlatform && event.detail.type === 'VKWebAppViewRestore') {
        window.location.reload();
        return;
      }
    };

    bridge.subscribe(handleVkEvents);
  }, [platform]);

  useEffect(() => {
    const query = getVkQuery();
    const msid = getMsid();

    bridge.send('VKWebAppInit');

    const { vk_viewer_group_role, vk_group_id } = query || {};
    const isAdmin = vk_viewer_group_role === 'admin';

    if (isAdmin) {
      dispatch(authActions.getVkAuthData.started({}));
      dispatch(vkActions.getStorageVars({}));

      if (msid) {
        dispatch(vkActions.setView({ view: Views.SHOW_GAME }));
      }

      return;
    }

    if (vk_group_id) {
      dispatch(vkActions.setView({ view: Views.SHOW_GAME }));
    } else {
      dispatch(vkActions.setView({ view: Views.GREETING }));
    }
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [view, panel]);

  const handleCloseModal = useCallback(() => {
    dispatch(vkActions.setActiveModal({ modal: null }));
    dispatch(vkActions.setModalContent({ content: () => null, title: '' }));
  }, [dispatch]);

  const registerCommunity = useCallback(() => {
    axios
      .post(
        `${config.api}/vk/register`,
        {
          ...getStringParams(),
        },
        { withCredentials: true }
      )
      .then(() => {
        dispatch(authActions.getVkAuthData.started({}));
        dispatch(myGamesActions.get.started({ isVk: true }));
        dispatch(targetsActions.get.started({ isVk: true }));
        dispatch(vkActions.setView({ view: Views.GAMES }));
      })
      .catch(() => {
        dispatch(
          vkActions.setGlobalError({
            title: `Ошибка при регистрации сообщества`,
            description: 'Мы уже работаем над ее исправлением.',
          })
        );
      });

    dispatch(vkActions.setView({ view: Views.ERROR }));
  }, [dispatch]);

  useEffect(() => {
    const query = getVkQuery();

    const { vk_viewer_group_role } = query;

    const needReinstallApp =
      ['admin'].includes(vk_viewer_group_role as string) &&
      auth.error &&
      auth.error.message === 'TargetNotFound';

    if (needReinstallApp) {
      registerCommunity();
    }
  }, [auth.error, registerCommunity]);

  const hideTabbar =
    [Views.SHOW_GAME, Views.GREETING, Views.ERROR].includes(view) ||
    auth.status === ApiStatus.ERROR;

  const disableTabbarNavigation = view === 'my-games' && panel === 'form';

  const navigateFromTabbar = (view: Views) => {
    if (disableTabbarNavigation) {
      return;
    }

    dispatch(vkActions.setView({ view }));
  };

  return (
    <Epic
      activeStory={view}
      tabbar={
        <Tabbar className={hideTabbar ? 'hidden' : ''}>
          <TabbarItem
            onClick={() => navigateFromTabbar(Views.GAMES)}
            selected={view === Views.GAMES}
            data-story={Views.GAMES}
            text="Каталог игр"
          >
            <Icon28Game />
          </TabbarItem>

          <TabbarItem
            onClick={() => navigateFromTabbar(Views.MY_GAMES)}
            selected={view === Views.MY_GAMES}
            data-story={Views.MY_GAMES}
            text="Мои кампании"
          >
            <Icon28ListOutline />
          </TabbarItem>

          <TabbarItem
            onClick={() => navigateFromTabbar(Views.ACCOUNT)}
            selected={view === Views.ACCOUNT || view === Views.TARIF_DESCRIPTION}
            data-story={Views.ACCOUNT}
            text="Аккаунт"
          >
            <Icon28User />
          </TabbarItem>
        </Tabbar>
      }
    >
      <View
        id={Views.GAMES}
        activePanel={panel}
        popout={popout}
        modal={
          <ModalRoot activeModal={modal} onClose={handleCloseModal}>
            <ModalPage
              id={ModalName.MAIN}
              settlingHeight={100}
              header={
                <ModalPageHeader
                  left={
                    platform === ANDROID && (
                      <PanelHeaderButton onClick={handleCloseModal}>
                        <Icon24Cancel />
                      </PanelHeaderButton>
                    )
                  }
                  right={
                    <PanelHeaderButton onClick={handleCloseModal} id="submit-modal">
                      {platform === IOS ? 'Готово' : <Icon24Done />}
                    </PanelHeaderButton>
                  }
                >
                  {modalTitle}
                </ModalPageHeader>
              }
            >
              <ModalContent {...modalProps} />
            </ModalPage>
          </ModalRoot>
        }
      >
        <Panel id={Panels[Views.GAMES]['MAIN']}>
          <GamesPanel />
        </Panel>

        <Panel id={Panels[Views.GAMES]['GAME']}>
          <GameDemoPanel bridge={bridge} />
        </Panel>
      </View>

      <View
        id={Views.MY_GAMES}
        activePanel={panel}
        modal={
          <ModalRoot activeModal={modal} onClose={handleCloseModal}>
            <ModalPage
              id={ModalName.MAIN}
              settlingHeight={100}
              header={
                <ModalPageHeader
                  left={
                    platform === ANDROID && (
                      <PanelHeaderButton onClick={handleCloseModal}>
                        <Icon24Cancel />
                      </PanelHeaderButton>
                    )
                  }
                  right={
                    <PanelHeaderButton onClick={handleCloseModal} id="submit-modal">
                      {platform === IOS ? 'Готово' : <Icon24Done />}
                    </PanelHeaderButton>
                  }
                >
                  {modalTitle}
                </ModalPageHeader>
              }
            >
              <ModalContent {...modalProps} />
            </ModalPage>
          </ModalRoot>
        }
        popout={popout}
      >
        <Panel id={Panels[Views.MY_GAMES]['MAIN']}>
          <MyGamesPanel />
        </Panel>

        <Panel id={Panels[Views.MY_GAMES]['GAME']}>
          <GameSettingsPanel bridge={bridge} />
        </Panel>

        <Panel id={Panels[Views.MY_GAMES]['FORM']}>
          <GameSettingsFormPanel bridge={bridge} />
        </Panel>
      </View>

      <View
        id={Views.ACCOUNT}
        activePanel={panel}
        modal={
          <ModalRoot activeModal={modal} onClose={handleCloseModal}>
            <ModalPage
              id={ModalName.MAIN}
              settlingHeight={100}
              header={
                <ModalPageHeader
                  left={
                    platform === ANDROID && (
                      <PanelHeaderButton onClick={handleCloseModal}>
                        <Icon24Cancel />
                      </PanelHeaderButton>
                    )
                  }
                  right={
                    <PanelHeaderButton onClick={handleCloseModal}>
                      {platform === IOS ? 'Готово' : <Icon24Done />}
                    </PanelHeaderButton>
                  }
                >
                  {modalTitle}
                </ModalPageHeader>
              }
            >
              <ModalContent {...modalProps} />
            </ModalPage>
          </ModalRoot>
        }
        popout={popout}
      >
        <Panel id={Panels[Views.ACCOUNT]['MAIN']}>
          <AccountPanel />
        </Panel>
      </View>

      <View id={Views.GREETING} activePanel={panel} className="single-panel">
        <Panel id={Panels[Views.GREETING]['MAIN']}>
          <GreetingPanel bridge={bridge} />
        </Panel>
      </View>

      <View id={Views.SHOW_GAME} activePanel={panel} className="single-panel">
        <Panel id={Panels[Views.SHOW_GAME]['MAIN']}>
          <ShowGamePanel />
        </Panel>
      </View>

      <View id={Views.TARIF_DESCRIPTION} activePanel={panel}>
        <Panel id={Panels[Views.TARIF_DESCRIPTION]['MAIN']}>
          <TarifDescriptionPanel />
        </Panel>
      </View>

      <View id={Views.ERROR} activePanel={panel} className="single-panel">
        <Panel id={Panels[Views.ERROR]['MAIN']}>
          <ErrorPanel />
        </Panel>
      </View>
    </Epic>
  );
};

export default App;
