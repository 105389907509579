import React, { ChangeEventHandler, FC, useCallback, useEffect, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import styled from 'styled-components';

import { config } from 'src/config/config';
import { getStringParams } from 'src/vk-app/utils/query';
import { Button, Input } from '@vkontakte/vkui';
import { pluralize } from 'src/core/utils/pluralize';

type PublicCodeProps = {
  titleColor: string;
  subtitleColor: string;
  mainGameSettingsId: number;
};

const WINDOW_RELOAD_DELAY = 2000;

export const PublicCode: FC<PublicCodeProps> = ({
  titleColor,
  subtitleColor,
  mainGameSettingsId,
}) => {
  const [publicCode, setPublicCode] = useState('');
  const [collapsed, setCollapsed] = useState(true);
  const formWrapperRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [attemptsCount, setAttemptsCount] = useState(0);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const applyCode = useCallback(async () => {
    try {
      setLoading(true);
      setError('');
      setAttemptsCount(0);

      const response = await axios.post(`${config.gameApi}/vk/apply-code`, {
        code: publicCode,
        mainGameSettingsId,
        ...getStringParams(),
      });

      if (response.data.extraAttempts) {
        setAttemptsCount(response.data.extraAttempts);

        timer.current = setTimeout(() => {
          window.location.reload();
        }, WINDOW_RELOAD_DELAY);
      }
    } catch (error) {
      setError(
        (error as AxiosError).response?.data.message ||
          'Ошибка. Мы уже работаем над ее исправлением. Попробуйте позже'
      );
    }

    setLoading(false);
  }, [mainGameSettingsId, publicCode]);

  const handleTitleClick = () => {
    if (!formWrapperRef.current) {
      return;
    }

    setCollapsed((collapsed) => !collapsed);
  };

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setPublicCode(event.target.value);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <PublicCodeWrapper color={subtitleColor}>
      <Title onClick={handleTitleClick}>У меня есть промокод</Title>

      {attemptsCount ? (
        <ResultMessage color={titleColor}>
          Вам доступно еще {attemptsCount}{' '}
          {pluralize(attemptsCount, 'попытка', 'попытки', 'попыток')}!
        </ResultMessage>
      ) : (
        <FormWrapper
          ref={formWrapperRef}
          height={collapsed ? 0 : formWrapperRef.current?.scrollHeight}
        >
          <Input value={publicCode} onChange={handleInputChange} />

          <Button onClick={applyCode} className="code-button" disabled={loading}>
            Применить
          </Button>

          {error && <ErrorMessage>{error}</ErrorMessage>}
        </FormWrapper>
      )}
    </PublicCodeWrapper>
  );
};

const PublicCodeWrapper = styled.div<{ color: string }>`
  color: ${(p) => p.color};
`;

const Title = styled.div`
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;
  margin-bottom: 12px;
`;

const FormWrapper = styled.div<{ height?: number }>`
  transition: height 0.15s ease-in-out;
  height: 0px;
  overflow: hidden;

  ${(p) => p.height && `height: ${p.height}px;`};

  .code-button {
    margin-top: 12px;
  }
`;

const ResultMessage = styled.div<{ color: string }>`
  color: ${(p) => p.color};
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 8px;
`;
