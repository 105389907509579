import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { change, Field, FormSection, formValueSelector } from 'redux-form';
import styled from 'styled-components';
import { Button, Div, Select } from '@vkontakte/vkui';
import axios, { AxiosError } from 'axios';

import { Icon28RefreshOutline } from '@vkontakte/icons';

import { TextField } from 'src/vk-app/components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/core/rootReducer';
import { config } from 'src/config/config';
import { getStringParams } from 'src/vk-app/utils/query';
import { useApi } from 'src/core/hooks/useApi';

type SenlerIntegrationInfo = {
  id: number;
  accessToken: string;
  listId: string;
  botId: string;
  botStepId: string;
};

type SenlerList = {
  subscription_id: string;
  name: string;
};

type SenlerBot = {
  bot_id: string;
  title: string;
};

type SenlerBotStep = {
  step_id: string;
  title: string;
};

export const Senler = () => {
  const formSelector = formValueSelector('game');

  const senlerIntegration = useSelector<RootState, SenlerIntegrationInfo | undefined>((state) =>
    formSelector(state, 'mainGameSettings.senlerIntegration')
  );

  const dispatch = useDispatch();

  const [activateLoading, setActivateLoading, activateError, setActivateError] = useApi();

  const [lists, setLists] = useState<SenlerList[]>([]);
  const [bots, setBots] = useState<SenlerBot[]>([]);
  const [botSteps, setBotSteps] = useState<SenlerBotStep[]>([]);

  const fetchLists = useCallback(async () => {
    try {
      const { accessToken } = senlerIntegration || {};

      const lists = await axios.post(
        `${config.api}/senler/get-lists`,
        { ...getStringParams(), accessToken },
        { withCredentials: true }
      );

      if (lists.data?.items?.length) {
        setLists(lists.data.items);
      }
    } catch (err) {
      const errorMessage = (err as AxiosError).response?.data?.message;

      setActivateError(errorMessage);

      setLists([]);
    }
  }, [senlerIntegration, setActivateError]);

  const fetchBots = useCallback(async () => {
    try {
      const { accessToken } = senlerIntegration || {};

      const bots = await axios.post(
        `${config.api}/senler/get-bots`,
        { ...getStringParams(), accessToken },
        { withCredentials: true }
      );

      if (bots.data?.items?.length) {
        setBots(bots.data.items);
      }
    } catch (err) {
      const errorMessage = (err as AxiosError).response?.data?.message;

      setActivateError(errorMessage);

      setBots([]);
    }
  }, [senlerIntegration, setActivateError]);

  const fetchBotSteps = useCallback(
    async (botId: string) => {
      try {
        const { accessToken } = senlerIntegration || {};

        const steps = await axios.post(
          `${config.api}/senler/get-bot-steps`,
          { ...getStringParams(), accessToken, botId },
          { withCredentials: true }
        );

        if (steps.data?.items?.length) {
          setBotSteps(steps.data.items);
        }
      } catch (err) {
        const errorMessage = (err as AxiosError).response?.data?.message;

        setActivateError(errorMessage);

        setBotSteps([]);
      }
    },
    [senlerIntegration, setActivateError]
  );

  const handleActivate = async () => {
    setActivateLoading(true);
    setActivateError('');

    await Promise.all([
      fetchLists(),
      fetchBots(),
      senlerIntegration?.botId ? fetchBotSteps(senlerIntegration?.botId) : undefined,
    ]);

    setActivateLoading(false);
  };

  const onListChange = (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(change('game', 'mainGameSettings.senlerIntegration.listId', event.target.value));
  };

  const onBotChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const botId = event.target.value;

    dispatch(change('game', 'mainGameSettings.senlerIntegration.botId', botId));
    dispatch(change('game', 'mainGameSettings.senlerIntegration.botStepId', ''));

    if (botId) {
      fetchBotSteps(botId);
    } else {
      setBotSteps([]);
    }
  };

  const onBotStepChange = (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(change('game', 'mainGameSettings.senlerIntegration.botStepId', event.target.value));
  };

  useEffect(() => {
    if (senlerIntegration?.accessToken) {
      fetchLists();
      fetchBots();

      if (senlerIntegration.botId) {
        fetchBotSteps(senlerIntegration.botId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <FormSection name="senlerIntegration">
        <Label>Ключ API</Label>

        <Lists>
          <Field
            name="accessToken"
            component={TextField}
            placeholder="Ключ API"
            type="password"
            className="token-input"
          />
          <Button
            disabled={!senlerIntegration?.accessToken || activateLoading}
            onClick={handleActivate}
            className="activate-button"
          >
            <Icon28RefreshOutline />
          </Button>
        </Lists>

        <Label>
          Подробнее про настройку интеграции можно узнать в{' '}
          <a
            href="https://vk.com/@embedgames-integraciya-s-senler"
            target="_blank"
            rel="noopener noreferrer"
          >
            статье
          </a>{' '}
          в нашем сообществе
        </Label>
      </FormSection>

      <Spacer />

      <Label>Добавлять в группу подписчиков</Label>

      <Select value={senlerIntegration?.listId} onChange={onListChange}>
        <option value={''} key={''}>
          Не выбрано
        </option>
        {lists.map((list) => (
          <option value={list.subscription_id} key={list.subscription_id}>
            {list.name}
          </option>
        ))}
      </Select>

      <Spacer />

      <Label>Добавлять в бота</Label>

      <Select value={senlerIntegration?.botId} onChange={onBotChange}>
        <option value={''} key={''}>
          Не выбрано
        </option>
        {bots.map((bot) => (
          <option value={bot.bot_id} key={bot.bot_id}>
            {bot.title}
          </option>
        ))}
      </Select>

      <Spacer />

      <Label>Шаг в боте</Label>

      <Select value={senlerIntegration?.botStepId} onChange={onBotStepChange}>
        <option value={''} key={''}>
          Не выбрано
        </option>
        {botSteps.map((step) => (
          <option value={step.step_id} key={step.step_id}>
            {step.title}
          </option>
        ))}
      </Select>

      {activateError && (
        <Div>
          <Error>{activateError}</Error>
        </Div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .lists-select {
    flex-shrink: 0;
    width: 70%;
  }
`;

const Error = styled.div`
  font-size: 12px;
  color: red;
`;

const Lists = styled.div`
  display: flex;
  margin-top: 12px;

  .token-input {
    width: 100%;
  }

  .activate-button {
    flex-shrink: 0;
    margin-left: 12px;
  }
`;

const Label = styled.div`
  padding: 8px 12px 0;
  font-size: 14px;
  line-height: 18px;
  color: #818c99;
  color: var(--text_secondary);
`;

const Spacer = styled.div`
  height: 16px;
`;
