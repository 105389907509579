import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ApiStatus } from 'src/core/ApiStatus';
import { statisticsActions as actions } from '../actions';
import { StatisticsEvent, CollectedUserData } from '../types';

export type StatisticsInitState = {
  status: ApiStatus;
  data: {
    events: StatisticsEvent;
    emails: CollectedUserData[];
    phones: CollectedUserData[];
    leads: CollectedUserData[];
  };
  error: string;
};

export const STATISTICS_INITIAL_STATE: StatisticsInitState = {
  status: ApiStatus.INITIAL,
  data: {
    events: {
      closeAmount: 0,
      looseAmount: 0,
      winAmount: 0,
      promocodes: {},
    },
    emails: [],
    phones: [],
    leads: [],
  },
  error: '',
};

export const statisticsReducer = reducerWithInitialState(STATISTICS_INITIAL_STATE)
  .case(actions.get.started, (state) => ({ ...state, status: ApiStatus.FETCHING }))
  .case(actions.get.done, (state, payload) => ({
    ...state,
    data: payload.result,
    status: ApiStatus.SUCCESS,
  }))
  .case(actions.get.failed, (state, payload) => ({
    ...state,
    error: payload.error.message,
    status: ApiStatus.ERROR,
  }))
  .case(actions.reset, () => STATISTICS_INITIAL_STATE);
