import React, { FC, useCallback } from 'react';
import { Button, Placeholder } from '@vkontakte/vkui';
import bridge from '@vkontakte/vk-bridge';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import { useSelector } from 'react-redux';

import { RootState } from 'src/core/rootReducer';
import { VKInitialState } from 'src/store/vk/reducer';

type Props = {};

export const ErrorPanel: FC<Props> = () => {
  const error = useSelector<RootState, VKInitialState['globalError']>(
    (state) => state.vk.globalError
  );

  const handleClose = useCallback(() => {
    bridge.send('VKWebAppClose', { status: 'success' });
  }, []);

  if (!error) {
    return null;
  }

  return (
    <Placeholder
      icon={<Icon56ErrorOutline />}
      header={error.title}
      action={
        <Button size="l" onClick={handleClose}>
          Закрыть
        </Button>
      }
    >
      {error.description}
    </Placeholder>
  );
};
