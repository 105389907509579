import React from 'react';
import reactDom from 'react-dom';
import CryptoES from 'crypto-es';
import { Modal } from 'src/components/Modal';
import { GameId, ShowGameRules } from 'src/store/game/types';
import { storage } from 'src/core/storage';

import { ComponentProps } from './eg-browser-ep';
import { getResult } from './fetchers';
import { GiftComponent, EmbedComponent } from './components';

export class GameManager {
  component: React.ComponentType<any>;
  componentProps: ComponentProps;
  localStorageKey: string;
  localStorageUserIdKey: string;

  constructor(Component: React.ComponentType<any>, componentProps: ComponentProps) {
    this.component = Component;
    this.componentProps = componentProps;
    this.localStorageKey = `eg-${componentProps.settings.mainGameSettings.gameId}-last-game`;
    this.localStorageUserIdKey = `eg-user-id`;

    if (!storage.getItem(this.localStorageUserIdKey)) {
      storage.setItem(this.localStorageUserIdKey, Math.random().toString(36).substring(2, 12));
    }

    if (this.canShow() && this.matchUrl()) {
      const renderDelay =
        this.componentProps.settings.mainGameSettings.showType === ShowGameRules.AUTO_OPEN
          ? this.componentProps.settings.mainGameSettings.showDelay * 1000
          : 500;

      setTimeout(() => {
        this.render();
      }, renderDelay);
    }
  }

  matchUrl() {
    const { showPages, showOnAllPages } = this.componentProps.settings.mainGameSettings;

    if (showOnAllPages) {
      return true;
    }

    const { pathname } = window.location;

    let match = false;

    const targetUrlEqual = showPages.filter((page) => page.type === 'match');
    const targetUrlContains = showPages.filter((page) => page.type === 'contains');

    if (targetUrlEqual) {
      targetUrlEqual.forEach((page) => {
        if (page.url === pathname) {
          match = true;
        }
      });
    }

    if (targetUrlContains) {
      targetUrlContains.forEach((page) => {
        if (pathname.includes(page.url)) {
          match = true;
        }
      });
    }

    return match;
  }

  canShow() {
    if (this.canRenderToDiv()) {
      return true;
    }

    return !this.isLocked();
  }

  isLocked(): boolean {
    const lastShow = storage.getItem(this.localStorageKey);

    if (!lastShow) {
      return false;
    }

    const breakMs = this.componentProps.settings.mainGameSettings.gameBreak * 60 * 60 * 1000;

    const lastShowMs = +lastShow;

    const now = Date.now();

    if (now - lastShowMs > breakMs) {
      return false;
    }

    return true;
  }

  lockGame = () => {
    const now = Date.now();

    storage.setItem(this.localStorageKey, now.toString());
  };

  canRenderToDiv(): boolean {
    const {
      settings: {
        mainGameSettings: { showType, renderElemId },
      },
    } = this.componentProps;

    const block = renderElemId ? document.getElementById(renderElemId) : null;

    if (showType === ShowGameRules.RENDER_TO_DIV && !block) {
      console.error(
        `[EmbedGames]: Element with id='${renderElemId}' not found! Render game in modal.`
      );
    }

    return showType === ShowGameRules.RENDER_TO_DIV && Boolean(block);
  }

  isModalWithTransform(gameId: GameId): boolean {
    return gameId !== GameId.LUCKY;
  }

  render() {
    const { component: Component, componentProps } = this;

    const {
      settings: {
        mainGameSettings: {
          showType,
          renderElemId,
          showDelay,
          giftImgUrl,
          giftPosition,
          giftPositionMobile,
        },
      },
      gameId,
      token,
      getResultToken,
    } = componentProps;

    const modalWithTransform = this.isModalWithTransform(gameId);

    const block = renderElemId ? document.getElementById(renderElemId) : null;

    const hmac = CryptoES.HmacSHA256(getResultToken, '3079CB7ynk').toString();

    const getResultFn = getResult({ gameId, token, getResultToken: hmac });

    if (this.canRenderToDiv()) {
      const container = document.createElement('div');
      block?.appendChild(container);

      // Если рендерим в контейнер, и такой контейнер найден
      reactDom.render(
        <EmbedComponent
          Game={Component}
          gameProps={{
            ...componentProps,
            getResultToken: hmac,
            lockGame: this.lockGame,
            getResult: getResultFn,
          }}
          localStorageKey={this.localStorageKey}
        />,
        container
      );

      return;
    }

    if (
      (showType === ShowGameRules.RENDER_TO_DIV && !block) ||
      showType === ShowGameRules.AUTO_OPEN
    ) {
      // Если рендерим в модал, либо если не найден контейнер
      const container = document.createElement('div');

      reactDom.render(
        <Modal visible={true} withTransform={modalWithTransform}>
          {({ close }: { close: () => void }) => (
            <Component
              {...componentProps}
              getResultToken={hmac}
              close={close}
              lockGame={this.lockGame}
              getResult={getResultFn}
            />
          )}
        </Modal>,
        container
      );

      document.body.appendChild(container);
      return;
    }

    if (
      showType === ShowGameRules.CLICK_ON_GIFT ||
      showType === ShowGameRules.CLICK_ON_GIFT_AND_AUTO_OPEN
    ) {
      const container = document.createElement('div');

      reactDom.render(
        <GiftComponent
          autoOpenDelay={
            showType === ShowGameRules.CLICK_ON_GIFT_AND_AUTO_OPEN ? showDelay * 1000 : undefined
          }
          imageUrl={giftImgUrl || 'https://cdn2.embedgames.app/BOX/gift-1.png'}
          giftPosition={giftPosition}
          giftPositionMobile={giftPositionMobile}
          modalWithTransform={modalWithTransform}
          isLocked={this.isLocked.bind(this)}
        >
          {({ close }: { close: () => void }) => (
            <Component
              {...componentProps}
              close={close}
              getResultToken={hmac}
              lockGame={this.lockGame}
              getResult={getResultFn}
            />
          )}
        </GiftComponent>,
        container
      );

      document.body.appendChild(container);
      return;
    }
  }
}
