import React from 'react';
import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { change, initialize } from 'redux-form';
import { ScreenSpinner } from '@vkontakte/vkui';

import { myGamesActions } from '../actions';
import { BaseGame } from '../../../games/common/types';
import { Action } from 'typescript-fsa';
import { GameId } from 'src/store/game/types';
import { RootState } from 'src/core/rootReducer';
import {
  getMyGamesFetcher,
  addGameFetcher,
  createVkCampaign,
  saveSettingsFetcher,
  saveVkCampaign,
  deleteGameFetcher,
  deleteVkCampaign,
  addTargetFetcher,
  publishFetcher,
  getMyGamesVkFetcher,
  publishOnDirectLinkFetcher,
  publishVkCampaign,
} from './fetchers';
import { getStringParams } from 'src/vk-app/utils/query';
import { vkActions } from 'src/store/vk/reducer';
import { Panels, Views } from 'src/store/vk/types';

function* getMyGames(action: ReturnType<typeof myGamesActions.get.started>) {
  const fetcher = action.payload.isVk ? getMyGamesVkFetcher : getMyGamesFetcher;

  try {
    const res: AxiosResponse<BaseGame[]> = yield call(fetcher, getStringParams());

    yield put(myGamesActions.get.done({ params: {}, result: res.data }));
  } catch (err) {
    yield put(
      myGamesActions.get.failed({ params: {}, error: { message: err.response?.data?.message } })
    );
  }
}

function* addGame(
  action: Action<{ gameId: GameId; targetId: number | null; campaignName?: string }>
) {
  const { gameId, targetId, campaignName } = action.payload;

  const vkParams = getStringParams();
  let res: AxiosResponse<{ mainSettingId: number }>;

  try {
    if (vkParams.sign) {
      res = yield call(createVkCampaign, {
        gameId,
        campaignName,
        ...vkParams,
      });
    } else {
      res = yield call(addGameFetcher, {
        gameId,
        targetId,
        ...vkParams,
      });
    }

    yield put(myGamesActions.addGame.done({ params: { gameId, targetId }, result: res.data }));
    yield put(
      myGamesActions.setAddedGameUrl({ url: `/my-games/${gameId}/${res.data.mainSettingId}` })
    );

    if (vkParams.sign) {
      yield put(myGamesActions.get.started({ isVk: true }));
    } else {
      yield put(myGamesActions.get.started({}));
    }
  } catch (err) {
    yield put(
      myGamesActions.addGame.failed({
        params: { gameId, targetId },
        error: { message: err.response.data.message },
      })
    );
  }
}

function* saveSettings(action: Action<{ gameId: GameId }>) {
  const { gameId } = action.payload;

  let state: RootState = yield select();

  const settings = state.form.game.values;
  const mainGameSettingsId = settings?.mainGameSettingsId;

  const vkParams = getStringParams();

  try {
    let res: AxiosResponse<{}>;

    if (vkParams.sign) {
      res = yield call(saveVkCampaign, {
        settings,
        ...vkParams,
      });
    } else {
      res = yield call(saveSettingsFetcher, {
        gameId,
        settings,
      });
    }

    yield put(myGamesActions.saveSettings.done({ params: { gameId }, result: res.data }));

    const getMyGamesAction = myGamesActions.get.started({ isVk: Boolean(vkParams.sign) });

    yield* getMyGames(getMyGamesAction);

    state = yield select();

    const initialValues = state.myGames.data.find(
      (game) => game.mainGameSettingsId === mainGameSettingsId
    );

    yield put(initialize('game', initialValues));

    if (vkParams.sign) {
      yield put(vkActions.setPopoutContent({ popout: null }));
      yield put(vkActions.setPanel({ panel: Panels[Views.MY_GAMES]['GAME'] }));
    } else {
      yield put(myGamesActions.setNeedShowPrompt(false));
    }
  } catch (err) {
    yield put(vkActions.setPopoutContent({ popout: null }));
    yield put(
      myGamesActions.saveSettings.failed({
        params: { gameId },
        error: { message: err.response?.data?.message },
      })
    );
    yield put(vkActions.requestSaveForm({ save: false }));

    if (!err.response) {
      yield put(vkActions.setPanel({ panel: Panels[Views.MY_GAMES]['GAME'] }));
    }
  }
}

function* deleteGame(
  action: Action<{ gameId?: GameId; targetId?: number; mainGameSettingsId?: number }>
) {
  const state: RootState = yield select();

  const gameId = action.payload.gameId ?? state.form.game?.values?.gameId;
  const targetId = action.payload.targetId ?? state.form.game?.values?.targetId;
  const mainGameSettingsId =
    action.payload.mainGameSettingsId ?? state.form.game?.values?.mainGameSettingsId;

  const vkParams = getStringParams();

  try {
    if (vkParams.sign) {
      yield call(deleteVkCampaign, { mainGameSettingsId, ...vkParams });
    } else {
      yield call(deleteGameFetcher, { gameId, targetId });
    }

    yield put(myGamesActions.deleteGame.done({ params: { gameId, targetId, mainGameSettingsId } }));

    if (vkParams.sign) {
      yield put(myGamesActions.get.started({ isVk: true }));
    } else {
      yield put(myGamesActions.get.started({}));
    }
    // if game not found, redirect
  } catch (err) {
    yield put(
      myGamesActions.deleteGame.failed({
        params: { gameId },
        error: { message: err.response.data.message },
      })
    );
  }
}

function* publish(action: Action<{ targetId?: number; isPublish: boolean }>) {
  const { targetId, isPublish } = action.payload;

  const state: RootState = yield select();

  const settings = state.form.game.values;

  try {
    if (targetId) {
      // add targetId to settings
      yield call(addTargetFetcher, {
        gameId: settings?.gameId,
        settingsId: settings?.id,
        targetId,
      });
    }

    const vkParams = getStringParams();

    if (vkParams.sign) {
      yield put(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));
    } else {
      yield call(saveSettingsFetcher, { gameId: settings?.gameId, settings });
    }

    if (vkParams.sign) {
      yield call(publishVkCampaign, {
        mainGameSettingsId: settings?.mainGameSettingsId,
        isPublish,
        ...vkParams,
      });
    } else {
      yield call(publishFetcher, {
        gameId: settings?.gameId,
        settingsId: settings?.id,
        isPublish,
        ...vkParams,
      });
    }

    if (vkParams.sign) {
      yield put(myGamesActions.get.started({ isVk: true }));
    } else {
      yield put(myGamesActions.get.started({}));
    }

    yield put(myGamesActions.publish.done({ params: action.payload, result: {} }));

    if (vkParams.sign) {
      yield put(vkActions.setPopoutContent({ popout: null }));
    }

    yield put(change('game', 'mainGameSettings.isPublish', isPublish));
    yield put(myGamesActions.setPublishSuccessModal({ open: true }));
    yield delay(2000);
    yield put(myGamesActions.setPublishSuccessModal({ open: false }));
  } catch (err) {
    yield put(
      myGamesActions.publish.failed({
        params: action.payload,
        error: { message: err.response.data.message },
      })
    );
  }
}

function* publishOnDirectLink(action: Action<{ isPublish: boolean }>) {
  const { isPublish } = action.payload;

  const state: RootState = yield select();

  const settings = state.form.game.values;

  try {
    const vkParams = getStringParams();

    yield put(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));

    yield call(publishOnDirectLinkFetcher, {
      mainGameSettingsId: settings?.mainGameSettingsId,
      isPublish,
      ...vkParams,
    });

    yield put(myGamesActions.get.started({ isVk: true }));
    yield put(myGamesActions.publishOnDirectLink.done({ params: action.payload, result: {} }));
    yield put(vkActions.setPopoutContent({ popout: null }));

    yield put(change('game', 'mainGameSettings.isPublishOnDirectLink', isPublish));
  } catch (err) {
    yield put(
      myGamesActions.publishOnDirectLink.failed({
        params: action.payload,
        error: { message: err.response.data.message },
      })
    );
  }
}

export function* watchMyGames() {
  yield takeLatest(myGamesActions.get.started, getMyGames);
  yield takeLatest(myGamesActions.addGame.started, addGame);
  yield takeLatest(myGamesActions.saveSettings.started, saveSettings);
  yield takeLatest(myGamesActions.deleteGame.started, deleteGame);
  yield takeLatest(myGamesActions.publish.started, publish);
  yield takeLatest(myGamesActions.publishOnDirectLink.started, publishOnDirectLink);
}
