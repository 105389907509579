import React, { ChangeEvent, useState } from 'react';
import { Button, File } from '@vkontakte/vkui';
import { WrappedFieldProps } from 'redux-form';
import styled from 'styled-components';
import { Icon24Upload } from '@vkontakte/icons';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import { vkActions } from 'src/store/vk/reducer';
import { ModalName } from 'src/store/vk/types';
import { getStringParams } from 'src/vk-app/utils/query';

type ImageSelectOwnProps = {
  variants?: string[];
  size?: [number, number];
  backgroundSize?: 'cover' | 'contain';
  title: string;
  className?: string;
  uploadUrl: string;
  setPreviewSrc: (imageSrc: string) => void;
  canUpload?: boolean;
  disabled?: boolean;
  uploadButtonText?: string;
};

type ImageSelectProps = Partial<WrappedFieldProps> & ImageSelectOwnProps;

export const ImageSelect = ({
  input,
  size = [150, 150],
  backgroundSize = 'contain',
  variants,
  title,
  className,
  uploadUrl,
  canUpload = false,
  setPreviewSrc,
  disabled = false,
  uploadButtonText = 'Загрузить свой',
}: ImageSelectProps) => {
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  let previews = [...(variants || [])];

  const [uploadedSrc, setUploadedSrc] = useState('');

  if (uploadedSrc) {
    previews.unshift(uploadedSrc);
  }

  const ModalContent = () => {
    const [selected, setSelected] = useState(input?.value);

    return (
      <VariantsList>
        {previews &&
          previews.map((variant) => (
            <Preview
              key={variant}
              selected={variant === selected}
              src={variant}
              width={size[0] / 2}
              height={size[1] / 2}
              backgroundSize={backgroundSize}
              className="item"
              onClick={() => {
                if (input?.onChange) {
                  input.onChange(variant);
                }

                setSelected(variant);
              }}
            />
          ))}
      </VariantsList>
    );
  };

  const handleChangeButtonClick = () => {
    dispatch(vkActions.setActiveModal({ modal: ModalName.MAIN }));
    dispatch(
      vkActions.setModalContent({
        content: ModalContent,
        title,
      })
    );
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && (event.target.files[0] as Blob);

    if (file) {
      if (file.size > 5000000) {
        setError('Размер файла должен быть меньше 5МБ');
        setLoading(false);
        return;
      }

      if (!['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(file.type)) {
        setError('Формат файла не поддерживается. Используйте jpeg, png или gif');
        setLoading(false);
        return;
      }

      const { sign, stringParams } = getStringParams();

      const formData = new FormData();

      formData.append('file', file);
      formData.append('sign', sign);
      formData.append('stringParams', stringParams);

      setError('');
      setLoading(true);

      axios
        .post(uploadUrl, formData, { withCredentials: true })
        .then((res) => {
          const src = `${res.data.url}?${Math.random()}`;
          setPreviewSrc(src);
          setUploadedSrc(src);

          setLoading(false);
        })
        .catch((err) => {
          let errorMessage: string;

          if (err.response) {
            errorMessage = err.response.data.message;
          } else {
            errorMessage =
              'Ошибка на сервере. Возможно, слишком большой размер файла. Максимальный размер файла - 5 МБ';
          }

          setLoading(false);

          setError(errorMessage);
        });
    }
  };

  return (
    <FieldWrap className={className}>
      {input?.value && (
        <Preview
          src={input.value}
          width={size[0]}
          height={size[1]}
          backgroundSize={backgroundSize}
          disabled={disabled}
        />
      )}

      <ButtonsWrapper>
        {variants && variants.length > 0 && (
          <Button
            onClick={handleChangeButtonClick}
            className="choose-background-button"
            size="l"
            disabled={disabled}
          >
            {canUpload ? 'Выбрать из предложенных' : 'Выбрать'}
          </Button>
        )}

        {canUpload && (
          <File
            mode="secondary"
            className="upload-background-button"
            before={<Icon24Upload />}
            controlSize="l"
            accept="image/*"
            onChange={onChange}
            disabled={disabled || loading}
          >
            {uploadButtonText}
          </File>
        )}
      </ButtonsWrapper>

      <FileSizeError>{error}</FileSizeError>
    </FieldWrap>
  );
};

const FieldWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Preview = styled.div<{
  src: string;
  width: number;
  height: number;
  backgroundSize: string;
  selected?: boolean;
  disabled?: boolean;
}>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  background-image: url(${(p) => p.src});
  background-size: ${(p) => p.backgroundSize};
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 15px;
  ${(p) => p.selected && `box-shadow: 0 0 2px 2px #4986cc`};
  ${(p) => p.disabled && `opacity: 0.5`};
`;

const VariantsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .item {
    margin: 12px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .choose-background-button,
  .upload-background-button {
    margin: 0 !important;
  }

  .choose-background-button {
    margin-bottom: 16px !important;
  }
`;

const FileSizeError = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: red;
  min-height: 17px;
`;
