import React, { FC } from 'react';

import { GameWrap } from '../common/game-wrap';
import { GameWrapProps, LuckySettings } from '../common/types';
import { Lucky } from './lucky';

import styles from './lucky.module.css';

type Props = Omit<GameWrapProps, 'GameContent'>;

const LuckyGame: FC<Props> = (props) => {
  const { buttonText, buttonTextColor, buttonColor, backgroundColor, view } =
    props.settings as LuckySettings;

  return (
    <GameWrap
      {...props}
      GameContent={Lucky}
      showResultScreenManual={true}
      startButton={{
        styles: {
          color: buttonTextColor,
          backgroundColor: buttonColor,
          marginTop: '25px',
        },
        text: buttonText,
      }}
      mainSectionClassname={styles.mainSection}
      style={{ backgroundColor: view === 'mono' ? backgroundColor : '' }}
    />
  );
};

export { LuckyGame };

export default LuckyGame;
