import React from 'react';
import ReactModal from 'react-modal';
import Media from 'react-media';
import cn from 'classnames';

import { handleContainer } from './utils';

import styles from './modal.module.css';

type ModalProps = {
  onClose?: () => void;
  visible: boolean;
  withTransform: boolean;
};

const getContentStyles = (size: string): React.CSSProperties => {
  let styles: React.CSSProperties = {};

  switch (size) {
    case 'medium':
      break;
    default:
      // is mobile
      styles = {
        padding: 0,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        border: 'none',
      };
      break;
  }

  return styles;
};

const getStyles = (size: string): ReactModal.Styles => {
  const contentStyles: React.CSSProperties = getContentStyles(size);

  return {
    content: {
      zIndex: 10000,
      outline: 'none',
      ...contentStyles,
    },
    overlay: {
      zIndex: 10000,
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      overflowY: 'auto',
    },
  };
};

export const Modal: React.FC<ModalProps> = ({ children, onClose, visible, withTransform }) => {
  const restoreContainerStyles = React.useRef<null | Function>(null);

  const [open, setOpen] = React.useState(visible);

  const closeModal = () => {
    setOpen(false);

    if (onClose) {
      onClose();
    }
  };

  React.useEffect(() => {
    setOpen(visible);
  }, [visible]);

  React.useEffect(() => {
    if (open) {
      restoreContainerStyles.current = handleContainer(document.body);
    }

    return () => {
      if (restoreContainerStyles.current) {
        restoreContainerStyles.current();
        restoreContainerStyles.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Media
      queries={{
        medium: '(min-width: 600px)',
        large: '(min-width: 960px)',
        xlarge: '(min-width: 1200px)',
      }}
    >
      {(matches) => {
        const size = matches.medium ? 'medium' : matches.large ? 'large' : 'xlarge';

        return (
          <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            style={getStyles(size)}
            appElement={document.body}
            portalClassName="eg-modal__portal"
            className={{
              base: cn(styles.modal, { [styles.withTransform]: withTransform }),
              afterOpen: styles.afterOpen,
              beforeClose: styles.beforeClose,
            }}
            overlayClassName={{
              base: styles.overlay,
              afterOpen: 'eg-modal__overlay_after-open',
              beforeClose: 'eg-modal__overlay_before-close',
            }}
          >
            {typeof children === 'function' ? children({ close: closeModal }) : children}
          </ReactModal>
        );
      }}
    </Media>
  );
};
