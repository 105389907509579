import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  Ref,
  useImperativeHandle,
  useRef,
} from 'react';
import styled, { css } from 'styled-components';

import { Loader } from '@alfalab/core-components-loader';

export type ComponentProps = {
  /**
   * Тип кнопки
   */
  view?: 'primary' | 'secondary' | 'outlined' | 'link' | 'ghost' | 'outlined-inverted';

  /**
   * Слот слева
   */
  leftAddons?: React.ReactNode;

  /**
   * Слот справа
   */
  rightAddons?: React.ReactNode;

  /**
   * Размер компонента
   */
  size?: 'xs' | 's' | 'm' | 'l';

  /**
   * Растягивает компонент на ширину контейнера
   */
  block?: boolean;

  /**
   * Дополнительный класс
   */
  className?: string;

  /**
   * Выводит ссылку в виде кнопки
   */
  href?: AnchorHTMLAttributes<HTMLAnchorElement>['href'];

  /**
   * Идентификатор для систем автоматизированного тестирования
   */
  dataTestId?: string;

  /**
   * Показать лоадер
   */
  loading?: boolean;
};

type AnchorButtonProps = ComponentProps & AnchorHTMLAttributes<HTMLAnchorElement>;
type NativeButtonProps = ComponentProps & ButtonHTMLAttributes<HTMLButtonElement>;
type ButtonProps = Partial<AnchorButtonProps | NativeButtonProps>;

export const Button = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      view = 'primary',
      leftAddons,
      rightAddons,
      size = 'm',
      block = false,
      className,
      dataTestId,
      href,
      loading = false,
      ...restProps
    },
    ref
  ) => {
    const buttonRef = useRef<HTMLElement>(null);

    // Оставляет возможность прокинуть реф извне
    useImperativeHandle(ref, () => buttonRef.current as HTMLButtonElement | HTMLAnchorElement);

    const componentProps = {
      className,
      'data-test-id': dataTestId || null,
    };

    const buttonChildren = (
      <React.Fragment>
        {leftAddons && <Addons loading={loading}>{leftAddons}</Addons>}
        {children && <Text loading={loading}>{children}</Text>}
        {loading && !href && <SLoader />}
        {rightAddons && <Addons loading={loading}>{rightAddons}</Addons>}
      </React.Fragment>
    );

    if (href) {
      return (
        <SLink
          {...componentProps}
          {...(restProps as AnchorHTMLAttributes<HTMLAnchorElement>)}
          href={href}
          ref={buttonRef as Ref<HTMLAnchorElement>}
          size={size}
          view={view}
          block={block}
        >
          {buttonChildren}
        </SLink>
      );
    }

    const { disabled, ...restButtonProps } = restProps as ButtonHTMLAttributes<HTMLButtonElement>;

    return (
      <SButton
        {...componentProps}
        {...restButtonProps}
        disabled={disabled || loading}
        ref={buttonRef as Ref<HTMLButtonElement>}
        size={size}
        view={view}
        block={block}
      >
        {buttonChildren}
      </SButton>
    );
  }
);

const styles = css<{ size?: ButtonProps['size']; view?: ButtonProps['view']; block?: boolean }>`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0;
  padding: 0 24px;
  line-height: 1.2;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
  user-select: none;
  cursor: pointer;
  transition: background 0.2s ease, border 0.2s ease, color 0.2s ease;
  box-sizing: border-box;

  ${(p) =>
    p.block &&
    `
    display: flex;
    width: 100%;
  `};

  ${(p) => {
    switch (p.size) {
      case 'xs':
        return `
          min-width: 80px;
          min-height: 32px;
          padding: 0 12px;
          font-size: 14px;
        `;
      case 's':
        return `
          min-width: 104px;
          min-height: 38px;
          font-size: 16px;
        `;
      case 'm':
        return `
          min-width: 128px;
          min-height: 44px;
          font-size: 16px;
        `;
      case 'l':
        return `
          min-width: 160px;
          min-height: 52px;
          padding: 0 32px;
          font-size: 18px;
        `;
    }
  }}

  ${(p) => {
    switch (p.view) {
      case 'primary':
        return css`
          color: #fff;
          background-color: #4d61fc;

          &:hover {
            background-color: #475bed;
          }

          &:active {
            background-color: #4053e3;
          }

          &:disabled {
            cursor: default;
            background-color: #a5afff;
          }
        `;
      case 'secondary':
        return css`
          background-color: #dbdee1;

          &:hover {
            background-color: #ccced1;
          }

          &:active {
            background-color: #babdbf;
          }
        `;
      case 'outlined-inverted':
        return `
          color: #fff;
          border: 1px solid #fff;
          background-color: transparent;
        `;
      case 'outlined':
        return `
          color: #0b1f35;
          border: 1px solid #0b1f35;
          background-color: transparent;
        `;
    }
  }};
`;

const SLink = styled.a`
  ${styles}
`;

const SButton = styled.button`
  ${styles}
`;

const Addons = styled.div<{ loading: boolean }>`
  display: flex;
  flex-shrink: 0;
  align-items: center;

  &:first-child {
    margin: 0 12px 0 0;
  }

  &:last-child {
    margin: 0 0 0 12px;
  }

  &:only-child {
    margin: 0;
  }

  ${(p) => p.loading && `opacity: 0;`};
`;

const Text = styled.div<{ loading: boolean }>`
  ${(p) => p.loading && `opacity: 0;`};
`;

const SLoader = styled(Loader)`
  position: absolute;
`;
