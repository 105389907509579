import React, { FC, useEffect } from 'react';
import { Button, FormStatus, Div, InfoRow, Progress, Spinner } from '@vkontakte/vkui';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { RootState } from 'src/core/rootReducer';
import { vkActions } from 'src/store/vk/reducer';
import { Views } from 'src/store/vk/types';
import { TargetsInitState } from 'src/store/targets/reducers';
import { BaseGame } from 'src/games/common/types';
import { Target } from 'src/store/targets/types';
import { targetsActions } from 'src/store/targets/actions';
import { VkTarif } from 'src/store/auth/types';

type Props = {
  activeGame: BaseGame;
  currentTarget?: Target;
};

export const FormStatusComponent: FC<Props> = ({ activeGame, currentTarget }) => {
  const dispatch = useDispatch();
  const targets = useSelector<RootState, TargetsInitState>((state) => state.targets);

  const isIOS = window.origin === 'null';

  const showsCount = targets.vkLimits
    ? targets.vkLimits.shows > targets.vkLimits.limit
      ? targets.vkLimits.limit
      : targets.vkLimits.shows
    : 0;

  const isOverLimit = targets.vkLimits && targets.vkLimits.shows >= targets.vkLimits.limit;

  const freeTarget = currentTarget?.vkTarif === VkTarif.FREE;

  const handleOpenActiveGame = () => {
    dispatch(vkActions.setView({ view: Views.SHOW_GAME }));
  };

  const handleGetPremium = () => {
    dispatch(vkActions.setView({ view: Views.TARIF_DESCRIPTION }));
  };

  useEffect(() => {
    if (freeTarget) {
      dispatch(targetsActions.getVkLimits.started({}));
    }
  }, [freeTarget, dispatch]);

  if (currentTarget?.vkTarif === 'FREE' && !targets.vkLimits) {
    return (
      <Div>
        <Wrapper>
          <FormStatus
            style={{
              height: '142px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner />
          </FormStatus>
        </Wrapper>
      </Div>
    );
  }

  return (
    <Div>
      <Wrapper>
        <FormStatus
          header={isOverLimit ? 'Показ игры приостановлен' : 'Игра активна'}
          className={
            isOverLimit
              ? 'custom-form-status form-status-error'
              : 'custom-form-status form-status-default'
          }
          mode={isOverLimit ? 'error' : 'default'}
        >
          {isOverLimit && !isIOS ? (
            <>
              Вы достигли лимита игр на сегодня. Чтобы снять лимит, оформите премиум доступ для
              сообщества.
            </>
          ) : (
            <>В сообществе опубликована игра «{activeGame.gameInfo.name}».</>
          )}
          <br />
          {targets.vkLimits && (
            <LimitsWrap>
              <InfoRow
                header={`Сыграно игр за сегодня: ${showsCount} из ${targets.vkLimits.limit}`}
              >
                <Progress value={(showsCount / targets.vkLimits.limit) * 100} />
              </InfoRow>
            </LimitsWrap>
          )}
          <FormStatusButtonsWrapper>
            {!isOverLimit && (
              <Button mode="commerce" className="open-game-button" onClick={handleOpenActiveGame}>
                Открыть игру
              </Button>
            )}

            {targets.vkLimits && !isIOS ? (
              <Button mode={isOverLimit ? 'commerce' : 'primary'} onClick={handleGetPremium}>
                {isOverLimit ? 'Оформить премиум' : 'Снять ограничения'}
              </Button>
            ) : null}
          </FormStatusButtonsWrapper>
        </FormStatus>
      </Wrapper>
    </Div>
  );
};

const LimitsWrap = styled.div`
  margin: 6px 0 16px;
`;

const FormStatusButtonsWrapper = styled.div`
  display: flex;
  margin-top: 12px;

  .open-game-button {
    margin-right: 12px;
  }
`;

const Wrapper = styled.div`
  .form-status-error {
    border: 1px solid red;
  }

  .form-status-default {
    border: 1px solid #13a454;
    background-color: #ebf9f1;
  }
`;
