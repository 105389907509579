import React, { useCallback, useEffect, useState } from 'react';
import { Cell, Div, Switch, Button, FormStatus } from '@vkontakte/vkui';
import { connect, useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import bridge from '@vkontakte/vk-bridge';

import { RootState } from 'src/core/rootReducer';
import { BaseGame } from 'src/games/common/types';
import styled from 'styled-components';
import { config } from 'src/config/config';
import { myGamesActions } from 'src/store/my-games/actions';
import { MyGamesInitState } from 'src/store/my-games/reducers';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { ModalName, Panels, Views } from 'src/store/vk/types';

const PublishDescription = () => (
  <Div>
    <FormStatus>
      В сообществе может быть несколько активных игр:
      <br />
      <br />— основная, которая открывается по-умолчанию при запуске мини-приложения (например, из
      меню сообщества)
      <br />
      <br />— дополнительные, которые можно открыть только по уникальной ссылке
    </FormStatus>
  </Div>
);

const Publish = () => {
  const formValues = useSelector<RootState>((state) => state.form.game.values) as BaseGame;
  const myGames = useSelector<RootState, MyGamesInitState>((state) => state.myGames);

  const {
    targetInfo,
    mainGameSettings: { isPublish, isPublishOnDirectLink, id: msid },
  } = myGames.data.find(
    (settings: any) => settings.mainGameSettings.id === formValues.mainGameSettings.id
  ) as BaseGame;

  const anotherPublishedGame = myGames.data.find(
    (settings) =>
      settings.mainGameSettings.id !== formValues.mainGameSettings.id &&
      settings.mainGameSettings.isPublish
  );

  const [copyed, setCopyed] = useState(false);
  const dispatch = useDispatch();

  const linkToApp = `https://vk.com/app${config.vkAppId}_-${targetInfo ? targetInfo.vkClubID : ''}`;
  const directLinkToGame = `https://vk.com/app${config.vkAppId}_-${
    targetInfo ? targetInfo.vkClubID : ''
  }#msid=${msid}`;

  const handleCopyLinkClick = useCallback(() => {
    setCopyed(true);

    bridge.send('VKWebAppCopyText', { text: linkToApp }).then(() => {
      setTimeout(() => {
        setCopyed(false);
      }, 2000);
    });
  }, [linkToApp]);

  const handleCopyDirectLinkClick = useCallback(() => {
    setCopyed(true);

    bridge.send('VKWebAppCopyText', { text: directLinkToGame }).then(() => {
      setTimeout(() => {
        setCopyed(false);
      }, 2000);
    });
  }, [directLinkToGame]);

  const publish = useCallback(() => {
    dispatch(myGamesActions.publish.started({ isPublish: !isPublish }));

    dispatch(vkActions.setActiveModal({ modal: null }));
    dispatch(vkActions.setModalContent({ content: () => null, title: '' }));
  }, [dispatch, isPublish]);

  const publishOnDirectLink = useCallback(
    (publish?: boolean) => {
      dispatch(
        myGamesActions.publishOnDirectLink.started({ isPublish: publish ?? !isPublishOnDirectLink })
      );

      dispatch(vkActions.setActiveModal({ modal: null }));
      dispatch(vkActions.setModalContent({ content: () => null, title: '' }));
    },
    [dispatch, isPublishOnDirectLink]
  );

  const handlePublishClick = useCallback(() => {
    if (!isPublish && anotherPublishedGame) {
      dispatch(vkActions.setActiveModal({ modal: ModalName.MAIN }));
      dispatch(
        vkActions.setModalContent({
          content: () => (
            <Div>
              <Div>
                <FormStatus mode="error" className="custom-form-status">
                  В сообществе уже опубликована другая игра: «{anotherPublishedGame?.gameInfo.name}»
                </FormStatus>
              </Div>

              <Div>
                <Button stretched size="xl" onClick={publish}>
                  Опубликовать вместо неё
                </Button>
              </Div>

              <Div>
                <Button
                  stretched
                  mode="secondary"
                  size="xl"
                  onClick={() => {
                    publishOnDirectLink(true);
                  }}
                >
                  Опубликовать по прямой ссылке
                </Button>
              </Div>
            </Div>
          ),
          title: 'Публикация игры',
        })
      );
      return;
    }

    publish();
  }, [isPublish, anotherPublishedGame, publish, dispatch, publishOnDirectLink]);

  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);

  useEffect(() => {
    if (vkData.requestSave) {
      dispatch(vkActions.setPanel({ panel: Panels[Views.MY_GAMES]['GAME'] }));
    }
  }, [dispatch, vkData.requestSave]);

  return (
    <Wrap>
      <PublishDescription />

      <Cell asideContent={<Switch checked={isPublish} onChange={handlePublishClick} />}>
        {isPublish ? 'Опубликована' : 'Опубликовать'}
      </Cell>

      {isPublish && (
        <Div>
          <FormStatus header="Игра опубликована" className="custom-form-status">
            Теперь все пользователи, запустившие приложение из вашего сообщества, смогут сыграть в
            эту игру. При переходе по этой ссылке, администраторы сообщества видят само приложение,
            а все остальные видят только опубликованную игру.
          </FormStatus>

          <Button
            mode="secondary"
            onClick={handleCopyLinkClick}
            className="copy-button"
            size="xl"
            stretched={true}
          >
            {copyed ? 'Скопировано' : 'Скопировать ссылку на игру'}
          </Button>
        </Div>
      )}

      <Cell
        asideContent={
          <Switch
            checked={isPublishOnDirectLink}
            onChange={() => {
              publishOnDirectLink();
            }}
          />
        }
      >
        {isPublishOnDirectLink ? 'Опубликована по прямой ссылке' : 'Опубликовать по прямой ссылке'}
      </Cell>

      {isPublishOnDirectLink && (
        <Div>
          <FormStatus header="Игра опубликована по прямой ссылке" className="custom-form-status">
            Игра доступна по прямой ссылке
          </FormStatus>

          <Button
            mode="secondary"
            onClick={handleCopyDirectLinkClick}
            className="copy-button"
            size="xl"
            stretched={true}
          >
            {copyed ? 'Скопировано' : 'Скопировать прямую ссылку на игру'}
          </Button>
        </Div>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  .custom-form-status {
    margin-bottom: 20px;
    border: 1px solid #13a454;
    background-color: #ebf9f1;
  }

  .copy-button {
    margin-bottom: 10px;
  }
`;

const withForm = reduxForm<any, any, any>({
  form: 'game',
})(Publish);

const withStore = connect((state: RootState) => {
  const { mainSettingsId } = state.vk.gameSettings;

  return {
    initialValues: state.myGames.data.find(
      (game: any) => game.mainGameSettings.id === mainSettingsId
    ),
  };
}, {})(withForm);

export { withStore as Publish };
