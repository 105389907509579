import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ApiStatus } from 'src/core/ApiStatus';
import { targetsActions as actions } from '../actions';
import { Target, VkLimits, Limits } from '../types';

export type TargetsInitState = {
  status: ApiStatus;
  data: {
    targets: Target[];
  };
  error: string;
  crudInProgress: boolean;
  crudError: string;
  vkLimits?: VkLimits;
  limits?: Limits;
};

export const TARGETS_INITIAL_STATE: TargetsInitState = {
  status: ApiStatus.INITIAL,
  data: {
    targets: [],
  },
  error: '',
  crudError: '',
  crudInProgress: false,
};

export const targetsReducer = reducerWithInitialState<TargetsInitState>(TARGETS_INITIAL_STATE)
  .case(actions.get.started, (state) => ({ ...state, status: ApiStatus.FETCHING }))
  .case(actions.get.done, (state, payload) => ({
    ...state,
    data: payload.result,
    status: ApiStatus.SUCCESS,
  }))
  .case(actions.get.failed, (state, payload) => ({
    ...state,
    error: payload.error.message,
    status: ApiStatus.ERROR,
  }))
  .case(actions.create.started, (state) => ({ ...state, crudInProgress: true }))
  .case(actions.create.done, (state, payload) => ({ ...state, crudInProgress: false }))
  .case(actions.create.failed, (state, payload) => ({
    ...state,
    crudError: payload.error.message,
    crudInProgress: false,
  }))
  .case(actions.update.started, (state) => ({ ...state, crudInProgress: true }))
  .case(actions.update.done, (state, payload) => ({ ...state, crudInProgress: false }))
  .case(actions.update.failed, (state, payload) => ({
    ...state,
    crudError: payload.error.message,
    crudInProgress: false,
  }))
  .case(actions.delete.started, (state) => ({ ...state, crudInProgress: true }))
  .case(actions.delete.done, (state, payload) => ({ ...state, crudInProgress: false }))
  .case(actions.delete.failed, (state, payload) => ({
    ...state,
    crudError: payload.error.message,
    crudInProgress: false,
  }))
  .case(actions.getVkLimits.done, (state, payload) => ({
    ...state,
    vkLimits: payload.result,
  }))
  .case(actions.getLimits.done, (state, payload) => ({
    ...state,
    limits: payload.result,
  }))
  .case(actions.resetLimits, (state) => ({ ...state, limits: undefined, vkLimits: undefined }));
