import React from 'react';
import styled from 'styled-components';
import { Group, InfoRow, PanelHeader, SimpleCell, Div, Button } from '@vkontakte/vkui';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';

import { RootState } from 'src/core/rootReducer';
import { vkTarifName } from 'src/pages/Account/constants';
import { VkTarif } from 'src/store/auth/types';
import { TargetsInitState } from 'src/store/targets/reducers';
import { vkActions } from 'src/store/vk/reducer';
import { getVkQuery } from 'src/vk-app/utils/query';
import { Views } from 'src/store/vk/types';
import { FormStatus } from 'src/components';
import { DAY_SHOWS_LIMIT } from 'src/core/constants';

moment.locale('ru');

export const AccountPanel = () => {
  const dispatch = useDispatch();

  const isIOS = window.origin === 'null';

  const targets = useSelector<RootState, TargetsInitState>((state) => state.targets);

  const query = getVkQuery();

  const currentTarget = targets.data.targets.find(
    (t) => t.targetType === 'VK_CLUB' && t.vkClubID === `${query.vk_group_id}`
  );

  const freeTarget = currentTarget?.vkTarif === VkTarif.FREE;

  const vkTarif = (currentTarget && currentTarget.vkTarif) || VkTarif.FREE;

  return (
    <Wrap>
      <PanelHeader>Аккаунт</PanelHeader>

      <Group>
        <SimpleCell disabled>
          <InfoRow header="Текущее сообщество">{currentTarget?.name}</InfoRow>
        </SimpleCell>

        {!isIOS && (
          <SimpleCell disabled>
            <InfoRow header="Тариф">{vkTarifName[vkTarif]}</InfoRow>
          </SimpleCell>
        )}

        {!freeTarget && currentTarget?.vkTarif && !isIOS && (
          <>
            {currentTarget.paidUntil && (
              <SimpleCell disabled>
                <InfoRow header="Тариф оплачен до">
                  {moment(currentTarget.paidUntil).format('DD.MM.YYYY')}
                </InfoRow>
              </SimpleCell>
            )}

            <Div>
              <Button
                onClick={() => {
                  dispatch(
                    vkActions.setView({
                      view: Views.TARIF_DESCRIPTION,
                      params: { renewTarif: true },
                    })
                  );
                }}
              >
                Продлить
              </Button>
            </Div>
          </>
        )}

        {freeTarget && !isIOS ? (
          <Div>
            <FormStatus
              content={
                <>
                  На бесплатном тарифе действует ограничение — в игру в вашем сообществе можно
                  сыграть не более {DAY_SHOWS_LIMIT} раз за день.
                  <br />
                  <br />
                  <Button
                    onClick={() => {
                      dispatch(vkActions.setView({ view: Views.TARIF_DESCRIPTION }));
                    }}
                  >
                    Подробнее о премиум доступе
                  </Button>
                </>
              }
            />
          </Div>
        ) : null}
      </Group>
    </Wrap>
  );
};

const Wrap = styled.div`
  .active-target {
    background-color: #eee;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: var(--content_tint_background);
  }
`;
