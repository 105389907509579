import { FormLayout, FormLayoutGroup, ScreenSpinner, Separator } from '@vkontakte/vkui';
import React, { FC, ReactNode, useCallback, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { RootState } from 'src/core/rootReducer';
import { BaseGame } from 'src/games/common/types';
import { myGamesActions } from 'src/store/my-games/actions';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { TextField, TextArea } from 'src/vk-app/components';
import { usePrevious } from 'src/core/hooks/usePrevious';

const prizeName = '{prize_name}';
const prizePromocode = '{prize_promocode}';
const prizeDescription = '{prize_description}';

const VK_TEXT_MESSAGE_MAX_LENGTH = 400;

type Props = {
  additional?: ReactNode;
};

const Texts: FC<Props> = ({ additional = null }) => {
  const values = useSelector<RootState>((state) => state.form.game.values) as BaseGame;
  const dispatch = useDispatch();
  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);

  const handleSubmit = useCallback(() => {
    dispatch(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));
    dispatch(myGamesActions.saveSettings.started({ gameId: values.gameId }));
  }, [dispatch, values.gameId]);

  const isCompetition = values.mainGameSettings.campaignType === 'competition';

  const prevRequestSave = usePrevious(vkData.requestSave);

  useEffect(() => {
    if (vkData.requestSave && !prevRequestSave) {
      handleSubmit();
    }
  }, [vkData.requestSave, handleSubmit, prevRequestSave]);

  const setVkTextMessage = (text: string) => {
    if (text.length < VK_TEXT_MESSAGE_MAX_LENGTH) {
      dispatch(change('game', 'vkTextMessage', text));
    }
  };

  const getTitleBottom = () => {
    const text = `Переменная {prize} заменится на ${
      isCompetition ? 'кол-во набранных очков' : 'название выигранного приза'
    }. Кликните для вставки`;

    return (
      <>
        {text}
        <br />
        <LabelVar
          onClick={() => {
            if (values.winTitle.length < 60) {
              dispatch(change('game', 'winTitle', `${values.winTitle} {prize}`));
            }
          }}
        >
          {`{prize}`}
        </LabelVar>
      </>
    );
  };

  const getVkTextMessageBottom = () => {
    if (isCompetition) {
      const text = `Переменная {prize_name} заменится на кол-во набранных очков. Кликните для вставки`;

      return (
        <>
          {text}
          <br />
          <LabelVar
            onClick={() => {
              setVkTextMessage(`${values.vkTextMessage} ${prizeName}`);
            }}
          >
            {prizeName}
          </LabelVar>
        </>
      );
    }

    return (
      <>
        Кликните на переменную для вставки. Переменная заменится на соответствующее значение
        <br />
        <span>
          <LabelVar
            onClick={() => {
              setVkTextMessage(`${values.vkTextMessage} ${prizeName}`);
            }}
          >
            {prizeName}
          </LabelVar>{' '}
          - название выигранного приза
        </span>
        <br />
        <span>
          <LabelVar
            onClick={() => {
              setVkTextMessage(`${values.vkTextMessage} ${prizePromocode}`);
            }}
          >
            {prizePromocode}
          </LabelVar>{' '}
          - значение приза (секретная часть)
        </span>
        <br />
        <span>
          <LabelVar
            onClick={() => {
              setVkTextMessage(`${values.vkTextMessage} ${prizeDescription}`);
            }}
          >
            {prizeDescription}
          </LabelVar>{' '}
          - описание приза
        </span>
      </>
    );
  };

  return (
    <Wrap>
      <FormLayout>
        <FormLayoutGroup top={`Заголовок (${values.titleText.length}/40)`}>
          <Field name="titleText" component={TextField} maxLength={40} />
        </FormLayoutGroup>

        <FormLayoutGroup top={`Подзаголовок (${values.subtitleText.length}/240)`}>
          <Field name="subtitleText" component={TextArea} maxLength={240} />
        </FormLayoutGroup>

        <Separator />

        <FormLayoutGroup
          top={`Заголовок экрана выигрыша (${values.winTitle.length}/60)`}
          bottom={getTitleBottom()}
        >
          <Field name="winTitle" component={TextField} maxLength={60} />
        </FormLayoutGroup>

        <FormLayoutGroup top={`Заголовок экрана проигрыша (${values.failTitle.length}/60)`}>
          <Field name="failTitle" component={TextField} maxLength={60} />
        </FormLayoutGroup>

        <Separator />

        <FormLayoutGroup
          top={`Текст сообщения. Отправится игроку в ЛС. Необходимо включить сообщения сообщества. (${values.vkTextMessage.length}/400)`}
          bottom={getVkTextMessageBottom()}
        >
          <Field name="vkTextMessage" component={TextArea} maxLength={400} />
        </FormLayoutGroup>

        {additional}
      </FormLayout>
    </Wrap>
  );
};

const Wrap = styled.div``;

const LabelVar = styled.div`
  display: inline-flex;
  width: max-content;
  padding: 4px 8px;
  color: #fff;
  font-weight: 500;
  background-color: #3899ec;
  margin-right: 4px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 4px;

  :last-child {
    margin-right: 0;
  }
`;

const withForm = reduxForm<any, any, any>({
  form: 'game',
})(Texts);

const withStore = connect((state: RootState) => {
  const { mainSettingsId } = state.vk.gameSettings;

  return {
    initialValues: state.myGames.data.find((game) => game.mainGameSettings.id === mainSettingsId),
  };
}, {})(withForm);

export { withStore as Texts };
