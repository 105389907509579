import React, { FC, useCallback, useEffect } from 'react';
import { Group, List, PanelHeader, PanelHeaderBack, SimpleCell } from '@vkontakte/vkui';
import { useDispatch, useSelector } from 'react-redux';
import { VKBridge } from '@vkontakte/vk-bridge';

import { RootState } from 'src/core/rootReducer';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { FormName, Panels, SettingsForm, Views } from 'src/store/vk/types';

import {
  Icon28GiftOutline,
  Icon28PaletteOutline,
  Icon28ShareOutline,
  Icon28TextCircleOutline,
  Icon28ViewOutline,
  Icon28SettingsOutline,
  Icon28StatisticsOutline,
  Icon28CrownOutline,
  Icon28TicketOutline,
  Icon28DocumentTextOutline,
} from '@vkontakte/icons';
import { getVkQuery } from 'src/vk-app/utils/query';
import { TargetsInitState } from 'src/store/targets/reducers';
import { VkTarif } from 'src/store/auth/types';
import { BaseGame } from 'src/games/common/types';

type Props = {
  bridge: VKBridge;
};

export const GameSettingsPanel: FC<Props> = () => {
  const dispatch = useDispatch();
  const campaigns = useSelector<RootState, BaseGame[]>((state) => state.myGames.data);
  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);

  const query = getVkQuery();
  const targets = useSelector<RootState, TargetsInitState>((state) => state.targets);

  const currentTarget = targets.data.targets.find(
    (t) => t.targetType === 'VK_CLUB' && t.vkClubID === `${query.vk_group_id}`
  );

  const vkTarif = (currentTarget && currentTarget.vkTarif) || VkTarif.FREE;

  const campaign = campaigns.find(
    (c) => c.mainGameSettingsId === vkData.gameSettings.mainSettingsId
  );

  const onCellClick = useCallback(
    (form: SettingsForm) => {
      dispatch(vkActions.setGameSettingsForm({ form }));
      dispatch(vkActions.setPanel({ panel: Panels[Views.MY_GAMES]['FORM'] }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(vkActions.requestSaveForm({ save: false }));
  }, [dispatch]);

  const isIOS = window.origin === 'null';
  const shouldRenderPremiumFeatures = isIOS ? vkTarif !== VkTarif.FREE : true;

  return campaign ? (
    <>
      <PanelHeader
        left={
          <PanelHeaderBack
            onClick={() => {
              dispatch(vkActions.setPanel({ panel: Panels[Views.GAMES]['MAIN'] }));
            }}
          />
        }
      >
        {campaign.mainGameSettings?.campaignName || campaign.gameInfo?.name}
      </PanelHeader>

      <Group>
        <List>
          <SimpleCell
            onClick={() => onCellClick(SettingsForm.CAMPAIGN_SETTINGS)}
            expandable={true}
            before={<Icon28DocumentTextOutline />}
            className="eg-simple-cell"
          >
            {FormName[SettingsForm.CAMPAIGN_SETTINGS]}
          </SimpleCell>

          {shouldRenderPremiumFeatures && (
            <SimpleCell
              onClick={() => onCellClick(SettingsForm.PREMIUM)}
              expandable={true}
              before={<Icon28CrownOutline className="crown-icon" />}
              className="eg-simple-cell"
            >
              {FormName[SettingsForm.PREMIUM]}
            </SimpleCell>
          )}

          <SimpleCell
            onClick={() => onCellClick(SettingsForm.DESIGN)}
            expandable={true}
            before={<Icon28PaletteOutline />}
            className="eg-simple-cell"
          >
            {FormName[SettingsForm.DESIGN]}
          </SimpleCell>

          <SimpleCell
            onClick={() => onCellClick(SettingsForm.TEXT)}
            expandable={true}
            before={<Icon28TextCircleOutline />}
            className="eg-simple-cell"
          >
            {FormName[SettingsForm.TEXT]}
          </SimpleCell>

          {campaign.mainGameSettings?.campaignType !== 'competition' && (
            <SimpleCell
              onClick={() => onCellClick(SettingsForm.PROMOCODES)}
              expandable={true}
              before={<Icon28GiftOutline />}
              className="eg-simple-cell"
            >
              {FormName[SettingsForm.PROMOCODES]}
            </SimpleCell>
          )}

          <SimpleCell
            onClick={() => onCellClick(SettingsForm.RULES)}
            expandable={true}
            before={<Icon28SettingsOutline />}
            className="eg-simple-cell"
          >
            {FormName[SettingsForm.RULES]}
          </SimpleCell>

          {shouldRenderPremiumFeatures && (
            <SimpleCell
              onClick={() => onCellClick(SettingsForm.PUBLIC_CODES)}
              expandable={true}
              before={<Icon28TicketOutline className="public-codes-icon" />}
              className="eg-simple-cell"
            >
              {FormName[SettingsForm.PUBLIC_CODES]}
            </SimpleCell>
          )}

          <SimpleCell
            onClick={() => onCellClick(SettingsForm.SHOW_RESULT)}
            expandable={true}
            before={<Icon28ViewOutline />}
            className="eg-simple-cell"
          >
            {FormName[SettingsForm.SHOW_RESULT]}
          </SimpleCell>

          <SimpleCell
            onClick={() => onCellClick(SettingsForm.PUBLISH)}
            expandable={true}
            before={<Icon28ShareOutline />}
            className="eg-simple-cell"
          >
            {FormName[SettingsForm.PUBLISH]}
          </SimpleCell>

          <SimpleCell
            onClick={() => onCellClick(SettingsForm.STATISTICS)}
            expandable={true}
            before={<Icon28StatisticsOutline />}
            className="eg-simple-cell"
          >
            {FormName[SettingsForm.STATISTICS]}
          </SimpleCell>
        </List>
      </Group>
    </>
  ) : null;
};
