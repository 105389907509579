import React, { FC } from 'react';
import { Switch as VkSwitch } from '@vkontakte/vkui';
import { WrappedFieldProps } from 'redux-form';

type SwitchProps = WrappedFieldProps & {
  disabled?: boolean;
};

export const Switch: FC<SwitchProps> = ({ input, disabled }) => {
  return <VkSwitch checked={input.value} disabled={disabled} {...input} />;
};
