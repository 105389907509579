import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ApiStatus } from 'src/core/ApiStatus';
import { actionTypes } from 'redux-form';

import { myGamesActions as actions } from '../actions';
import { BaseGame } from '../../../games/common/types';

export type MyGamesInitState = {
  status: ApiStatus;
  data: BaseGame[];
  error: string;
  addInProgress: boolean;
  addError: string;
  saveInProgress: boolean;
  saveError: string;
  deleteInProgress: boolean;
  deleteError: string;
  publishInProgress: boolean;
  publishError: string;
  publishSuccessModal: boolean;
  addedGameUrl: string;
  needShowPrompt: boolean;
};

export const MY_GAMES_INITIAL_STATE: MyGamesInitState = {
  status: ApiStatus.INITIAL,
  data: [],
  error: '',
  addInProgress: false,
  addError: '',
  saveInProgress: false,
  saveError: '',
  deleteInProgress: false,
  deleteError: '',
  publishInProgress: false,
  publishError: '',
  publishSuccessModal: false,
  addedGameUrl: '',
  needShowPrompt: false,
};

export const myGamesReducer = reducerWithInitialState(MY_GAMES_INITIAL_STATE)
  .case(actions.get.started, (state) => ({ ...state, status: ApiStatus.FETCHING }))
  .case(actions.get.done, (state, payload) => ({
    ...state,
    data: payload.result,
    status: ApiStatus.SUCCESS,
  }))
  .case(actions.get.failed, (state, payload) => ({
    ...state,
    error: payload.error.message,
    status: ApiStatus.ERROR,
  }))
  .case(actions.addGame.started, (state) => ({ ...state, addInProgress: true }))
  .case(actions.addGame.done, (state) => ({ ...state, addInProgress: false }))
  .case(actions.addGame.failed, (state, payload) => ({
    ...state,
    addInProgress: false,
    addError: payload.error.message,
  }))
  .case(actions.saveSettings.started, (state) => ({
    ...state,
    saveInProgress: true,
    saveError: '',
  }))
  .case(actions.saveSettings.done, (state) => ({ ...state, saveInProgress: false }))
  .case(actions.saveSettings.failed, (state, payload) => ({
    ...state,
    saveInProgress: false,
    saveError: payload.error.message,
  }))
  .case(actions.deleteGame.started, (state) => ({
    ...state,
    deleteInProgress: true,
    deleteError: '',
  }))
  .case(actions.deleteGame.done, (state) => ({ ...state, deleteInProgress: false }))
  .case(actions.deleteGame.failed, (state, payload) => ({
    ...state,
    deleteInProgress: false,
    deleteError: payload.error.message,
  }))
  .case(actions.publish.started, (state) => ({
    ...state,
    publishInProgress: true,
    publishError: '',
  }))
  .case(actions.publish.done, (state) => ({ ...state, publishInProgress: false }))
  .case(actions.publish.failed, (state, payload) => ({
    ...state,
    publishInProgress: false,
    publishError: payload.error.message,
  }))
  .case(actions.setPublishSuccessModal, (state, payload) => ({
    ...state,
    publishSuccessModal: payload.open,
  }))
  .case(actions.setAddedGameUrl, (state, payload) => ({
    ...state,
    addedGameUrl: payload.url,
  }))
  // @ts-ignore
  .case({ type: actionTypes.CHANGE }, (state) => ({
    ...state,
    needShowPrompt: true,
  }))
  .case(actions.setNeedShowPrompt, (state, needShowPrompt) => ({ ...state, needShowPrompt }));
