import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ApiStatus } from 'src/core/ApiStatus';
import { Game } from '../types';
import { allGamesActions as actions } from '../actions';

export type AllGamesInitState = {
  status: ApiStatus;
  data: Game[];
  error: string;
};

export const ALL_GAMES_INIT_STATE: AllGamesInitState = {
  status: ApiStatus.INITIAL,
  data: [],
  error: '',
};

export const allGamesReducer = reducerWithInitialState(ALL_GAMES_INIT_STATE)
  .case(actions.get.started, (state) => ({ ...state, status: ApiStatus.FETCHING }))
  .case(actions.get.done, (state, payload) => ({ ...state, data: payload.result, status: ApiStatus.SUCCESS }))
  .case(actions.get.failed, (state, payload) => ({ ...state, error: payload.error.message, status: ApiStatus.ERROR }));
