import React, { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { change, Field, FormSection, formValueSelector, reduxForm } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Button,
  Div,
  FormLayout,
  FormLayoutGroup,
  FormStatus,
  ScreenSpinner,
  Separator,
  Switch as VkSwitch,
  Select as VkSelect,
} from '@vkontakte/vkui';

import { RootState } from 'src/core/rootReducer';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { myGamesActions } from 'src/store/my-games/actions';
import { BaseGame } from 'src/games/common/types';
import { usePrevious } from 'src/core/hooks/usePrevious';
import { Senler, Switch, TextField } from 'src/vk-app/components';
import { VkTarif } from 'src/store/auth/types';
import { Target } from 'src/store/targets/types';
import { Views } from 'src/store/vk/types';
import { IntegrationsEnum } from 'src/store/game/types';

const Premium = () => {
  const senlerSectionRef = useRef<HTMLDivElement>(null);

  const formSelector = formValueSelector('game');
  const dispatch = useDispatch();

  const isIOS = window.origin === 'null';

  const settings = useSelector<RootState>((state) => state.form.game?.values) as BaseGame;

  const target = useSelector<RootState, Target>((state) => formSelector(state, 'targetInfo'));

  const handleSubmit = useCallback(() => {
    dispatch(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));
    dispatch(myGamesActions.saveSettings.started({ gameId: settings.gameId }));
  }, [dispatch, settings.gameId]);

  const handleIntegrationsChange = (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();

    let newValues = [...integrations];

    const value = event.target.value as IntegrationsEnum;

    if (value && !integrations.includes(value)) {
      newValues.push(value);
    } else {
      newValues = newValues.filter((integration) => integration !== IntegrationsEnum.SENLER);
    }

    dispatch(change('game', 'mainGameSettings.integrations', newValues));

    if (senlerSectionRef.current) {
      const { bottom } = senlerSectionRef.current.getBoundingClientRect() || {};

      window.scroll({ top: bottom + 250, left: 0, behavior: 'smooth' });
    }
  };

  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);

  const prevRequestSave = usePrevious(vkData.requestSave);

  useEffect(() => {
    if (vkData.requestSave && !prevRequestSave) {
      handleSubmit();
    }
  }, [vkData.requestSave, handleSubmit, prevRequestSave]);

  const saveError = useSelector<RootState, string>((state) => state.myGames.saveError);

  const hasPremium = target?.vkTarif !== VkTarif.FREE;

  const integrations = settings.mainGameSettings.integrations || [];

  const senlerEnable = hasPremium && integrations.includes(IntegrationsEnum.SENLER);

  return (
    <Wrap>
      <FormSection name="mainGameSettings">
        <FormLayout>
          <FormLayoutGroup top="Подписаться на сообщество за еще одну попытку">
            <Div>
              {hasPremium ? (
                <Field name="additionalAttemptForVkSubscription" component={Switch} />
              ) : (
                <VkSwitch checked={false} disabled={true} />
              )}
            </Div>

            <FormStatus className="custom-form-status" mode="default">
              Пользователь получит еще одну попытку сыграть в игру, если подпишется на ваше
              сообщество.
            </FormStatus>
          </FormLayoutGroup>

          <Separator />

          <FormLayoutGroup top="Играть в игру могут только подписчики">
            <Div>
              {hasPremium ? (
                <Field name="onlyForVkSubscribers" component={Switch} />
              ) : (
                <VkSwitch checked={false} disabled={true} />
              )}
            </Div>

            <FormStatus className="custom-form-status" mode="default">
              Играть в игру могут только подписчики вашего сообщества.
            </FormStatus>
          </FormLayoutGroup>

          <Separator />

          <FormLayoutGroup top="Играть в игру могут только Доны">
            <Div>
              {hasPremium ? (
                <Field name="onlyForVkDons" component={Switch} />
              ) : (
                <VkSwitch checked={false} disabled={true} />
              )}
            </Div>

            <FormStatus className="custom-form-status" mode="default">
              Играть в игру могут только Доны вашего сообщества. <br />
              Не забудьте подключить VK Donut в настройках сообщества.
            </FormStatus>
          </FormLayoutGroup>

          <Separator />

          <FormLayoutGroup top="Интеграции">
            <VkSelect
              value={senlerEnable ? IntegrationsEnum.SENLER : ''}
              onChange={handleIntegrationsChange}
              disabled={!hasPremium}
            >
              <option value={''} key={''}>
                Не выбрано
              </option>
              <option value={IntegrationsEnum.SENLER} key={IntegrationsEnum.SENLER}>
                {IntegrationsEnum.SENLER}
              </option>
            </VkSelect>

            {senlerEnable && <Senler />}
          </FormLayoutGroup>

          <Separator />

          <FormLayoutGroup top="Получать уведомления о выигрышах">
            <FormStatus className="custom-form-status" mode="default">
              Вы должны разрешить получать сообщения от нашего сообщества.
              <br />
              После каждого выигрыша, вы будете получать уведомление. Укажите ссылки на страницы,
              через запятую, на которые хотите получать уведомления.
            </FormStatus>

            <Field
              name="vkNotificationsRecipients"
              component={TextField}
              placeholder="https://vk.com/id123,https://vk.com/id456"
            />
          </FormLayoutGroup>

          {!hasPremium && !isIOS && (
            <Button
              onClick={() => {
                dispatch(vkActions.setView({ view: Views.TARIF_DESCRIPTION }));
              }}
              size="xl"
            >
              Подробнее о премиум доступе
            </Button>
          )}
        </FormLayout>
      </FormSection>

      {saveError && <Error>{saveError}</Error>}
    </Wrap>
  );
};

const Wrap = styled.div`
  padding-bottom: 45px;

  .custom-form-status {
    margin-top: 0;
  }
`;

const Error = styled.div`
  font-size: 12px;
  margin-top: 15px;
  color: red;
  padding: 12px;
`;

const withForm = reduxForm<any, any, any>({
  form: 'game',
})(Premium);

const withStore = connect((state: RootState) => {
  const { mainSettingsId } = state.vk.gameSettings;

  return {
    initialValues: state.myGames.data.find(
      (game: any) => game.mainGameSettings.id === mainSettingsId
    ),
  };
}, {})(withForm);

export { withStore as Premium };
