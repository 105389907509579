import axios from 'axios';
import qs from 'querystring';
import Hashids from 'hashids';
import { SendAnalyticsParams, Result } from './types';

import { config } from '../../config/config';
import { GameId } from 'src/store/game/types';
import { GameResult } from '../common/types';
import { getMsid, getStringParams } from 'src/vk-app/utils/query';
import { storage } from 'src/core/storage';
import { getEnv, getEnvForVk } from './save-env';

type GetResultParams = {
  gameId: GameId;
  token?: string;
  space?: string;
  getResultToken?: string;
};

type GetVkResultParams = {
  mainGameSettingsId: number;
  stringParams: string;
  sign: string;
  isDemoPreview?: boolean;
};

export const getResult =
  ({ gameId, token, space, getResultToken }: GetResultParams) =>
  (gameData: any = {}): Promise<GameResult> => {
    let score: string = '';

    if (gameData.score) {
      const hashId = new Hashids('3079CB7ynk', 10);
      score = hashId.encode(gameData.score);
    }

    const env = getEnv(storage);

    const data = {
      token,
      space,
      getResultToken,
      ...gameData,
      score,
      env,
    };

    return axios.post(`${config.gameApi}/get-result/${gameId}`, data).then((result) => result.data);
  };

export const getVkResult =
  ({ stringParams, sign, isDemoPreview, mainGameSettingsId }: GetVkResultParams) =>
  (gameData: any = {}): Promise<GameResult> => {
    let score: string = '';

    if (gameData.score !== undefined) {
      const hashId = new Hashids('3079CB7ynk', 10);
      score = hashId.encode(gameData.score);
    }

    const env = getEnvForVk();
    const msid = getMsid();

    const body = {
      stringParams,
      sign,
      isDemoPreview,
      ...gameData,
      score,
      env,
      msid: msid ?? undefined,
      mainGameSettingsId,
    };

    return axios
      .post(`${config.gameApi}/get-vk-result`, body)
      .then((result) => result.data);
  };

export const sendAnalytics = (params: SendAnalyticsParams): Promise<any> =>
  axios.post(`${config.gameApi}/send-analytics`, params).then((result) => result.data);

export const getUserData = ({ token }: { token: string }): Promise<Result> => {
  const params = {
    token,
  };

  return axios
    .get(`${config.gameApi}/get-user-data?${qs.stringify(params)}`)
    .then((result) => result.data);
};

export const getUserDataOnSpace = ({
  targetUrl,
  token,
}: {
  targetUrl: string;
  token: boolean;
}): Promise<Result> => {
  const params = {
    target: targetUrl,
    token,
    ...getStringParams(),
  };

  return axios.post(`${config.gameApi}/get-user-data-by-target`, params).then((result: any) => {
    result.data.settings.mainGameSettings = result.data.mainSettings;

    return {
      ...result.data,
      gameId: result.data.settings.gameId,
      token: result.data.token,
      getResultToken: result.data.getResultToken,
    };
  });
};

export const isUniqLead = (params: {
  email?: string;
  phone?: string;
  mainSettingId: number;
}): Promise<{ ok: boolean; errorMessage?: string }> => {
  return axios
    .post(`${config.gameApi}/lead/uniq`, params)
    .then((result) => result.data)
    .catch(() => ({ ok: true }));
};
